import axios from 'axios';
import { URL_FOUNDATION } from '../../config/keys';

export enum GroupInsideType {
	Report = 'Report',
	AdditionalColumn = 'AdditionalColumn',
	CalculatedField = 'CalculatedField',
	FormField = 'FormField'
}
export type GroupInside = {
	id: number,
	type_: GroupInsideType,
	ishidded: boolean,
}

export type Group = {
	id: number,
	name: string,
	sequence: number,
	created_by: number,
	public: boolean,
	inside: GroupInside[],
	shared_with: number[],
	is_deleted: boolean
}

export async function getUserCategories(userId: number): Promise<Group[]> {
	return await axios.get(`${URL_FOUNDATION}/api/v2/reports/groups/` + userId).then(res => res.data);
}

export async function syncUserCategories(userId: number, reportGroups: Group[]): Promise<void> {
	return await axios.put(`${URL_FOUNDATION}/api/v2/reports/groups/` + userId, reportGroups);
}