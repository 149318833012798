import * as React from 'react';
import Popup, { PopupContext } from '../../../components_v2/popup/Popup';
import { PopupMode, PopupStyle } from '../../../components_v2/popup/model/Model';
import Restricted from '../../permissions/Restricted';
import { DefaultImage } from '../../../styles/global/css/GlobalImage';
import { Unhautorized } from '../../noData/NoData';
import close from 'images/icons/orders/close.svg';
import { BodyHeaderStatus, BodyMainTextEditable, Container, FullOpen, HeaderBlock, HeaderRight, Logo, RightContainer } from '../../client-companies/style/PopupStyle';
import modal_open from 'images/icons/company/modal_open.svg';
import { DetailPopupHeader, DetailPopupLeftContainer } from '../../client-companies/popup/CompanyPopup';
import { FlexDiv } from '../../products/style';
import { ContactLinkedElementAmount, QueryUpdateContact, getContact, getContactLinkedElementAmounts, updateContact as rawUpdateContact } from '../data/Data';
import { ContactDetail } from 'bindings/contacts/ContactDetail';
import { GreySidely, SidelyBlack } from '../../../styles/global/css/Utils';
import { getClientStatuses } from '../../import/actions';
import { CompanyStatus } from '../../client-companies/model/Model';
import Dropdown from '../../../components_v2/dropdown/Dropdown';
import { Open } from '../../../styles/global/css/Open';
import Avatar from '../../../components_v2/avatar/Avatar';
import ContactPopupDetail from './innerPopupContent/ContactPopupDetails';
import { blurOnEnterPressed } from '../../../components_v2/input/Input';
import { TitleAndChild } from '../../client-companies/popup/Detail';
import { Translate } from '../../../styles/global/translate';
import ContactPopupLinkedElements from './innerPopupContent/ContactPopupLinkedElements';
import { ITag } from 'proto/protobufs';
import { getTags } from '../../../components_v2/toolbarFilter/tags/action';
import { TagType } from '../../../components_v2/toolbarFilter/tags/Model';
import { useRecoilValue } from 'recoil';
import { AContactEdition } from '../../../atoms/contact/contactEdition';
import open_new_tab from 'images/icons/company/open_new_tab.svg';
import usePermission from '../../permissions/usePermission';


export default function ContactPopup(props: {
	isOpen: boolean
	setOpen: (value: boolean) => void
	contactId?: number
	fullOpenMode?: boolean
	disableOnClickOut?: boolean
	disableOnEscPressed?: boolean
	noFullScreen?: boolean
	popupStyle?: PopupStyle
	updateContact?: (contactId: number, params: QueryUpdateContact, localChanges?: { [key: string]: unknown } | undefined) => Promise<void | undefined>,
	tags?: ITag[]
  }): JSX.Element {
	const [fullOpenMode, setFullOpenMode] = React.useState(props.fullOpenMode ?? false);
	const [disableEsc, setDisableEsc] = React.useState<boolean>(false);
	const [contact, setContact] = React.useState<ContactDetail>();
	const [statuses, setStatuses] = React.useState<CompanyStatus[]>();
	const [tags, setTags] = React.useState<ITag[]>(props.tags ?? []);
	const [linkedAmount, setLinkedAmount] = React.useState<ContactLinkedElementAmount>();
	const atomContactEdition = useRecoilValue(AContactEdition);

	const updateLinkedElementAmounts = React.useCallback((id: number) => getContactLinkedElementAmounts(id).then(setLinkedAmount), []);
	React.useEffect(() => {
		if (!contact || !atomContactEdition || atomContactEdition.id !== contact?.id) return;
		if (!contact.tags) contact.tags = [];
		if (atomContactEdition.ids_delete) {
			contact.tags = contact.tags.filter(t => !atomContactEdition.ids_delete?.includes(t));
		}
		if (atomContactEdition.ids_add) {
			for (const id of atomContactEdition.ids_add) {
				contact.tags.push(id);
			}
		}
		setContact({ ...contact });
	}, [atomContactEdition]);

	React.useEffect(() => {
		getTags(TagType.CONTACT).then(setTags);
	}, [props.tags]);

	React.useEffect(() => {
		getClientStatuses().then(response => {
			setStatuses(response.data);
		});
	}, []);

	React.useEffect(() => {
		setFullOpenMode(props.fullOpenMode ?? false);
	}, [props.fullOpenMode]);

	const fetchContact = (id?: number) => id ? getContact(id).then(setContact) : setContact(undefined);

	React.useEffect(() => {
		if (props.contactId !== undefined) updateLinkedElementAmounts(props.contactId);
		fetchContact(props.contactId);
	}, [props.contactId]);

	const onClickOut = React.useCallback(() => props.setOpen(false), []);

	return <Popup
		isOpen={props.isOpen}
		onClickOut={onClickOut}
		popupStyle={{ ...props.popupStyle, animate: true, minWidth: '450px' }}
		popupMode={fullOpenMode ? PopupMode.Default : PopupMode.Details}
		disableOutClick={disableEsc}
		disableEsc={disableEsc}
		content={props.contactId && contact && statuses
			? <ContactPopupContent
				updateLinkedElementAmounts={updateLinkedElementAmounts}
				linkedAmount={linkedAmount}
				tags={tags}
				contactId={props.contactId}
				contact={contact}
				onClickOut={onClickOut}
				isFullOpen={fullOpenMode}
				setFullOpenMode={setFullOpenMode}
				statuses={statuses}
				setDisableEsc={setDisableEsc}
				updateContact={(id, params, other) => (props.updateContact ?? rawUpdateContact)(id, params, other).then(() => fetchContact(id)).catch(console.error)}
			/>
			: undefined
		}
	/>;
}

function NameContainer(props: {
	contact: ContactDetail,
	objectKey: string,
	updateContact: (contactId: number, params: QueryUpdateContact, localChanges?: { [key: string]: unknown } | undefined) => Promise<void | undefined>
}) {
	const { contact, objectKey, updateContact } = props;
	return <Restricted
		to={{ objectAction: 'UpdateContact' }}
		ownerId={contact.owner_id}
		fallback={<div 
			style={{
				fontSize: '15px',
				color: SidelyBlack,
				padding: '0px',
				fontWeight: '700',
			}}
		>
			{contact[objectKey]}
		</div>}
	>
		<BodyMainTextEditable
			marginLeft='0px'
			fontSize='15px'
			fontWeight='700'
			color={SidelyBlack}
			suppressContentEditableWarning
			contentEditable
			onKeyDownCapture={blurOnEnterPressed}
			minWidth='10px'
			onBlur={(e) => {
				const newName = e.currentTarget.innerText.trim();
				if (newName === contact[objectKey]) return;
				const body: object = {};
				if (!newName) body[objectKey] = 'deleted';
				else body[objectKey] = { updated: newName };
				updateContact(contact.id, body);
			}}
		>
			{contact[objectKey]}
		</BodyMainTextEditable>
	</Restricted>;
}

function ContactPopupContent(props: {
	tags: ITag[],
	onClickOut: () => void,
	contactId: number,
	contact: ContactDetail,
	isFullOpen: boolean,
	setFullOpenMode: React.Dispatch<React.SetStateAction<boolean>>,
	statuses: CompanyStatus[],
	updateContact: (contactId: number, params: QueryUpdateContact, localChanges?: { [key: string]: unknown } | undefined) => Promise<void | undefined>,
	setDisableEsc: React.Dispatch<React.SetStateAction<boolean>>,
	linkedAmount: ContactLinkedElementAmount | undefined,
	updateLinkedElementAmounts: (id: number) => void
}) {
	const { isChild } = React.useContext(PopupContext);
	const { isFullOpen, setFullOpenMode, contact, statuses } = props;
	const status = statuses.find(s => s.id == contact.status_id);
	const [dropdownOpen, setDropdownOpen] = React.useState(false);
	const canEdit = usePermission({ objectAction: 'UpdateContact' }, { ownerId: contact.owner_id });

	const linked = <ContactPopupLinkedElements isExtend={isFullOpen} contact={contact} setDisableEsc={props.setDisableEsc} linkedAmount={props.linkedAmount} updateLinkedElementAmounts={props.updateLinkedElementAmounts}/>;
	
	return <Restricted
		ownerId={contact.owner_id}
		to={{ objectAction: 'ReadContact' }}
		fallback={<>
			<DefaultImage cursor="pointer" src={close} onClick={() => props.onClickOut()} />
			<Unhautorized />
		</>}
	>
		<Container>
			<DetailPopupLeftContainer isFullOpen={isFullOpen}>
				<DetailPopupHeader minHeight='100px'>
					<DefaultImage cursor="pointer" src={close} onClick={() => props.onClickOut()} />
					<FlexDiv width="calc(100% - 50px)" flow='column' justify='space-evenly' flexShrink={0}>
						<FlexDiv width='100%' align='stretch'>
							<FlexDiv margin="5px 0 0 10px" padding="5px 0 0 5px" width='calc(100% - 35px)' align='flex-start'>
								{contact.photo
									? <Logo src={contact.photo} />
									: <Avatar name={(contact.first_name ?? '') + ' ' + (contact.last_name ?? '')} width='50px' fontSize='20px' backgroundColor={GreySidely} />
								}
								<FlexDiv flow="column" margin="0 0 0 10px" align="flex-start" width='calc(100% - 60px)' gap='2px'>
									<HeaderBlock flexDirection="row" gap='0.5em'>
										<NameContainer contact={contact} objectKey='first_name' updateContact={props.updateContact} />
										<NameContainer contact={contact} objectKey='last_name' updateContact={props.updateContact} />
									</HeaderBlock>
									<HeaderBlock flexDirection="row">
										<Restricted
											ownerId={contact.owner_id}
											to={{ objectAction: 'UpdateContact' }}
											fallback={<BodyHeaderStatus backgroundColor={status?.color_code}>
												{status?.name ?? '-'}
											</BodyHeaderStatus>}
										>
											<Dropdown
												dropdownStyle={{ optionWidth: '300px', height: '21px' }}
												name="select_status"
												datalist={statuses.sort((a, b) => a.sequence - b.sequence).map(s => ({ label: s.name, value: s, color: s.color_code }))}
												JSXButton={() => <FlexDiv gap="5px">
													<BodyHeaderStatus backgroundColor={status?.color_code}>
														{status?.name ?? '-'}
													</BodyHeaderStatus>
													<Open isOpen={dropdownOpen} size={10} />
												</FlexDiv>}
												JSXContent={status => <BodyHeaderStatus inDropdown backgroundColor={status.color}>{status.label ?? '-'}</BodyHeaderStatus>}
												onChange={value => props.updateContact(contact.id, { status_id: value.value.id })}
												onOpen={setDropdownOpen}
											/>
										</Restricted>
									</HeaderBlock>
								</FlexDiv>
							</FlexDiv>
						</FlexDiv>
						{/* <QuickActions company={companyDetail} onCreate={() => updateLinkedElementAmounts()} isSelf={isSelf} /> */}
					</FlexDiv>
					<HeaderRight>
						{!isChild && <FullOpen src={modal_open} isActive={isFullOpen} onClick={() => setFullOpenMode(open => !open)} />}
						{isChild && <FullOpen isActive src={open_new_tab} onClick={() => window.open(`/contacts-v2?id=${contact.id}`, '_blank')?.focus()} />}
					</HeaderRight>
				</DetailPopupHeader>
				<ContactPopupDetail contact={contact} updateContact={props.updateContact} tags={props.tags} readOnly={!canEdit}/>
				{!isFullOpen && <div style={{ padding: '0 15px' }}>
					<TitleAndChild title={<Translate id='linked_elements'/>} >
						{linked}
					</TitleAndChild>
				</div>}
			</DetailPopupLeftContainer>
			<RightContainer isOpen={false} isFullOpen={isFullOpen}>
				{isFullOpen && linked}
			</RightContainer>
		</Container>
	</Restricted>;
}