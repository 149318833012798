import * as React from 'react';
import { getTranslate, Translate } from 'react-localize-redux';
import { PieChart } from 'react-minimal-pie-chart';
import { DataEntry } from 'react-minimal-pie-chart/types/commonTypes';
import { getAvgColorByFrequencyScore, getEmojiByFrequencyScore } from '../../atoms/global/frequency';
import { getPinColor } from '../../helpers/data/map';
import storeLang from '../../helpers/storeLang';
import { BlueSidely, GreenSidely, GreySidely, RedSidely, SidelyBlack } from '../../styles/global/css/Utils';
import { Owner } from '../orders/model/Model';
import { HideTrashDiv } from '../products/style';
import { ClusterProperties, InitialMapRow, MapCompany, MapData, Point } from './model';
import { PinLassoPosition } from './lasso';
import { MapContext } from './MapContext';
import { CompanyStatus } from '../client-companies/model/Model';
import { translateToString } from '../../styles/global/translate';
import { FetchKey, MinMax, MinMaxAdditionalColumn } from './MapView';
import { useRecoilValue } from 'recoil';
import { AAdditionalColumns } from '../../atoms/additionalColumns';
import { AAdditionalFieldColumns } from '../../atoms/additionalFieldColumns';
import { genAdditionalColumnClusterData, getStepColors } from './mapAdditionalColumns';
import { AAssortments } from '../../atoms/assortment';
import { ACalculatedFields } from '../../atoms/calculatedFields';
import { ACampaigns, Campaign, Period } from '../../atoms/campaignAtom';

export const DEFAULT_MAP_PIN_COLOR = '#83CEEE';
export const CHECKOUT_SUM_STEPS = 5;

export const dataFromDistribution = (distribution: number[], translate?: boolean): DataEntry[] => [
	{ value: distribution[0], color: GreenSidely, label: translate ? <Translate id='instore_presence' /> : 'instore_presence' },
	{ value: distribution[1], color: '#C8ED34', label: translate ? <Translate id={'order_in_progress'} /> : 'order_in_progress' },
	{ value: distribution[2], color: '#FFEA2C', label: translate ? <Translate id={'out_of_stock'} /> : 'out_of_stock' },
	{ value: distribution[3], color: '#FF7669', label: translate ? <Translate id={'de_listed'} /> : 'de_listed' },
	{ value: distribution[4], color: '#FFA340', label: translate ? <Translate id={'out_of_assortment'} /> : 'out_of_assortment' },
	{ value: distribution[5], color: BlueSidely, label: translate ? <Translate id={'other'} /> : 'other' },
	{ value: distribution[6], color: '#EAEAEA', label: translate ? <Translate id={'uninformed'} /> : 'uninformed' }
];

function getColorFromPeriods(periods: Period[]): string {
	const now = new Date();

	const period = periods.find(p => new Date(p.start) <= now && (!p.end || new Date(p.end) >= now));
	return period?.color ?? RedSidely;
}

function campaignsClusterData(points: ClusterProperties[], campaigns: Record<number, Campaign>): DataEntry[] {
	const stats = points.map(p => p.campaign ?? 0);
	const added = stats.reduce((acc, p) => {
		acc[p] ??= 0;
		acc[p] += 1;
		return acc;
	}, {});
	return Object.entries(added).map(([campaignId, value]) => {
		const campaign = campaigns[parseInt(campaignId)];
		console.log('campaign', campaign, 'period', campaign?.periods);
		return {
			value,
			color: campaign?.name ? getColorFromPeriods(campaign?.periods ?? []) : DEFAULT_MAP_PIN_COLOR,
			label: campaign?.name ?? 'Pas de campagne en cours'
		};
	});
}

function statusClusterData(points: ClusterProperties[], statuses: CompanyStatus[]): DataEntry[] {
	const stats = points.map(p => ({ id: p.status, color: p.statusColor }));
	return stats.reduce((acc: Array<{ value: number, color: string, label: string }>, s: ({ id: number, color: string })) => {
		if (!acc[s.id]) { acc[s.id] = ({ value: 1, color: s.color, label: statuses.find(st => st.id == s.id)?.name ?? '' }); } else { acc[s.id].value += 1; }
		return acc;
	}, [])
		.filter(e => e)
		.sort((a, b) => b.value - a.value);
}

function distributionClusterData(points: ClusterProperties[]): DataEntry[] {
	const stats = points.map(p => p.distribution ?? []).filter(e => e.length > 0);
	const added = stats.reduce((acc, p) => {
		return acc.map((y, i) => (p?.[i] ?? 0) + y);
	}, [0, 0, 0, 0, 0, 0, 0]);
	const max = stats.length * 100;
	return dataFromDistribution(added.map(e => e / max * 100), true);
}

function formsClusterData(points: ClusterProperties[], rangeColor: { firstRange: number, lastRange: number }): DataEntry[] {
	const t = points.reduce((acc: Record<string, { max: number, min: number, value: number }>, point) => {
		const color = getPinColorFromRange(point.formsRange, rangeColor);
		acc[color] ??= { max: 0, value: 0, min: Number.MAX_SAFE_INTEGER };
		acc[color].max = Math.max(acc[color].max, point.formsRange ?? 0);
		acc[color].min = Math.min(acc[color].min, point.formsRange ?? Number.MAX_SAFE_INTEGER);
		acc[color].value += 1;
		return acc;
	}, {});

	const obj = Object.entries(t).map(([color, { max, value, min }]): DataEntry => {
		let label = '';
		if (min === Number.MAX_SAFE_INTEGER) {
			label = translateToString('No Formbuilder');
		} else if (max === min) {
			label = max + ' ' + translateToString('days');
		} else {
			label = translateToString('between') + ' ' + min + ' et ' + max + ' ' + translateToString('days');
		}
		return { label: label, color, value, max };
	}
	).filter(({ value }) => value !== 0).sort((a, b) => a.max - b.max);
	return obj;
}

function frequencyClusterData(points: ClusterProperties[], eventType: number): DataEntry[] {
	const translate = getTranslate(storeLang.getState().localize);
	return points.reduce((acc: Array<{ value: number, color: string, label: string, score?: number | null}>, s: ClusterProperties) => {
		let label: string;
		let color: string;
		if (s.frequencies?.[eventType]?.score === undefined || s.frequencies?.[eventType]?.score === null) {
			label = translate('no_frequency').toString();
			color = DEFAULT_MAP_PIN_COLOR;
		} else {
			label = getEmojiByFrequencyScore(s.frequencies[eventType]?.score ?? undefined);
			color = getAvgColorByFrequencyScore(s.frequencies[eventType]?.score ?? undefined) ?? DEFAULT_MAP_PIN_COLOR;
		}
		const index = acc.findIndex(a => a.color === color);
		if (index < 0) {
			acc.push({ value: 1, label, color, score: s.frequencies?.[eventType]?.score });
		} else {
			acc[index].value += 1;
		}
		return acc;
	}, [])
		.sort((a, b) => a.score && b.score ? b.score - a.score : a.score ? 1 : 0);
}

function userClusterData(points: ClusterProperties[], users: Array<Owner>): DataEntry[] {
	return points.reduce((acc: Array<{ value: number, color: string, name: string }>, s: ClusterProperties) => {
		if (!s.owner) return acc;
		if (!acc[s.owner]) {
			const user = users?.find(u => u.id == s.owner);
			acc[s.owner] = ({ value: 1, color: user?.color ?? '', name: user?.name ?? '' });
		} else { acc[s.owner].value += 1; }
		return acc;
	}, [])
		.map(e => ({ value: e.value, color: e.color, label: e.name }))
		.filter(e => e)
		.sort((a, b) => b.value - a.value);
}

type T1 = { value: number, color: string, name?: string };
type T = {[key: number]: T1} & { no_checkouts: T1 };
function checkoutSumClusterData(points: ClusterProperties[], checkoutsMinMax: MinMax): DataEntry[] {
	const div = (checkoutsMinMax.max - checkoutsMinMax.min) / CHECKOUT_SUM_STEPS;
	const data = points.reduce((acc: T, point) => {
		if (!point.checkouts || point.checkouts.currentSum === undefined || point.checkouts.currentSum === null) acc.no_checkouts.value += 1;
		else {
			const index = Math.floor(point.checkouts.currentSum / div);
			acc[index] ??= {
				value: 0,
				color: getStepColors({ r: 255, g: 255, b: 255 }, { r: 5, g: 119, b: 57 }, index, CHECKOUT_SUM_STEPS),
				name: `${(index * div).toFixed(2)} - ${((index + 1) * div).toFixed(2)}`
			};
			acc[index].value += 1;
		}
		return acc;
	}, { no_checkouts: { value: 0, color: DEFAULT_MAP_PIN_COLOR } });
	return Object.entries(data).map(([label, { color, value, name }]): DataEntry => ({ label: name ?? translateToString(label), color, value })).filter(({ value }) => value !== 0);
}

function checkoutClusterData(points: ClusterProperties[]): DataEntry[] {
	const data = points.reduce((acc, point) => {
		if (!point.checkouts || point.checkouts.evolution === undefined || point.checkouts.evolution === null) acc.no_checkouts.value += 1;
		else if (point.checkouts.evolution > 0) acc.positive_evolution.value += 1;
		else if (point.checkouts.evolution == 0) acc.no_changements.value += 1;
		else acc.negative_evolution.value += 1;
		return acc;
	}, { positive_evolution: { value: 0, color: GreenSidely }, negative_evolution: { value: 0, color: RedSidely }, no_changements: { value: 0, color: GreySidely }, no_checkouts: { value: 0, color: DEFAULT_MAP_PIN_COLOR } });
	return Object.entries(data).map(([label, { color, value }]): DataEntry => ({ label: translateToString(label), color, value })).filter(({ value }) => value !== 0);
}

export function getPinColorFromRange(range: number | undefined, rangeColor: { firstRange: number, lastRange: number }): string {
	const { firstRange, lastRange } = rangeColor;
	if (firstRange || lastRange) {
		const rumus1 = 100 / (lastRange - firstRange);
		if (range !== undefined && range !== null) {
			const rumus2 = rumus1 * range;
			return getPinColor(rumus2);
		}
	}
	return DEFAULT_MAP_PIN_COLOR;
}

export function handlePinColor(point: ClusterProperties | MapCompany, tabType: FetchKey, rangeColor: { firstRange: number, lastRange: number }, campaigns?: Record<number, Campaign>): string {
	const { firstRange, lastRange } = rangeColor;
	let range: number | undefined;


	switch (tabType) {
		case 'campaign':
			if ('campaignId' in point && point.campaignId && campaigns) {
				const campaign = campaigns[point.campaignId];
				return campaign?.name ? getColorFromPeriods(campaign?.periods ?? []) : RedSidely;
			}
			break;
		case 'last':
			range = point.range !== undefined && point.range !== null ? point.range - firstRange : undefined;
			break;
		case 'next':
			range = point.nextRange !== undefined && point.nextRange !== null ? point.nextRange - firstRange : undefined;
			break;
		case 'orders':
			if ('orderRange' in point && point.orderRange !== undefined && point.orderRange !== null) {
				range = point.orderRange - firstRange;
			}
			break;
		case 'forms':
			// console.log('formsRange', point.formsRange);
			if ('formsRange' in point && point.formsRange) {
				range = point.formsRange !== undefined && point.formsRange !== null ? point.formsRange - firstRange : undefined;
			} else {
				range = undefined;
			}
			break;
	}
	console.log('range', range);
	if (firstRange || lastRange) {
		const rumus1 = 100 / (lastRange - firstRange);
		if (range !== undefined && range !== null) {
			const rumus2 = rumus1 * range;
			return getPinColor(rumus2);
		} else {
			if (tabType === 'last') {
				if (!point.range && point.next === true) {
					return '#11689E';
				}
				return DEFAULT_MAP_PIN_COLOR;
			} else if (tabType === 'next') {
				if (!point.next) {
					return DEFAULT_MAP_PIN_COLOR;
				}
			}
		}
	}
	return DEFAULT_MAP_PIN_COLOR;
}

function DateClusterData(points: ClusterProperties[], tabType: string, rangeColor: { firstRange: number, lastRange: number }): DataEntry[] {
	const obj = points
		.map(point => handlePinColor(point, tabType, rangeColor))
		.reduce((acc: object, color: string) => {
			if (!acc[color]) { acc[color] = 1; } else { acc[color] += 1; }
			return acc;
		}, {});

	return Object.entries(obj).map(([key, value]) => ({ value, color: key }));
}

export default function ClusterIcon(props: {
	companiesInLasso: InitialMapRow[],
	points: Point[],
	pointCount: number,
	properties: ClusterProperties[],
	tabType: FetchKey,
	rangeColor: { firstRange: number, lastRange: number },
	users: Array<Owner>,
	statuses: CompanyStatus[],
	campaigns: Record<number, Campaign>
	night?: boolean,
	eventType: number,
	onClusterClicked: (properties: ClusterProperties[]) => void,
	mapData: MapData | undefined,
	checkoutsMinMax?: MinMax,
	minMaxAdditionalColumn: MinMaxAdditionalColumn[]
	minMaxCalculatedFields: MinMaxAdditionalColumn[]
}) {
	const { hoveredPin, setHoveredPin } = React.useContext(MapContext);
	const { points, pointCount, properties, users, statuses, night, tabType, rangeColor } = props;
	const additionalColumns = useRecoilValue(AAdditionalColumns);
	const additionalFieldColumns = useRecoilValue(AAdditionalFieldColumns);
	const calculatedFields = useRecoilValue(ACalculatedFields);
	const assortments = useRecoilValue(AAssortments);
	const campaigns = useRecoilValue(ACampaigns);

	let data: DataEntry[] = [];
	let noToolType = false;
	const { isOneChildSelected, isOneChildHovered } = props.properties.reduce((acc, c) => {
		if (!acc.isOneChildSelected) acc.isOneChildSelected = props.companiesInLasso.some(c2 => c2.id === c.id);
		if (!acc.isOneChildHovered) acc.isOneChildHovered = typeof hoveredPin === 'object' ? hoveredPin.some(c2 => c2 === c.id) : c.id === hoveredPin;
		return acc;
	}, { isOneChildSelected: false, isOneChildHovered: false });
	if (typeof tabType === 'string') {
		switch (tabType) {
			case 'campaign':
				data = campaignsClusterData(properties, campaigns);
				break;
			case 'status':
				data = statusClusterData(properties, statuses);
				break;
			case 'instore':
				data = distributionClusterData(properties);
				break;
			case 'per_users':
				data = userClusterData(properties, users);
				break;
			case 'checkouts_sum':
				if (!props.checkoutsMinMax) data = [];
				else data = checkoutSumClusterData(properties, props.checkoutsMinMax);
				break;
			case 'checkouts':
				data = checkoutClusterData(properties);
				break;
			case 'forms':
				data = formsClusterData(properties, rangeColor);
				break;
			case 'frequencies':
				data = frequencyClusterData(properties, props.eventType);
				break;
			case 'next':
			case 'last':
			case 'orders':
				data = DateClusterData(properties, tabType, rangeColor);
				noToolType = true;
				break;
		}
	} else if ('additionalColumn' in tabType) {
		const additionalColumn = additionalColumns.find(ac => ac.id == tabType.additionalColumn);
		if (additionalColumn) {
			const idString = additionalColumn.id.toString();
			const res = genAdditionalColumnClusterData(
				properties,
				(point) => point.additionalColumnsValue?.[idString],
				additionalColumn.type,
				rangeColor,
				assortments,
				props.minMaxAdditionalColumn,
				false,
				idString
			);
			data = res.data;
			noToolType = res.noToolType ?? false;
		}
	} else if ('additionalFieldColumn' in tabType) {
		const additionalColumn = additionalFieldColumns.find(afc => afc.field_id == tabType.additionalFieldColumn);
		if (additionalColumn) {
			const idString = additionalColumn.field_id.toString();
			const res = genAdditionalColumnClusterData(
				properties,
				(point) => point.additionalFieldColumnsValue?.[idString],
				additionalColumn.field_type,
				rangeColor,
				assortments,
				props.minMaxAdditionalColumn,
				true,
				idString
			);
			data = res.data;
			noToolType = res.noToolType ?? false;
		}
	} else if ('calculatedFieldColumn' in tabType) {
		const calculatedFieldColumn = calculatedFields.find(cf => cf.id == tabType.calculatedFieldColumn);
		if (calculatedFieldColumn) {
			const idString = calculatedFieldColumn.id.toString();
			const res = genAdditionalColumnClusterData(
				properties,
				(point) => point.calculatedFieldColumnsValue?.[idString],
				'CalculatedField',
				rangeColor,
				assortments,
				props.minMaxCalculatedFields,
				true,
				idString
			);
			data = res.data;
			noToolType = res.noToolType ?? false;
		}
	}
	const countLength = pointCount.toString().length;
	const size = (pointCount / points.length) * 20;
	const clusterSize = 17 + size + countLength;
	const pieSize = clusterSize + 13;
	const MapToolTip = React.lazy(async() => await import('./toolTip'));
	
	return (
		<>
			<HideTrashDiv
				style={{ cursor: 'pointer' }}
				hoverStyle='z-index: 1;'
				hoverTrashStyle='z-index: 1;'
				onClick={() => {
					props.onClusterClicked(props.properties);
				}}
				onMouseEnter={() => isOneChildSelected && setHoveredPin(properties.reduce((acc, p) => p.id ? [...acc, p.id] : acc, []))}
				onMouseLeave={() => isOneChildSelected && setHoveredPin(undefined)}
			>
				{data.length > 0 &&
					<PieChart
						className='circle'
						style={{ position: 'absolute', left: '50%', top: '50%', width: pieSize, height: pieSize, transform: 'translate(-50%, -50%)' }}
						lineWidth={70}
						center={[50, 50]}
						viewBoxSize={[100, 100]}
						data={data}
					/>
				}
				<div
					className='bg-cluster'
				>
					<div
						className='cluster-marker'
						style={{
							width: `${clusterSize}px`,
							height: `${clusterSize}px`,
							backgroundColor: night ? SidelyBlack : 'white',
							color: night ? 'white' : SidelyBlack
						}}
					>
						{pointCount}
						{isOneChildSelected && <PinLassoPosition style={{
							top: '0',
							right: '0',
							translateValue: '50% -50%',
							size: '10px'
						}}
						selected={isOneChildHovered}
						/>}
					</div>
				</div>
				<React.Suspense>
					{!noToolType && data.every(e => !isNaN(e.value)) && <MapToolTip data={data} instore={tabType == 'instore'} night={night} cluster/>}
				</React.Suspense>
			</HideTrashDiv>
		</>
	);
}
