import * as React from 'react';
import { ToolTipHover } from './ReportInterpretor';
import { useRefState } from '../../../utils/customHooks';

export type EvolutionState = Record<number, string>;

export interface ReportInterpretorContextType {
  hover: ToolTipHover | undefined
  setHover: (a?: ToolTipHover) => void
  evolutionState: EvolutionState,
  setEvolutionState: React.Dispatch<React.SetStateAction<EvolutionState>>
  evolutionStateRef: React.MutableRefObject<EvolutionState>,
  onCompanyClick: (n: number) => void,
  onFormInstanceClick: (uuid: string) => void,
  mapValues?: MapValues
}

const emptyFunction = () => undefined;

// Default behaviour for the Permission Provider Context
// i.e. if for whatever reason the consumer is used outside of a provider
// The permission will not be granted if no provider says otherwise
const defaultBehaviour: ReportInterpretorContextType = {
	hover: undefined,
	setHover: emptyFunction,
	evolutionState: {},
	setEvolutionState: emptyFunction,
	evolutionStateRef: { current: {} },
	onCompanyClick: emptyFunction,
	onFormInstanceClick: emptyFunction
};

// Create the context
const ReportInterpretorContext = React.createContext<ReportInterpretorContextType>(defaultBehaviour);

export default ReportInterpretorContext;

export type MapValues = {
	companies: Record<number, string>,
	campaigns: Record<number, string>,
	users: Record<number, string>,
	products: Record<string, string>,
}

export type ReportInterpretorProviderProps = {
	onCompanyClick?: (n: number) => void,
	onFormInstanceClick?: (uuid: string) => void,
	mapValues?: MapValues
};

export function ReportInterpretorProvider(props: { children } & ReportInterpretorProviderProps) {
	const [hover, setHover] = React.useState<ToolTipHover>();
	const [evolutionStateRef, setEvolutionState, evolutionState] = useRefState<EvolutionState>({});

	const value = {
		hover,
		setHover,
		evolutionState,
		setEvolutionState,
		evolutionStateRef,
		onCompanyClick: props.onCompanyClick ?? emptyFunction,
		onFormInstanceClick: props.onFormInstanceClick ?? emptyFunction,
		mapValues: props.mapValues
	};

	return (
		<ReportInterpretorContext.Provider value={value}>
			{props.children}
		</ReportInterpretorContext.Provider>
	);
}