import * as React from 'react';
import { getTranslate, Translate } from 'react-localize-redux';
import Popup from '../../../components_v2/popup/Popup';
import { Column, Table } from '../../../components_v2/table/Table';
import storeLang from '../../../helpers/storeLang';
import { ToolbarState } from '../../globals/mainPage/mainPage';
import Restricted from '../../permissions/Restricted';
import { addUserToGroup, createGroup, deleteGroup, delUserFromGroup, getGroups, updateGroup } from '../action';
import { Group, GroupDetailResult } from '../model';
import { RightContainer } from '../style';
import GroupDetail from './GroupDetail';
import swal from 'sweetalert2';
import { BlueSidely, RedSidely } from '../../../styles/global/css/Utils';
import { PopupMode } from '../../../components_v2/popup/model/Model';
import { DefaultButton } from '../../../styles/global/css/GlobalButton';
import { TableRow, TableRowTitle } from '../../orders/templateOrders/style/Style';

export enum ActionOpen {
  EDIT,
  CREATE,
  NONE
}

export default function SecurityGroupList(props: {
  setToolBarState: (value: ToolbarState) => void
}): JSX.Element {
	const [groupId, setGroupId] = React.useState<number>(0);
	const translate = getTranslate(storeLang.getState().localize);
	const [actionOpen, setActionOpen] = React.useState<ActionOpen>(ActionOpen.NONE);
	const [groups_, setGroups_] = React.useState<Group[]>([]);
	const groups = React.useRef(groups_);
	function setGroups(newGroups: Group[]) {
		groups.current = [...newGroups];
		setGroups_([...newGroups]);
	}

	React.useEffect(() => {
		props.setToolBarState({
			bottomRightToolbarComponent: <div>
				<Restricted to={{ objectAction: 'CreateAccountSettings' }}>
					<DefaultButton
						onClick={() => {
							setGroupId(-1);
							setActionOpen(ActionOpen.CREATE);
						}}
					><Translate id="admin.create_group" /></DefaultButton>
				</Restricted>
			</div>
		});
		getGroups()
			.then(res => setGroups(res.data))
			.catch(console.log);
	}, []);

	const columns: Column<Group>[] = React.useMemo(
		(): Column<Group>[] => {
			return [
				{
					id: 'group_name',
					Header: translate('admin.table.name') as string,
					accessor: (row: Group) => <TableRowTitle 
						onClick={() => { setActionOpen(ActionOpen.EDIT); setGroupId(row.id); }}
					>
						{row.name}
					</TableRowTitle>,
					disableSortBy: true,
					width: undefined,
					maxWidth: 'unset'
				},
				{
					id: 'user_nb',
					Header: translate('admin.table.user_count') as string,
					accessor: (row: Group) => <TableRow style={{ cursor: 'pointer' }} onClick={() => { setActionOpen(ActionOpen.EDIT); setGroupId(row.id); }}>{row.user_count}</TableRow>,
					disableSortBy: true,
					width: undefined,
					maxWidth: 'unset'
				}
			];
		},
		[]
	);

	async function groupUpdate(result: GroupDetailResult): Promise<void> {
		setActionOpen(ActionOpen.NONE);
		const body = {
			group_id: result.group.id,
			group_name: result.groupName,
			policies: result.policies.map(policy => {
				return {
					group_id: policy.groupId,
					object_action: policy.objectAction,
					scope: policy.scope
				};
			})
		};
		const res = await updateGroup(body);
		if (res.status !== 200) {
			console.log(res);
			return;
		}

		for (const member of result.members) {
			if (member.to_remove) {
				await delUserFromGroup(member.id, result.group.id);
			} else {
				try {
					await addUserToGroup(member.id, result.group.id);
				} catch (e) {
					continue;
				}
			}
		}

		await getGroups()
			.then(res => setGroups(res.data))
			.catch(console.log);
	}

	function groupCreation(result: GroupDetailResult): void {
		setActionOpen(ActionOpen.NONE);
		const body = {
			group_name: result.groupName,
			policies: result.policies.filter(p => p.scope !== undefined).map(policy => {
				return {
					scope: policy.scope,
					object_action: policy.objectAction
				};
			})
		};

		createGroup(body)
			.then(async res => {
				const groupId: number = res.data;

				result.members.forEach(async(m) => {
					if (!m.to_remove) {
						await addUserToGroup(m.id, groupId);
					}
				});

				await getGroups()
					.then(res => {
						setGroups(res.data);
					});
			})
			.catch(console.log);
	}

	return (
		<div>
			<RightContainer>
				<Table
					height="calc(100vh - 174px)"
					columns={columns}
					data={groups.current}
					noBorder
				/>
			</RightContainer>
			<Popup
				isOpen={actionOpen != ActionOpen.NONE}
				popupStyle={{ width: '100%', height: '100%', top: '0px', left: '0px', animate: true, borderRadius: '10px 0 0 10px' }}
				popupMode={PopupMode.Default}
				hasBackground
				onClickOut={() => setActionOpen(ActionOpen.NONE)}
				content={<GroupDetail
					groups={groups}
					setGroups={setGroups}
					groupId={groupId}
					onClose={() => setActionOpen(ActionOpen.NONE)}
					onDelete={(group) => {
						if (group.user_count == 0) {
							// @ts-expect-error swal
							swal.fire({
								title: 'Attention !',
								text: translate('admin.delete_group_warning'),
								type: 'warning',
								showCancelButton: true,
								cancelButtonColor: RedSidely,
								cancelButtonText: translate('global.cancel'),
								confirmButtonColor: BlueSidely,
								confirmButtonText: translate('global.yes'),
								reverseButtons: true
							})
								.then(result => {
									if (result.value) {
										setActionOpen(ActionOpen.NONE);
										deleteGroup(group.id)
											.then(async() => {
												await getGroups()
													.then(res => {
														setGroups(res.data);
														const l = res.data.sort((a, b) => a.id - b.id);
														setGroupId(l[l.length - 1].id);
													})
													.catch(console.log);
											})
											.catch(console.log);
									}
								});
						} else {
							// @ts-expect-error swal
							swal.fire({
								title: translate('global.fire.error_title'),
								text: translate('admin.delete_group_user_count'),
								type: 'error',
								confirmButtonColor: RedSidely,
								confirmButtonText: 'Ok'
							});
						}
					}}
					onUpdate={async(result) => {
						await groupUpdate(result);
					}}
					onCreate={(result) => {
						groupCreation(result);
					}}
					actionOpen={actionOpen} />}
			/>
		</div>
	);
}
