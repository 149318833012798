/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useMemo } from 'react';
import { ComponentProps } from '../globals/mainPage/mainPage';
import { useRecoilValue } from 'recoil';
import { AProducts, Product } from '../../atoms/product';
import { AFormFields, Field } from '../../atoms/forms';
import { DropdownData } from '../../components_v2/dropdown/model/Model';
import Dropdown from '../../components_v2/dropdown/Dropdown';
import { useFunctionState } from '../../utils/customHooks';
import optionGrey from 'images/icon/three_dots.svg';
import * as XLSX from 'xlsx';
import deleteImage from 'images/icon/delete.png';
import { validate } from 'uuid';
import { Column, Table } from '../../components_v2/table/Table';
import { TableRow, TableRowTitle } from '../orders/templateOrders/style/Style';
import { getAdditionalValues, getAdditionalValuesViews, importAdditionalValues, refreshThericalPresence, saveAdditionalValue } from './action';
import FieldEditor from '../../utils/fieldEditor';
import { LoadingStateEnum } from '../import/model';
import { Loader } from '../../styles/global/css/GlobalLoader';
import { FlexDiv } from './style';
import { creationPopupDropdownStyle } from '../client-companies/style/Style';
import rowSvg from 'images/icon/row_with_arrow.svg';
import styled from 'styled-components';
import Add from '../../components_v2/add/Add';
import { DarkGreySidely2 } from '../../styles/global/css/Utils';
import ToolbarFilter, { ToolbarElement } from '../../components_v2/toolbarFilter/ToolbarFilter';
import { AtomCategory } from '../../atoms/utils/model/Model';
import { AInputSearch } from '../../atoms/filter/InputSearchFilter';
import { Translate, translateToString } from '../../styles/global/translate';
import { CleanButton } from '../../components_v2/dropdown/style/Style';
import Pagination from '../../components_v2/pagination/Pagination';
import { PaginationResult } from '../../components_v2/pagination/model/Model';
import { ComponentLoader } from '../map/modalRight/ModalCalendar';
import { DefaultButton } from '../../styles/global/css/GlobalButton';
import { ToolbarBox } from '../globals/defaultToolbar/style/Style';
import { AColors } from '../../atoms/colors/colors';
import { Dot } from '../../styles/global/css/Dot';
import { RawViewPicker, ViewsCategory } from '../../components_v2/view/ViewPicker';
import { ABrand, AEmptyBrand } from '../../atoms/filter/BrandFiltersAtom';
import { AProductFilter } from '../../atoms/filter/productsFilterAtom';
import ProductPopup, { BarcodeWrapper } from './product';
import { ModalState } from './model';
import Switch from '../../components_v2/Switch/Switch';
import { getBase64 } from '../import/UploadFile';
import readXlsxFile from 'read-excel-file';
import hash from 'object-hash';
import Popup from '../../components_v2/popup/Popup';
import { launchToastError } from '../forms/visual-form-editor/visualFormEditor';
import { FieldType } from 'bindings/forms/FieldType';
import assert from 'assert';
import { PopupMode } from '../../components_v2/popup/model/Model';
import { Delete } from '../client-companies/style/NewPhotoStyle';


type InnerColumnType = { product: Product, field?: Field, values: Record<string, unknown>, comparaisonsValues: unknown[] };
type ColumnType = Column<InnerColumnType>;
export type AdditionalValue = [Record<any, any>, any];
type StaticValues = Record<string | number, unknown>;

export type PathAndValue = {
	path: AdditionalValue[0],
	value: AdditionalValue[1]
}

type AdditionalValueView = {
	id: number,
	name: string,
	count: number,
	field: number,
	path: Record<number, unknown>,
	fieldToShowId?: number
};

type BuidPathOptions = {
	product?: string,
	staticValues?: Record<string | number, unknown>,
	chosenField?: { id: number, value: unknown },
	f?: unknown 
}

function buildCellPath(opt: BuidPathOptions) {
	const path = { };
	if (opt.product) path['p'] = opt.product;
	if (opt.chosenField) path[opt.chosenField.id] = opt.chosenField.value;
	if (opt.f) path['f'] = opt.f;
	return { ...path, ...opt.staticValues };
}

function findAdditionalValueRef(path: Record<string | number, unknown>, values: AdditionalValue[]): AdditionalValue | undefined {
	return values.find(([key]) => {
		if (Object.keys(key).length !== Object.keys(path).length) return false;
		for (const k in path) {
			if (key[k] !== path[k]) return false;
		}
		return true;
	});

}

function findAdditionalValue(path: Record<string | number, unknown>, values: AdditionalValue[]) {
	return findAdditionalValueRef(path, values)?.[1];
}

const Icon = styled.img<{flip?: boolean }>`
	width: 30px;
	transform: ${p => p.flip ? 'rotate(-90deg) scaleX(-1); ' : 'none;'}
`;

const Select = styled.select<{ border?: boolean }>`
	${({ border }) => border ? '' : ' border: none; width: 100%;'}
	font-size: 12px;
	font-weight: 400;
	color: ${DarkGreySidely2};
`;

const SwitchText = styled.p`
	margin: 0;
`;

const FILTER_HEIGHT = 40;
const FILTER_HEIGHT_S = `${FILTER_HEIGHT}px`;

const DropdownStyle = { ...creationPopupDropdownStyle, containerBorder: 'none', height: FILTER_HEIGHT_S };

const LocalButton = styled(DefaultButton)`
	margin: 0;
`;

type FieldFilters = { field?: Field, fieldValue?: unknown }[];

function staticValuesFromFilters(filters: FieldFilters): StaticValues {
	return filters.reduce<StaticValues>((acc, { field, fieldValue }) => {
		if (!field || !fieldValue) return acc;
		acc[field.id] = fieldValue;
		return acc;
	}, {});
}

const LOCAL_STORAGE_RESIZE_KEY = 'table_assortment_v3_resize';

export default function AssortmentListingV3(props: ComponentProps) {
	const inputSearchText = useRecoilValue(AInputSearch);
	const colors = useRecoilValue(AColors);
	const products = useRecoilValue(AProducts);
	const fields = useRecoilValue(AFormFields);
	const [loadingState, setLoadingState] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADED);
	const [additionalFieldsFilters, setAdditionalFieldsFilters] = React.useState<FieldFilters>([]);
	const staticValues = React.useMemo<StaticValues>(() => staticValuesFromFilters(additionalFieldsFilters), [additionalFieldsFilters]);
	const [productPopupState, setProductPopupState] = React.useState<ModalState<number>>({ isOpen: false });
	const [additionalValues, setAdditionalValues] = React.useState<AdditionalValue[]>([]);
	const [pagination, setPagination] = React.useState<PaginationResult>({ offset: 0, step: 25, currentPage: 1 });
	const [comparaisons, setComparaisons] = React.useState<{ filters: FieldFilters, color: string, id: number, staticValues: StaticValues, filterEmptyRows: boolean }[]>([]);
	const [views, setViews] = React.useState<ViewsCategory<AdditionalValueView>[]>([]);
	const [resize, setResize] = React.useState(JSON.parse(localStorage.getItem(LOCAL_STORAGE_RESIZE_KEY) || '{}'));
	const brandFilter = useRecoilValue(ABrand);
	const emptyBrand = useRecoilValue(AEmptyBrand);
	const productFilter = useRecoilValue(AProductFilter);
	const [filterEmptyRows, setFilterEmptyRows] = React.useState(false);
	const [popupOpen, setPopupOpen] = React.useState(false);
	const [popupHeight, setPopupHeight] = React.useState(300);
	const fieldsMap = React.useMemo<Map<number, Field>>(() => fields.reduce<Map<number, Field>>((acc, field) => {
		acc.set(field.id, field);
		return acc;
	}, new Map()), []);
	const additionalValuesHashMap = React.useMemo(() => {
		const res = new Map<string, any>();
		for (const [path, value] of additionalValues) {
			res.set(hash(path), value);
		}
		return res;
	}, [additionalValues]);

	const [fieldToShow, setFieldToShow] = useFunctionState<Field | undefined>(undefined, ({ newValue }) => {
		if (!newValue) return undefined;
		setAdditionalFieldsFilters(t => {
			if (t.some(t => !t.field?.id)) return t;
			return t.filter(t => t.field?.id != newValue?.id);
		});
		return newValue;
	});
	const [fieldToSet, setFieldToSet] = useFunctionState<Field | undefined>(undefined, ({ newValue }) => {
		if (!newValue) return undefined;
		setFieldToShow(fts => {
			if (fts && fts.id == newValue?.id) return undefined;
			return fts;
		});
		setAdditionalFieldsFilters(t => {
			if (t.some(t => !t.field?.id)) return t;
			return t.filter(t => t.field?.id != newValue?.id);
		});
		return newValue;
	});


	React.useEffect(() => {
		let id = 1;
		getAdditionalValuesViews().then(res => {
			let mapped = res.map(([path, count]) => {
				const res: AdditionalValueView = { count, path: {}, field: path['f'], id, name: '' };
				let name: string | undefined = undefined;
				id += 1;
				let oneField = true;
				for (const [key, value] of Object.entries(path)) {
					const fieldId = parseInt(key);
					if (isNaN(fieldId)) continue;
					if (oneField == true && res.fieldToShowId !== undefined) oneField = false;
					res.fieldToShowId = fieldId;
					res.path[fieldId] = value;
					const rawName = `${fieldsMap.get(fieldId)?.name}: "${value}"`;
					if (name === undefined) {
						name = rawName;
					} else {
						name += ` - ${rawName}`;
					}
				}
				if (!oneField) delete res.fieldToShowId;
				else if (res.fieldToShowId !== undefined) name = fieldsMap.get(res.fieldToShowId)?.name ?? 'unknown';
				res.name = name ?? 'unknown';
				return res;
			});
			const { merged, other } = mapped.reduce((acc: { merged: Record<string, AdditionalValueView>, other: AdditionalValueView[]}, v) => {
				if (!v.fieldToShowId) {
					acc.other.push(v);
					return acc;
				} else {
					const key = `${v.fieldToShowId} ${v.field}`;
					if (acc.merged[key] === undefined) acc.merged[key] = v;
					else acc.merged[key].count += v.count;
				}
				return acc;
			}, { merged: {}, other: [] });
			mapped = [...Object.values(merged), ...other];
			mapped.sort((a, b) => b.count - a.count);
			const categories: Record<number, ViewsCategory<AdditionalValueView>> = {};
			for (const view of mapped) {
				if (!categories[view.field]) categories[view.field] = { title: fieldsMap.get(view.field)?.name ?? 'unknown', views: [], editable: false, deletable: false };
				view.name += ` (${view.count})`;
				categories[view.field].views.push(view);
			}
			const categoriesArray = Object.values(categories);
			categoriesArray.sort((a, b) => (a.title as string).localeCompare(b.title as string));
			return setViews(categoriesArray);
		});
	}, []);

	const refresh = React.useCallback(() => {
		if (!fieldToSet) {
			setLoadingState(LoadingStateEnum.LOADED);
			return;
		}
		const params = new URLSearchParams({ path: JSON.stringify({ f: fieldToSet.id }) });
		setLoadingState(LoadingStateEnum.LOADING);
		getAdditionalValues(params.toString()).then(res => {
			setLoadingState(LoadingStateEnum.LOADED);
			return setAdditionalValues(res);
		}).catch(() => setLoadingState(LoadingStateEnum.ERROR));
	}, [fieldToSet]);


	React.useEffect(() => {
		refresh();
	}, [fieldToSet]);



	const handleChange = React.useCallback((file: File) => {
		getBase64(file, async loadedFile => {
			let res: PathAndValue[];
			if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
				res = await readXlsxFile(loadedFile).then((rawExcel: any[][]) => rawExcel.reduce<PathAndValue[]>((acc, [path, rawValue]) => {
					try {
						let value;
						try {
							value = JSON.parse(rawValue);
						} catch (_) {
							value = rawValue;
						}
						acc.push({
							path: JSON.parse(path),
							value
						});
					} catch (e) {
						console.error(e.message);
					}
					return acc;
				}, []));
			} else {
				res = [];
				// TODO accept csv
				//readString(loadedFile, {
				//	complete: (res) => {
				//		console.log(res);
				//	},
				//	worker: true
				//});
			}
			if (res.length == 0) {
				launchToastError('erreur dans le json du excel');
				return;
			}
			setLoadingState(LoadingStateEnum.LOADING);
			importAdditionalValues(res).then(refresh);
		});
	}, [refresh]);

	const list: DropdownData[] = [];
	list.push(
		{ label: translateToString('import.import_data') + ' (JSON)', value: 1 },
		{ label: translateToString('import.import_data') + ' (Excel)', value: 2 },
		{ label: 'Recalculer la DN theorique', value: 3 }
	);

	React.useEffect(() => props.setToolBarState({
		bottomLeftToolbarComponent: <ToolbarBox>
			<RawViewPicker<AdditionalValueView>
				buttonTitle={translateToString('defined_values')}
				onCreate={undefined}
				onEdit={undefined}
				noBorder={undefined}
				setEditedView={undefined}
				onDelete={undefined}
				categories={views}
				onViewChange={(v) => {
					// TODO add popup when v.fieldToShowId is undefined and path.length > 1
					setFieldToSet(fieldsMap.get(v.field));
					if (v.fieldToShowId !== undefined) {
						setFieldToShow(fieldsMap.get(v.fieldToShowId));
					} else {
						const newAdditionalFieldsFilters: FieldFilters = [];
						for (const [key, value] of Object.entries(v.path)) {
							const fieldId = parseInt(key);
							if (isNaN(fieldId)) continue;
							newAdditionalFieldsFilters.push({ field: fieldsMap.get(fieldId), fieldValue: value });
						}
						const newFieldToShow = newAdditionalFieldsFilters.pop();
						setFieldToShow(newFieldToShow?.field);
						setAdditionalFieldsFilters(newAdditionalFieldsFilters);
					}

				}}
			/>
			<ToolbarFilter
				category={AtomCategory.ASSORTMENTS}
				elements={[
					{ kind: ToolbarElement.BRAND_FILTER, withoutBrandOption: true },
					ToolbarElement.PRODUCT_PER_CATEGORY
				]}
			/>
			{!!fieldToSet && !!fieldToShow && <LocalButton onClick={() => setComparaisons(t => {
				if (t.some(t => t.filters.length == 0)) return t;
				const color = colors.find(c => !t.some(t => t.color == c.colorCode))?.colorCode;
				if (!color) return t;
				return [...t, { filters: [{}], color, id: Date.now(), staticValues: {}, filterEmptyRows: false }];
			})}>Ajouter une ligne de comparaison</LocalButton>}

		</ToolbarBox>,
		bottomRightToolbarComponent:
			<ToolbarBox>
				<Dropdown
					datalist={list}
					name='dropdown_actions'
					readOnly
					JSXButton={() => (
						<img
							src={optionGrey}
							className="custom-icon"
							style={{ marginTop: '3px' }}
							alt=""
						/>
					)}
					dropdownStyle={{
						optionWidth: '200px',
						optionLeft: '-175px'
					}}
					onChange={(value: DropdownData) => {
						if (value.value === 1) {
							const input = document.createElement('input');
							input.type = 'file';
							input.accept = '.xlsx';
							input.onchange = async(e) => {
								const buffer = await (e.target as HTMLInputElement).files![0];
								handleChange(buffer);
							};
							input.click();
						}
						else if (value.value === 2) {
							setPopupOpen(true);
						}
						else if (value.value === 3) {
							refreshThericalPresence().then(res => console.log(res.statusText));
						}
					}}
				/>
				{/* <Upload
					showUploadList={false}
					name='import'
					onChange={handleChange}
					//@ts-expect-error antd types are wrong
					customRequest={dummyRequest}
					accept='.xlsx'
				>
					<LocalButton><Translate id="import.import_data" /> (JSON)</LocalButton>
				</Upload> */}
				<ToolbarFilter
					category={AtomCategory.ASSORTMENTS}
					elements={[
						ToolbarElement.INPUT_SEARCH
					]}
				/>
			</ToolbarBox>,
		title: translateToString('product.assortments'),
	}), [!!fieldToSet && !!fieldToShow, views, fieldsMap]);

	React.useEffect(() => {
		localStorage.setItem(LOCAL_STORAGE_RESIZE_KEY, JSON.stringify(resize));
	}, [resize]);

	const allowedValues = React.useMemo(() => {
		if (fieldToShow === undefined) return undefined;
		return Object.entries(fieldToShow.constraint ?? {}).reduce<any[] | undefined>((acc, [constraintField, constraintMap]) => {
			if (!staticValues[constraintField]) return acc;
			if (typeof staticValues[constraintField] !== 'string') throw 'Constraint field value is not a string';
			const allowedValues: string[] = constraintMap[staticValues[constraintField]];
			if (acc === undefined) {
				return allowedValues;
			} else {
				return acc.filter(v => allowedValues.includes(v));
			}
		}, undefined);
	}, [fieldToShow, staticValues]);

	const productFields: DropdownData<Field>[] = React.useMemo(() => fields.filter(f => f.is_additional).map(f => ({ value: f, label: f.name })), [fields]);
	const fieldToShowData = productFields.filter(f => f.value.id != fieldToSet?.id);

	const data = React.useMemo(() => {
		const lowerInput = inputSearchText.toLocaleLowerCase();

		return products.reduce<InnerColumnType[]>((acc, p) => {
			if (brandFilter !== undefined && !brandFilter.some(b => b.id == p.brand) && (!emptyBrand || !!p.brand)) return acc;
			if (productFilter && !productFilter.all && !productFilter.products.includes(p.id)) return acc;
			if (lowerInput && !p.name.toLocaleLowerCase().includes(lowerInput)) return acc;

			const values: Record<string, unknown> = {};
			const comparaisonsValues: unknown[] = [];
			for (const comparaison of comparaisons) {
				const path = buildCellPath({ product: p.uuid, staticValues: comparaison.staticValues, f: fieldToSet?.id });
				const additionalValue = additionalValuesHashMap.get(hash(path));
				if (comparaison.filterEmptyRows && !additionalValue) return acc;
				comparaisonsValues.push(additionalValue);
			}
			if (fieldToShow === undefined || !Array.isArray(fieldToShow.data)) {
				acc.push({ product: p, values, comparaisonsValues });
				return acc;
			}
			let rowEmpty = true;
			for (const value of (fieldToShow.data as string[]).filter(f => !allowedValues || allowedValues.includes(f))) {
				const newStaticValues = { ...staticValues, f: fieldToSet?.id };
				if (fieldToShow) newStaticValues[fieldToShow.id] = value;
				const path = buildCellPath({ product: p.uuid, staticValues: newStaticValues });
				const additionalValue = additionalValuesHashMap.get(hash(path));
				if (filterEmptyRows && additionalValue) rowEmpty = false;
				values[value] = additionalValue;
			}
			if (filterEmptyRows && rowEmpty) return acc;
			acc.push({
				product: p,
				field: fieldToSet,
				values,
				comparaisonsValues
			});

			return acc;

		}, []);
	},
	[products, fieldToSet, inputSearchText, brandFilter, emptyBrand, productFilter, filterEmptyRows, additionalFieldsFilters, comparaisons, fieldToShow, allowedValues, additionalValuesHashMap]);

	const slicedData = data.slice(pagination.offset, pagination.offset + pagination.step);

	return <FlexDiv flow='column' align='stretch' gap='10px'>
		<FlexDiv width='100%' gap='10px'>
			<FlexDiv flexShrink={0} gap='10px' backgroundColor='white'padding='0 5px' ><Icon src={rowSvg} /><Dropdown dropdownStyle={DropdownStyle} datalist={productFields} selectedValue={productFields.find(f => f.value.id == fieldToSet?.id)} onChange={v => setFieldToSet(v.value)} cancellable name='dd1' /></FlexDiv>
			<FlexDiv flexShrink={0} gap='10px' backgroundColor='white'padding='0 5px' ><Icon src={rowSvg} flip/><Dropdown dropdownStyle={DropdownStyle} datalist={fieldToShowData} selectedValue={productFields.find(f => f.value.id == fieldToShow?.id)} onChange={v => setFieldToShow(v.value)} cancellable name='dd2' /></FlexDiv>
			<FilterBuilder
				filters={additionalFieldsFilters}
				setFilters={setAdditionalFieldsFilters}
				fieldToShowData={fieldToShowData}
				productFields={productFields}
				showAdd={!!fieldToSet && !!fieldToShow}
				filterEmptyRows={filterEmptyRows}
				setFilterEmptyRows={setFilterEmptyRows}
			/>
		</FlexDiv>
		<Popup
			popupMode={PopupMode.Centered}
			popupStyle={{ height: popupHeight + 'px', overflow: 'auto' }}
			isOpen={popupOpen}
			onClickOut={() => setPopupOpen(false)}
			content={
				<>
					<Import setPopupHeight={setPopupHeight} onQuit={
						() => {
							setPopupOpen(false);
						}
					} onSucess={
						() => {
							refresh();
							setPopupOpen(false);
						}
					} />
				</>
			}
		/>
		{comparaisons.map((comparaison, index) => <FlexDiv width='100%' gap='10px' height={FILTER_HEIGHT_S} key={comparaison.id}>
			<Dot color={comparaison.color}/>
			<FilterBuilder
				filters={comparaison.filters}
				setFilters={t => setComparaisons((comparaisons) => {
					comparaisons[index].filters = t(comparaisons[index].filters);
					if (comparaisons[index].filters.length == 0) comparaisons.splice(index, 1);
					else comparaisons[index].staticValues = staticValuesFromFilters(comparaisons[index].filters);
					return [...comparaisons];
				})}
				filterEmptyRows={comparaison.filterEmptyRows}
				setFilterEmptyRows={b => setComparaisons((comparaisons) => {
					comparaisons[index].filterEmptyRows = b;
					return [...comparaisons];
				})}
				fieldToShowData={fieldToShowData}
				productFields={productFields}
				showAdd={true}
			/>
		</FlexDiv>)}
		<Table
			resizeValue={resize}
			EnableResize
			onResize={(value) => Object.keys(value).length > 0 && setResize(value)}
			height={`calc(100vh - ${250 + comparaisons.length * (FILTER_HEIGHT + 10)}px)`}
			columns={React.useMemo<ColumnType[]>(() => {
				const columns: ColumnType[] = [{
					disableSortBy: true,
					disableFilter: true,
					freeze: 'left',
					Header: 'name',
					id: 'name',
					accessor: (p) => <TableRowTitle onClick={() => setProductPopupState({ data: p.product.id, isOpen: true })}>{p.product.name}</TableRowTitle>,
					toolTip: (p) => p.product.name,
				},
				{
					disableSortBy: true,
					disableFilter: true,
					id: 'barcode',
					Header: translateToString('EAN-JAN barcode'),
					accessor: (p) => <TableRow>{p.product.barcode}</TableRow>,
					toolTip: (p) => <BarcodeWrapper format='EAN13' value={p.product.barcode ?? ''} width={1} height={70} botMargin='0px'/>,
					toolTipStyle: { backgroundColor: 'white', borderColor: 'black' }
				}];

				!!fieldToSet && !fieldToShow && columns.push({
					disableSortBy: true,
					disableFilter: true,
					Header: fieldToSet.name,
					id: 'value-without-field-to-show',
					accessor: p => {
						const additionalValue = findAdditionalValue(buildCellPath({ product: p.product.uuid, staticValues: { f: fieldToSet.id } }), additionalValues);
						return <Cell product={p.product} field={fieldToSet} additionalValue={additionalValue} setAdditionalValues={setAdditionalValues} staticValues={{ f: fieldToSet.id }} />;
					}
				});


				!!fieldToSet && comparaisons.forEach((comparaison, i) => {
					columns.push({
						disableSortBy: true,
						disableFilter: true,
						id: `COMPARAISON[${comparaison.id}]`,
						Header: <Dot color={comparaison.color} size='15px'/>,
						accessor: (p) => <Cell product={p.product} field={fieldToSet} staticValues={{ ...comparaison.staticValues, f: p.field?.id }} additionalValue={p.comparaisonsValues[i]} readonly />
					});
				});

				if (fieldToShow === undefined || !Array.isArray(fieldToShow.data)) return columns;
				for (const value of (fieldToShow.data as string[]).filter(f => !allowedValues || allowedValues.includes(f))) {
					columns.push({
						disableSortBy: true,
						disableFilter: true,
						visualization: true,
						Header: value,
						id: `ADDITIONAL_VALUE[${value}]`,
						accessor: (p) => <Cell product={p.product} field={p.field!} additionalValue={p.values[value]} setAdditionalValues={setAdditionalValues} staticValues={{ ...staticValues, [fieldToShow.id]: value, f: p.field?.id }} />
					});
				}
				return columns;
			}, [fieldToShow, staticValues, comparaisons, allowedValues, additionalValues])}
			data={slicedData} />
		<Pagination label={'products'} steps={[25, 50, 100]} defaultStep={25} onChange={setPagination} amount={data.length}/>
		<ComponentLoader loadingState={loadingState} allScreen/>
		<ProductPopup isOpen={productPopupState.isOpen} setIsOpen={(isOpen) => setProductPopupState({ isOpen })} productId={productPopupState.data} />
	</FlexDiv>;
}

function FilterBuilder(props: {
	filters: FieldFilters,
	setFilters: (value: ((prevState: FieldFilters) => FieldFilters)) => void,
	productFields: DropdownData<Field>[],
	fieldToShowData: DropdownData<Field>[],
	showAdd: boolean,
	setFilterEmptyRows: (b: boolean) => void
	filterEmptyRows: boolean
}) {
	const { filters, setFilters, productFields, fieldToShowData } = props;
	return <>
		{filters.length > 0 && <FlexDiv gap='10px' height={FILTER_HEIGHT_S} overflow='auto'>
			{filters.map((t, myIndex) => (
				<FlexDiv key={t.field?.id} gap='10px' backgroundColor='white' padding='0 5px' flexShrink={0} height='100%'>
					<Select border={false} value={t.field?.id ?? -1} key={t.field?.id} id={`select_additional_fields[${t.field?.id}]`} onChange={e => {
						const parsedId = parseInt(e.target.value);
						const field = productFields.find(f => f.value.id == parsedId)?.value;
						return setFilters(t => {
							if (t[myIndex]?.field?.id == parsedId) return t;
							t[myIndex] = { field, fieldValue: undefined };
							return [...t];
						});
					} }>
						<option disabled value={-1}></option>
						{fieldToShowData.map((v) => (
							<option hidden={filters.some((t) => t.field?.id == v.value.id)} value={v.value.id} key={v.value?.id}>{v.value.name}</option>
						))}
					</Select>
					{t.field?.id && <FieldEditor field={t.field} value={t.fieldValue} onSave={v => setFilters(t => {
						t[myIndex].fieldValue = v;
						return [...t];
					})} />}

					<CleanButton
						className="btn btn-transparent p-0"
						type="button"
						onClick={() => {
							setFilters(t => {
								t.splice(myIndex, 1);
								return [...t];
							});
						}}
					>
						<i className="fas fa-times" />
					</CleanButton>
				</FlexDiv>
			))
			}
		</FlexDiv>}

		{props.showAdd && <Add onClick={() => setFilters(t => {
			if (t.some(t => !t.field?.id)) return t;
			return [...t, {}];
		})} />}
		{props.showAdd && <FlexDiv flexShrink={0} gap='10px'><Switch value={props.filterEmptyRows} onChange={props.setFilterEmptyRows}/> <SwitchText><Translate id='filter_empty_rows' /></SwitchText></FlexDiv>}
	</>;

}

function Cell({ product, staticValues, setAdditionalValues, readonly, additionalValue, field }: { field: Field, product: Product, staticValues: StaticValues, setAdditionalValues?: React.Dispatch<React.SetStateAction<AdditionalValue[]>>, readonly?: boolean, additionalValue: any }) {
	const [loadingState, setLoadingState] = React.useState<LoadingStateEnum>(LoadingStateEnum.LOADED);
	if (loadingState === LoadingStateEnum.LOADING) return <Loader width='15px'/>;
	if (readonly) return <TableRow>{additionalValue}</TableRow>;
	return <TableRow>
		<FieldEditor field={field} value={additionalValue} onSave={vRaw => {
			const v = vRaw === '' ? null : vRaw; 
			const path = buildCellPath({ product: product.uuid, staticValues });
			setLoadingState(LoadingStateEnum.LOADING);
			saveAdditionalValue(path, v).then(() => {
				setLoadingState(LoadingStateEnum.LOADED);
				setAdditionalValues?.(av => {
					const ref = findAdditionalValueRef(path, av);
					if (ref) ref[1] = v;
					else av.push([path, v]);
					return [...av];
				});
			}).catch(() => setLoadingState(LoadingStateEnum.ERROR));
		}} />
	</TableRow>;
}

export interface SimpleSelectProps {
    options: string[];
    value?: string;
    emptiable?: boolean;
    onChange?: (value: string) => void;
}

export function SimpleSelect({ options, value, onChange, emptiable }: SimpleSelectProps) {
	return <select value={value} defaultValue={value === undefined ? '' : undefined} onChange={e => onChange?.(e.target.value)}>
		<option disabled={!emptiable} value=""></option>
		{options.map(o => <option key={o} value={o}>{o}</option>)}
	</select>;
}

export type Fields = Map<number, Field>;

export interface ImportProps {
	onSucess: () => void;
	setPopupHeight?: (height: number) => void;
	onQuit: () => void;
}


function validateEmail(email: string): boolean {
	return Boolean(
		String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			),
	);
}

function validatePhone(phone: string): boolean {
	return Boolean(String(phone).match(/^(\+?\d{1,3}[- ]?)?(\d{3}[- ]?){2}\d{3}$/));
}

const ExcelBooleans = ['true', 'True', 'TRUE', 'Vrai', 'vrai', 'VRAI', '1', 'V', 'v', 'Oui', 'oui', 'OUI', 'Y', 'y', 'Yes', 'yes', 'YES', 'X', 'x', 'O', 'o', 'On', 'on', 'ON', 'S', 's', 'Si', 'si', 'SI', 'J', 'j', 'Ja', 'ja', 'JA', 'T', 't', 'Yup', 'yup', 'YUP', 'Okey', 'okey', 'OKEY'];

export function doParseValue(type: FieldType, value: string): string | number | boolean | null | undefined {
	switch (type) {
		case 'Text':
			return value.trim() !== '' ? value : null;
		case 'Integer':
			return value.trim() !== '' ? parseInt(value) : null;
		case 'Number':
			return value.trim() !== '' ? parseFloat(value) : null;
		case 'Boolean':
			return ExcelBooleans.includes(value.trim());
		case 'Money':
			return value.trim() !== '' ? Math.round(parseFloat(value) * 100) : null;
		case 'Date':
			try {
				return (new Date(value)).toUTCString();
			} catch {
				return null;
			}
		case 'User':
			return Number(value);
		case 'Url': {
			const url = new URL(value);
			return url.toString();
		}
		case 'Email':
			assert(validateEmail(value), 'Invalid email');
			return value;
		case 'Phone':
			assert(validatePhone(value), 'Invalid phone');
			return value;
		case 'Select':
			return value.trim() !== '' ? value : null;
	}
}

export function parseValue(field: Field, value: string): string | number | boolean | null | undefined {
	const parsedValue = doParseValue(field.type, value);


	// if (parsedValue !== undefined && field.constraint && entity) {
	// 	assert(fieldsSync !== undefined, "Fields must be loaded to validate constraints on fields");

	// 	for (const [fieldId, values] of field.constraint.entries()) {
	// 		assert(values.tag === 6);
	// 		const valueOfConstraint = entity.fields.get(fieldId);
	// 		if (valueOfConstraint === undefined) {
	// 			continue;
	// 		}
	// 		if (!values.val.map((v) => v.val).includes(valueOfConstraint.val as any)) {
	// 			throw new Error(
	// 				`Value "${valueOfConstraint.val}" of field "${fieldsSync.get(fieldId)?.name}" is not allowed for field "${field.name}"`,
	// 			);
	// 		}
	// 	}
	// }
	return parsedValue;
}


export function Import({ onSucess, setPopupHeight, onQuit }: ImportProps) {
	const [open, setOpen] = React.useState(false);
	const [sheet, setSheet] = React.useState<any>();
	const [productColumn, setProductColumn] = React.useState<string>();
	const [staticValues, setStaticValues] = React.useState<[number, string][]>();
	const [valuesToSet, setValuesToSet] = React.useState<[number, string][]>();
	const [step, setStep] = React.useState<number>(0);
	const nextDisabled = step == 0 && !productColumn || step == 1 && (staticValues && staticValues.some(([, r]) => !r)) || (valuesToSet && valuesToSet.some(([, r]) => !r));
	const fieldsAtom = useRecoilValue(AFormFields);
	const keepEmpty = React.useRef(false);
	const reset = () => { setOpen(false); setSheet(undefined); setProductColumn(undefined); setStaticValues(undefined); setValuesToSet(undefined); setStep(0); };
	const products = useRecoilValue(AProducts);
	const [filename, setFilename] = React.useState<string | undefined>(undefined);
	

	const fields = React.useMemo(() => {
		return new Map(fieldsAtom.map(f => [f.id, f]));
	}, [fieldsAtom]);


	if (step >= 2) {
		setPopupHeight?.(400);
	} else if (step >= 1) {
		setPopupHeight?.(350);
	} else if (sheet) {
		setPopupHeight?.(250);
	} else {
		setPopupHeight?.(230);
	}

	return <FlexDiv flow='column' gap='10px' style={{ paddingTop: '40px' }}>
		<>
			<div>
				{sheet ? filename : translateToString('choose_file')}
				<DefaultButton disabled={(sheet !== undefined)} onClick={() => {
					if (sheet) {
						setOpen(true);
						return;
					}
					const input = document.createElement('input');
					input.type = 'file';
					input.accept = '.xlsx';
					input.onchange = async(e) => {
						const target = e.target! as HTMLInputElement;
						const buffer = await target.files![0].arrayBuffer();
						const workbook = XLSX.read(new Uint8Array(buffer), { type: 'array' });
						const sheet = workbook.Sheets[workbook.SheetNames[0]];
						setFilename(target.files![0].name);
						setSheet(XLSX.utils.sheet_to_json(sheet));
						setOpen(true);
					};
					input.click();
				}}>{translateToString('import_')}</DefaultButton>
			</div>
		</>
	
		{step == 1 && <ImportStepStaticValues sheet={sheet} productColumn={productColumn!} staticValues={staticValues ?? []} setStaticValues={setStaticValues} />}
		{step == 2 && <ImportStepValuesToSet sheet={sheet} productColumn={productColumn!} staticValues={staticValues ?? []} valuesToSet={valuesToSet ?? []} setValuesToSet={setValuesToSet} keepEmpty={keepEmpty} />}
		{step == 0 && <ImportStepProduct sheet={sheet} productColumn={productColumn} setProductColumn={setProductColumn} />}
		<div className="flex flex-row justify-between">

			{step == 0 ? <DefaultButton type="button" onClick={onQuit}>
				{translateToString('cancel')}
			</DefaultButton> : <DefaultButton type="button" onClick={() => setStep(step - 1)}>
				{translateToString('back')}
			</DefaultButton>}

			<DefaultButton
				disabled={nextDisabled}
				type="submit"
				onClick={() => {
					if (step == 2) {
						doImport(sheet, fields, productColumn!, staticValues, valuesToSet!, keepEmpty.current, products)
							.catch(e => {
								console.error(e);
								launchToastError(e.toString());
							})
							.then(() => {
								onSucess();
								reset();
							});
					} else {
						setStep(step + 1);
					}
				}}
			>
				{step == 2 ? translateToString('import_') : translateToString('next')}
			</DefaultButton>
		</div>
	</FlexDiv>;
}

function ImportStepProduct({ sheet, productColumn, setProductColumn }: { sheet: any, productColumn?: string, setProductColumn: (v: string) => void }) {
	return <>
		{sheet && <FlexDiv flow='row' gap='10px'>
			<span>{translateToString('import.choose_product_column')}</span><SimpleSelect value={productColumn} options={sheet ? Object.keys(sheet[0]) : []} onChange={setProductColumn} />
		</FlexDiv>}
	</>;
}

function ImportStepStaticValues({ sheet, productColumn, staticValues, setStaticValues }: { sheet: any, staticValues: [number, string][], productColumn: string, setStaticValues: (v: [number, string][]) => void }) {
	const fields = useRecoilValue(AFormFields);

	const fieldMap = useMemo(() => new Map(fields.map(f => [f.id, f])), [fields]);

	return <>
		{sheet && <>
			<div>{translateToString('import.choose_static_values')}</div>
			<FlexDiv flow='column' gap='10px'>
				{
					staticValues.map(([l, r], i) => <FlexDiv key={i} flow='row' gap='10px'>
						<SimpleSelect key={`a${i}`} value={fieldMap.get(l)?.name} options={fields.filter(f => f.is_additional).map(f => f.name)} onChange={v => setStaticValues([...staticValues.slice(0, i), [fields.find((f) => f.name == v)!.id!, r], ...staticValues.slice(i + 1)])} />
						<SimpleSelect key={`b${i}`} value={r} options={Object.keys(sheet[0]).filter(c => c != productColumn)} onChange={v => setStaticValues([...staticValues.slice(0, i), [l, v], ...staticValues.slice(i + 1)])} />
						<Delete src={deleteImage} onClick={() => {
							setStaticValues([...staticValues.slice(0, i), ...staticValues.slice(i + 1)]);
						}} />
					</FlexDiv>)
				}
			</FlexDiv>
			<div className="flex flex-row justify-center">
				<Add onClick={() => {
					setStaticValues([...staticValues, [fields.filter((f) => f.is_additional)[0]?.id ?? 0, '']]);
				}} />
			</div>
		</>}
	</>;
}

function ImportStepValuesToSet({ sheet, productColumn, staticValues, valuesToSet, setValuesToSet, keepEmpty }: { sheet: any, staticValues: [number, string][], valuesToSet: [number, string][], productColumn: string, setValuesToSet: (v: [number, string][]) => void, keepEmpty: React.MutableRefObject<boolean> }) {
	const fields = useRecoilValue(AFormFields);

	const fieldsMap = useMemo(() => new Map(fields.map(f => [f.id, f])), [fields]);

	return <><div>{translateToString('import.choose_values_to_import')}</div>
		<FlexDiv flow='column' gap='10px'>
			{
				valuesToSet.map(([l, r], i) => <FlexDiv key={i} flow='row' gap='10px'>
					<SimpleSelect key={i + valuesToSet.length} value={fieldsMap.get(l)?.name ?? ''} options={fields.filter((f) => f.is_additional && !staticValues.map(s => s[0]).includes(f.id)).map((f) => f.name)} onChange={v => setValuesToSet([...valuesToSet.slice(0, i), [fields.find((f) => f.name == v)!.id, r], ...valuesToSet.slice(i + 1)])} />
					<SimpleSelect key={i} value={r} options={Object.keys(sheet[0]).filter(c => c != productColumn && !staticValues.map(s => s[1]).includes(c))} onChange={v => setValuesToSet([...valuesToSet.slice(0, i), [l, v], ...valuesToSet.slice(i + 1)])} />
					<Delete src={deleteImage} onClick={() => {
						setValuesToSet([...valuesToSet.slice(0, i), ...valuesToSet.slice(i + 1)]);
					}} />
				</FlexDiv>)
			}
		</FlexDiv>
		<div className="flex flex-row justify-center">
			<Add onClick={() => setValuesToSet([...valuesToSet, [fields.filter((f) => f.is_additional && !staticValues.map(s => s[0]).includes(f.id))[0][0] ?? 0, '']])} />
		</div>
		<label className="text-sm flex items-center">{translateToString('import.keep_empty')}<input className="m-2" type="checkbox" onChange={e => keepEmpty.current = e.target.checked} /></label>
	</>;
}

async function doImport(sheet: Record<string, any>[], fields: Fields, productColumn: string, staticValues: [number, string][] | undefined, valuesToSet: [number, string][], keepEmpty: boolean, products: Product[]): Promise<null> {
	const additionalValues = sheet.flatMap(row => {
		const pUuid = products.find(p => p.name == row[productColumn])?.uuid;
		if (!validate(pUuid)) {
			throw new Error(`Invalid product: "${row[productColumn]}" at row ${row.__rowNum__} column "${productColumn}"`);
		}
		const path: Record<number | string, any> = { p: pUuid };
		if (staticValues) {
			for (const [f, r] of staticValues) {
				const field = fields.get(f);
				assert(field, `Field ${f} is undefined`);
				try {
					path[f] = parseValue(field, row[r].toString());
				} catch (e: any) {
					throw new Error(`Invalid value "${row[r]}" for field "${field.name}" at row ${row.__rowNum__} column "${r}": ${e}`);
				}
				if (path[f] === undefined) {
					throw new Error(`Invalid value "${row[r]}" for field "${field.name}" at row ${row.__rowNum__} column "${r}"`);
				}
			}
		}

		return valuesToSet.reduce((acc, [f, r]) => {
			const field = fields.get(f)!;
			try {
				const value = parseValue(field, row[r].toString());
				if (value === undefined && !keepEmpty) {
					return acc;
				}
				acc.push({ path: { ...path, f }, value });
				return acc;
			} catch (e: any) {
				throw new Error(`Invalid value "${row[r]}" for field "${field.name}" at row ${row.__rowNum__} column "${r}": ${e}`);
			}
		}, [] as PathAndValue[]);
	});

	return await importAdditionalValues(additionalValues);
}