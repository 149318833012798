import axios from 'axios';
import { Cookies } from 'react-cookie';
import { atom, selector } from 'recoil';
import { URL_FOUNDATION } from '../config/keys';
import { AtomCategory, AtomState } from './utils/model/Model';
import { FilterType } from 'bindings/filters/FilterType';
import { FieldType } from 'bindings/forms/FieldType';
import { Value } from '../containers_v2/reports/interpretor/bareReportingDecoder';
import { ViewCompanyColumns } from './global/views';
import { filterIdFromString, isAdditionalColumnFilter } from '../components_v2/filter/model/Model';
import { ValueKey } from 'bindings/reports/generic/ValueKey';
import { Group } from '../components_v2/Selector/actions';

export const ADDITIONAL_COLUMN_TYPE_LST = ['Integer', 'Number', 'String', 'Boolean', 'Date', 'Catalogue', 'ReportColumn', 'Company', 'Select', 'MultiSelect'] as const;
export type AdditionalColumnType = typeof ADDITIONAL_COLUMN_TYPE_LST[number];
export type ReportColumnType = Value['val'];
export type FirstValueNotPieChart = {
	id: number,
	column_type: 'additional_column' | 'calculated_field'
	value: ReportColumnType
}

export function isAdditionalColumnTypeVisibleOnMap(type: AdditionalColumnType): boolean {
	switch (type) {
		case 'Boolean':
		case 'Catalogue':
		case 'Date':
		case 'Integer':
		case 'Number':
		case 'Company':
		case 'MultiSelect':
		case 'Select':
			return true;
	}
	return false;
}

export type AdditionalColumn = {
	id: number,
	name: string,
	ishidded?: boolean
} & ({
	type: Exclude<AdditionalColumnType, 'Select' | 'MultiSelect'>,
	data: undefined,
} | { type: 'Select' | 'MultiSelect', data: string[] })

export type AdditionalFormFields = {
	field_id: number,
	value: string,
	name: string,
	field_type: FieldType,
}

export type CompanyAdditionalColumnValue = {
	additional_column_id: number,
	value: unknown,
	enriched_value?: string,

}

export function additionalColumnTypeToFilterTypeAndPath(type: AdditionalColumnType): { type: FilterType, path?: ValueKey } {
	switch (type) {
		case 'Integer': return { type: 'numeric' };
		case 'Number': return { type: 'numeric' };
		case 'Boolean': return { type: 'bool' };
		case 'Catalogue': return { type: 'catalogue' };
		case 'Date': return { type: 'temporal' };
		case 'String': return { type: 'string', path: 'text' };
		case 'ReportColumn': return { type: 'nullable' };
		case 'Company': return { type: 'company' };
		case 'Select': return { type: 'select' };
		case 'MultiSelect': return { type: 'multi_select' };
	}

}

function updateCompanyHiddenColumns(cols: AdditionalColumn[]) {
	const localColumns: ViewCompanyColumns[] = JSON.parse(localStorage.getItem('company_hidden_columns') || '[]');
	if (localColumns.length == 0) return;
	const mapped = localColumns.map(lc => filterIdFromString(lc.column));
	cols.filter(c => !mapped.some(m => {
		if (isAdditionalColumnFilter(m)) return m.additional_columns.some(ac => ac.id == c.id);
		return false;
	})).forEach(c => localColumns.push({ column: `{"additional_columns":[{"id":${c.id}}]}`, hidden: true }));
	localStorage.setItem('company_hidden_columns', JSON.stringify(localColumns));
}

// -----------------------[ ATOM ]----------------------- //
export const AGlobalAdditionalColumns: AtomState<AdditionalColumn[]> = {
	category: AtomCategory.GLOBAL,
	atom: atom({
		key: 'atom_global_additional_columns', // UNIQUE ID
		default: getAdditionalColumns()
	})
};

export const AAdditionalColumns = selector({
	key: 'AAdditionalColumns',
	get: async({ get }) => get(AGlobalAdditionalColumns.atom),
	set: ({ set }, newValue) => {
		if (Array.isArray(newValue)) updateCompanyHiddenColumns(newValue);
		set(AGlobalAdditionalColumns.atom, newValue);
	},
});

// -----------------------[ API ]------------------------ //
const cookies = new Cookies();
const token: string | null = cookies.get('id_token') || null;

axios.defaults.headers.common.Authorization = `${token ?? ''}`;

export async function getAdditionalColumns(): Promise<AdditionalColumn[]> {
	return axios.get(`${URL_FOUNDATION}/api/v2/additional-columns`).then(res => res.data).catch(() => []);
}

export async function getAdditionalColumnValue(columnId: number, companyId: number): Promise<string | number | object | null> {
	return axios.get(`${URL_FOUNDATION}/api/v2/additional-columns/${columnId}/${companyId}`).then(res => res.data);
}

export async function getAdditionnalFormFields(companyId: number): Promise<AdditionalFormFields[]> {
	return axios.get(`${URL_FOUNDATION}/api/v2/additional-columns/form-fields/${companyId}`).then(res => res.data);
}

export async function updateAdditionalColumnValue(columnId: number, companyId: number, value: undefined | string | number | object | null | boolean) {
	return axios.put(`${URL_FOUNDATION}/api/v2/additional-columns/${columnId}/${companyId}`, { value }, { headers: {
		'Content-Type': 'application/json'
	} }).then(res => res.data);
}

export async function deleteAdditionalColumnValue(columnId: number, companyId: number) {
	return axios.delete(`${URL_FOUNDATION}/api/v2/additional-columns/${columnId}/${companyId}`).then(res => res.data);
}

export async function getFirstValueNotPieChart(): Promise<FirstValueNotPieChart[]> {
	return axios.get(`${URL_FOUNDATION}/api/v2/additional-columns/first-value-not-pie-chart`).then(res => res.data);
}

export async function getClientCompanyAdditionalColumnsValues(companyId: number): Promise<CompanyAdditionalColumnValue[]> {
	return axios.get(`${URL_FOUNDATION}/api/v2/additional-columns/client-company/${companyId}`).then(res => res.data);
}

export async function getUserCategories(userId: number): Promise<Group[]> {
	return await axios.get(`${URL_FOUNDATION}/api/v2/additional-columns/groups/` + userId).then(res => res.data);
}

export async function syncUserCategories(userId: number, reportGroups: Group[]): Promise<void> {
	return await axios.put(`${URL_FOUNDATION}/api/v2/additional-columns/groups/` + userId, reportGroups);
}