/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from 'react';
import { MapEventRes, getMapAdditionalColumns, getMapAdditionalFieldColumns, getMapCampaigns, getMapCheckouts, getMapDistribution, getMapEvents, getMapForms, getMapFrequency, getMapInitial, getMapOrders, getMapTags } from './action';
import { checkByAllParams2 } from '../../helpers/data/map';
import ModalLeftFilter from './modalLeft/ModalLeftFilter';
import { clearDrawnPoly } from './lasso';
import ModalRight from './modalRight/ModalRight';
import { BlueSidely, GreenSidely } from '../../styles/global/css/Utils';
import { ComponentLoader } from './modalRight/ModalCalendar';
import { LoadingStateEnum } from '../import/model';
import {
	CampaignsMapRow,
	ClusterProperties,
	EventsMapRow,
	FormMapRow,
	FrequencyMapRow,
	InitialMapRow,
	MapCompany,
	MapData,
	Point,
} from './model';
import { FlexDiv } from '../products/style';
import ClientCompany from '../../components_v2/clientCompany/ClientCompany';
import { useRecoilValue } from 'recoil';
import { ACompanyEdition } from '../../atoms/company/companyEdition';
import { Event, EventType } from '../calendar/model/Model';
import * as moment from 'moment';
import { AUsers } from '../../atoms/global/users';
import { AOrderStatuses } from '../../atoms/global/orders';
import { AFrequencyEventType } from '../../atoms/global/frequency';
import { IFrequency, ITag } from 'proto/protobufs';
import { AEventTypes } from '../../atoms/global/events';
import { Translate } from 'react-localize-redux';
import { translateToString } from '../../styles/global/translate';
import { ToolbarBox } from '../globals/defaultToolbar/style/Style';
import { ToolbarState } from '../globals/mainPage/mainPage';
import { ModalState } from '../products/model';
import Popup from '../../components_v2/popup/Popup';
import { PopupMode } from '../../components_v2/popup/model/Model';
import ClusterPopupContainer from './clusterPopupContainer';
import Add from '../../components_v2/add/Add';
import Restricted from '../permissions/Restricted';
import ClientCompanyCreation from '../../components_v2/creation/ClientCompanyCreation';
import { FilterResult } from '../../components_v2/filter/model/Model';
import { DefaultTextDiv } from '../../styles/global/css/GlobalText';
import { SeclectionImage, SelectionAction, SelectionActionContainer, SelectionText } from '../client-companies/style/Style';
import Dropdown from '../../components_v2/dropdown/Dropdown';
import { getImageFromEventTypeName } from '../client-companies/Companies';
import { DropdownData } from '../../components_v2/dropdown/model/Model';
import trash_red from 'images/ui_icon/trash_red.svg';
import { Map as MabObj } from '../../../typings/proto/protobufs';
import calendar_black from 'images/menu_icon/calendar_black.svg';
import PencilVendorImage from 'images/vendors/pencil.svg';
import PopupCreation from '../../components_v2/popup/PopupCreation';
import CompanyBulkEventCreation from '../client-companies/popup/CompanyBulkEventCreation';
import CompanyBulkEdition from '../client-companies/popup/CompanyBulkEdition';
import PopupDeletion from '../../components_v2/popup/PopupDeletion';
import { deleteCompanies } from '../client-companies/data/action';
import ReactMap from './Map';
import { MapProvider } from './MapContext';
import { CompanyStatus } from '../client-companies/model/Model';
import { AGranularity } from '../../atoms/global/granularity';
import { PinId } from './modalLeft/TabPins';
import { AAdditionalColumns } from '../../atoms/additionalColumns';
import { AAdditionalFieldColumns } from '../../atoms/additionalFieldColumns';
import { aggregateNumberField } from './mapAdditionalColumns';
import { ACalculatedFields } from '../../atoms/calculatedFields';
import { ASelectedFormId } from '../../atoms/global/selectedForm';
import NewCompany from '../client-companies/popup/NewCompany';

export type FetchKey = 'campaign'| 'forms' | 'status' | 'frequencies' | 'urchin' | 'tags' | 'last' | 'next' | 'plan' | 'instore' | 'orders' | 'orders_dates' | 'orders_sums' | 'per_users' | 'checkouts' | PinId;
export type MinMax = {
	min: number,
	max: number
}
export type MinMaxAdditionalColumn = MinMax & {
	id: string,
	field: boolean
};

function mergeAndToPoint(arriving: any[], old?: MapCompany[]): { newList: MapCompany[], points: Point[] } {
	const points: Point[] = [];
	const newMap = new Map();
	const arrivingMap = new Map();
	arriving.forEach(c => arrivingMap.set(c.id, c));
	old?.forEach(company => {
		const newCompany = { ...company, ...(arrivingMap.get(company.id) ?? {}) };
		newMap.set(company.id, newCompany);
		if (newCompany.lon === undefined || newCompany.lat === undefined) return;
		points.push({
			properties: {
				cluster: false,
				id: newCompany.id!,
				color: newCompany.colorCode!,
				detail: newCompany
			},
			geometry: {
				coordinates: [newCompany.lon!, newCompany.lat!]
			}
		});
	});
	arriving.forEach(company => {
		if (!newMap.has(company.id)) newMap.set(company.id, { ...company });
		if (company.lon === undefined || company.lat === undefined) return;
		points.push({
			properties: {
				cluster: false,
				id: company.id!,
				color: company.colorCode!,
				detail: company
			},
			geometry: {
				coordinates: [company.lon!, company.lat!]
			}
		});
	});

	return {
		points: points,
		newList: Array.from(newMap, ([_, value]) => value)
	};
}

function dataToPoints(data?: MapCompany[]): Point[] {
	return data?.reduce((acc: Point[], company: MapCompany): Point[] => {
		if (company.lon === undefined || company.lat === undefined) return acc;
		acc.push({
			properties: {
				cluster: false,
				id: company.id!,
				color: company.colorCode!,
				detail: company
			},
			geometry: {
				coordinates: [company.lon!, company.lat!]
			}
		});
		return acc;
	}, []) ?? [];
}

function getDataByEventType(companies: MapEventRes[]): EventsMapRow[] {
	return companies.map(val => ({
		...val,
		events: val.lastEventType && val.lastEventDate ? [[val.lastEventType, moment(val.lastEventDate).format('YYYY-MM-DD'), undefined]] : [],
		range: val.lastEventDate ? moment().diff(val.lastEventDate, 'minutes') / 60 / 24 : undefined,
		next: !!val.nextEventType,
		nextRange: val.nextEventDate ? moment(val.nextEventDate).diff(moment(), 'minutes') / 60 / 24 : undefined,
		nextEvent: val.nextEventType && val.nextEventDate ? [[val.nextEventType, undefined, moment(val.nextEventDate).format('YYYY-MM-DD')]] : []
	}));
}

function mergeForms(arriving: Array<MabObj.IFormsMapRow>, formId: number, old?: MapCompany[]): MapCompany[] {
	const newList: MapCompany[] = old?.map(company => {
		const index = arriving.findIndex((arr: FormMapRow) => company.id == arr.id);
		const newCompany = { ...company };
		if (arriving[index]?.lastFormDate) {
			newCompany.formsRange = moment().diff(moment(arriving[index]?.lastFormDate.seconds * 1000), 'days') + 1;
			newCompany.formId = formId;
		} else {
			newCompany.formsRange = undefined;
		}
		return newCompany;
	}) ?? [];

	arriving.forEach((arr: FormMapRow) => {
		const formsRange: number = moment().diff(moment(arr.lastFormDate!.seconds * 1000), 'days');
		const newItem: MapCompany = {
			id: arr.id,
			events: [],
			nextEvent: [],
			formsRange,
			formId
		};
		newList.push(newItem);
	});
	return newList;
}

function mergeCampaign(arriving: Array<MabObj.ICampaignsMapRow>, old?: MapCompany[]): MapCompany[] {
	const newList: MapCompany[] = old?.map(company => {
		const index = arriving.findIndex((arr: CampaignsMapRow) => company.id == arr.id);
		const newCompany = { ...company };
		if (arriving[index]?.campaignId) {
			newCompany.campaignId = arriving[index]?.campaignId; 
		} else {
			newCompany.campaignId = undefined;
		}
		return newCompany;
	}) ?? [];

	arriving.forEach((arr: CampaignsMapRow) => {
		const newItem: MapCompany = {
			id: arr.id,
			events: [],
			nextEvent: [],
			campaignId: arr.campaignId
		};
		newList.push(newItem);
	});
	return newList;
}

function mergeFrequencies(arriving: Array<FrequencyMapRow>, eventType: number, old?: MapCompany[]): MapCompany[] {
	const newList: MapCompany[] = old?.map(company => {
		const index = arriving.findIndex((arr: FrequencyMapRow) => company.id == arr.id);
		const newCompany = { ...company };
		if (!newCompany.frequencies) {
			newCompany.frequencies = [];
			newCompany.frequencies[eventType] = arriving[index]?.frequency;
		} else {
			newCompany.frequencies[eventType] = arriving[index]?.frequency;
		}
		return newCompany;
	}) ?? [];
	arriving.forEach((arr: FrequencyMapRow) => {
		if (!newList.some(nc => nc.id == arr.id)) {
			const frequencies: (IFrequency | null | undefined)[] = [];
			frequencies[eventType] = arr.frequency;
			const newItem: MapCompany = {
				id: arr.id,
				events: [],
				nextEvent: [],
				frequencies
			};
			newList.push(newItem);
		}
	});
	return newList;
}

function initialFilters(changed: boolean) {
	return {
		cities: [],
		countries: [],
		postcodes: [],
		hideShelfAudit: false,
		hideOrders: false,
		hideFrequencies: false,
		hideCheckouts: false,
		hideOrdersRange: [false, 0],
		changed,
		clientStatusId: [],
		frequencySlider: [0, 7],
		checkoutSlider: [-7, 7],
		hideAdditionalColumns: new Set(),
		hideAdditionalFieldColumns: new Set(),
		hideCalculatedFieldColumns: new Set()
	};
}

function getParameterByName(name: string, url = window.location.href) {
	name = name.replace(/[[\]]/g, '\\$&');
	const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
	const results = regex.exec(url);
	if ((results == null) || !results[2]) return null;
	return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

// eslint-disable-next-line react/display-name
const MapView = React.forwardRef((props: {
	setToolBarState: (value: ToolbarState) => void,
	filterResult: FilterResult | undefined,
	statuses: CompanyStatus[],
	searchName: string | undefined,
	setTabType: (type: FetchKey) => void,
	tabType: FetchKey,
	tags: ITag[]
}, parentRef) => {
	const { searchName, filterResult, statuses, setTabType, tabType, tags } = props;
	// markers states
	const [companiesInLasso, setCompaniesInLasso] = React.useState<InitialMapRow[]>([]);
	const [apiData, setApiData] = React.useState<MapData>();
	const nullableGeometry = React.useCallback((points: Point[]): Point[] => points.map(p => ({ ...p, geometry: { coordinates: [p.geometry.coordinates[0] ?? 0, p.geometry.coordinates[1] ?? 0] } })), []);
	const [points, setPoints_] = React.useState<Point[]>(nullableGeometry(dataToPoints(apiData?.list)));
	const setPoints = React.useCallback((points: Point[]) => {
		setPoints_(nullableGeometry(points));
	}, []);
	// modals states
	const [modalLeftOptions, setModalLeftOptions] = React.useState<{ all_postcodes: DropdownData<string>[], all_cities: DropdownData<string>[], all_countries: DropdownData<string>[] }>({ all_postcodes: [], all_cities: [], all_countries: [] });
	const [modalOptions, setModalOptions] = React.useState({ idSelected: 0, isOpen: false });
	const eventTypes = useRecoilValue(AEventTypes);
	// other states
	const [loadingState, setLoadingState] = React.useState<LoadingStateEnum>();
	const [filters, setFilters] = React.useState(initialFilters(false));
	const [waitingList, setWaitingList] = React.useState<{key: FetchKey, force?: boolean }[]>([]);
	const users = useRecoilValue(AUsers);
	const selectedFrequencyEventType = useRecoilValue(AFrequencyEventType);
	const selectedFormId = useRecoilValue(ASelectedFormId);
	const selectedGranularity = useRecoilValue(AGranularity);
	const [clusterPopupState, setClusterPopupState] = React.useState<ModalState<ClusterProperties[]>>({ isOpen: false });
	const [isCreationOpen, setCreationOpen] = React.useState<boolean>(false);
	const [isBulkEditOpen, setIsBulkEditOpen] = React.useState<boolean>(false);
	const [isBulkDeleteOpen, setIsBulkDeleteOpen] = React.useState<boolean>(false);
	const [selectedEventType, setSelectedEventType] = React.useState<EventType>();
	const [isModalRightOpen, setIsModalRightOpen] = React.useState<boolean>(false);
	const [range, setRange] = React.useState({ firstRange: 0, lastRange: 30 });
	const _ = useRecoilValue(AOrderStatuses);
	const additionalColumns = useRecoilValue(AAdditionalColumns);
	const additionalFieldColumns = useRecoilValue(AAdditionalFieldColumns);
	const [minMaxAdditionalColumn, setMinMaxAdditionalColumn] = React.useState<MinMaxAdditionalColumn[]>([]);
	const [checkoutsMinMax, setCheckoutsMinMax] = React.useState<MinMax>();
	const calculatedFields = useRecoilValue(ACalculatedFields);
	const [minMaxCalculatedFields, setMinMaxCalculatedFields] = React.useState<MinMaxAdditionalColumn[]>([]);

	React.useImperativeHandle(parentRef, () => ({
		hasFiltersOn() {
			return checkByAllParams2(apiData?.list ?? [], filters, selectedFrequencyEventType).length !== (apiData?.list ?? []).length;
		}
	}), [filters, apiData]);

	React.useEffect(() => {
		if (!filterResult) return;
		fetchFor('status', true);
	}, [filterResult]);

	React.useEffect(() => {
		if (apiData && apiData.initial)
			fetchFor('frequencies');
	}, [selectedFrequencyEventType]);

	React.useEffect(() => {
		if (apiData && apiData.initial && apiData.checkouts)
			fetchFor('checkouts', true);
	}, [selectedGranularity]);


	React.useEffect(() => {
		if (apiData && apiData.initial && apiData.forms) {
			fetchFor('forms', true);
		}
	}, [selectedFormId]);

	React.useEffect(() => {
		props.setToolBarState({
			title: translateToString('map'),
			bottomLeftToolbarComponent: <>
				{companiesInLasso.length > 0 && <FlexDiv gap='10px'>
					<DefaultTextDiv height='30px'>
						<SelectionActionContainer>
							<Restricted to={{ objectAction: 'UpdateCompany' }}>
								<Dropdown
									dropdownStyle={{
										height: '20px',
										optionWidth: '220px',
										optionHeight: '260px',
										containerTop: '30px'
									}}
									JSXButton={() =>
										<SelectionAction>
											<SeclectionImage src={calendar_black} />
											<SelectionText>
												<Translate id='map.modal_right.create_events' />
											</SelectionText>
										</SelectionAction>
									}
									datalist={eventTypes.map(et => ({
										value: et,
										label: translateToString('map.modal_right.create') + ' ' + translateToString(`event.${et.name}`),
										image: getImageFromEventTypeName(et.name)
									}))}
									name={'event_type_dropdown'}
									onChange={(value: DropdownData<EventType>) => setSelectedEventType(value.value)}
								/>
								<SelectionAction onClick={() => setIsBulkEditOpen(true)}>
									<SeclectionImage src={PencilVendorImage} />
									<SelectionText>
										<Translate id='edit_selection' />
									</SelectionText>
								</SelectionAction>
							</Restricted>
							<Restricted to={{ objectAction: 'DeleteCompany' }}>
								<SelectionAction onClick={() => setIsBulkDeleteOpen(true)} delete>
									<SeclectionImage src={trash_red} />
									<SelectionText>
										<Translate id='delete' />
									</SelectionText>
								</SelectionAction>
							</Restricted>
						</SelectionActionContainer>
					</DefaultTextDiv>
				</FlexDiv>}
			</>,
			bottomRightToolbarComponent: <ToolbarBox>
				<Restricted to={{ objectAction: 'CreateCompany' }}>
					<Add onClick={() => setCreationOpen(true)}/>
				</Restricted>
			</ToolbarBox>
		});
	}, [companiesInLasso]);

	const atomCompanyEdition = useRecoilValue(ACompanyEdition);
	const [pieFilter, setPieFilter] = React.useState<{ [key: string]: { index: number, isActive: boolean, color: string, value: [number, number] }}>(
		{
			instore_presence: { index: 0, isActive: true, color: GreenSidely, value: [0, 100] },
			order_in_progress: { index: 1, isActive: true, color: '#C8ED34', value: [0, 100] },
			out_of_stock: { index: 2, isActive: true, color: '#FFEA2C', value: [0, 100] },
			de_listed: { index: 3, isActive: true, color: '#FF7669', value: [0, 100] },
			out_of_assortment: { index: 4, isActive: true, color: '#FFA340', value: [0, 100] },
			other: { index: 5, isActive: true, color: BlueSidely, value: [0, 100] },
			uninformed: { index: 6, isActive: true, color: '#EAEAEA', value: [0, 100] }
		}
	);

	React.useEffect(() => {
		if ((atomCompanyEdition != null) && (apiData != null)) {
			if ('deleted' in atomCompanyEdition) {
				const index: number = apiData.list.findIndex(c => c.id == atomCompanyEdition.deleted);
				apiData.list.splice(index, 1);
				setApiData({ ...apiData });
				setFilters(filters => ({ ...filters, changed: true }));
				return;
			}
			const index: number = apiData.list.findIndex(c => c.id == atomCompanyEdition.client_company_id);
			if (index >= 0) {
				for (const [key, value] of Object.entries(atomCompanyEdition)) {
					switch (key) {
						case 'client_status_id':
							apiData.list[index].clientStatusId = value;
							apiData.list[index].colorCode = statuses.find(s => s.id == value)?.color_code ?? '';
							continue;
						case 'owner_id':
							apiData.list[index].ownerId = value;
							continue;
						case 'company_name':
							apiData.list[index].name = value;
							continue;
						case 'event': {
							const event: Event = value;
							if (apiData.events) {
								const startDate = moment(event.start_date);
								if (startDate.unix() < moment().unix() && event.event_status_id == 2) {
									const dbEventDate = apiData.list[index].events?.[0]?.[1];
									if (!dbEventDate || moment(dbEventDate).unix() < startDate.unix()) {
										apiData.list[index].events = [[event.event_type_id, startDate.format('YYYY-MM-DD'), undefined]];
										apiData.list[index].lastEventType = event.event_type_id;
										//@ts-expect-error date
										apiData.list[index].lastEventDate = startDate.toDate();
										apiData.list[index].range = moment(startDate).diff(dbEventDate, 'days');
									}
								} else if (event.event_status_id !== 2) {
									const dbEventDate = apiData.list[index].nextEvent?.[0]?.[2];
									if (!dbEventDate || moment(dbEventDate).unix() > startDate.unix()) {
										apiData.list[index].nextEvent = [[event.event_type_id, undefined, startDate.format('YYYY-MM-DD')]];
										apiData.list[index].nextEventType = event.event_type_id;
										//@ts-expect-error date
										apiData.list[index].nextEventDate = startDate.toDate();
										apiData.list[index].nextRange = moment(dbEventDate).diff(startDate, 'days');
									}
								}
							}
							continue;
						}
						case 'ids_add':
							if (apiData.tags) {
								apiData.list[index].tags = apiData.list[index].tags?.concat(value as number[]);
							}
							continue;
						case 'ids_delete':
							if (apiData.tags) {
								for (const id of value as number[]) {
									const idIndex = apiData.list[index].tags?.findIndex(i => i == id);
									if ((idIndex ?? -1) >= 0) {
										apiData.list[index].tags?.splice(idIndex ?? -1, 1);
									}
								}
							}
							continue;
					}
				}
				setApiData({ ...apiData });
				setFilters(filters => ({ ...filters, changed: true }));
			}
		}
	}, [atomCompanyEdition]);

	const updatePieFilter = React.useCallback((label: string, value, all_updated = true): void => {
		const newFilter = { ...pieFilter };
		newFilter[label] = value;
		setPieFilter(newFilter);
		if (all_updated) {
			changeFilters('pieFilter', newFilter);
		}
	}, [pieFilter]);

	React.useEffect(() => {
		const companyByQuery = getParameterByName('company');
		if (companyByQuery) {
			const companyId: number = parseInt(companyByQuery);
			if (!isNaN(companyId) && !modalOptions.idSelected) {
				const finded = apiData?.list.find(e => e.id === companyId);
				if (finded !== undefined && finded.lat && finded.lon) {
					onMapMarkerCompanyClicked(finded.id!);
				}
			}
		}
	}, [apiData]);

	React.useEffect(() => {
		if (!waitingList.length) {
			setLoadingState(LoadingStateEnum.LOADED);
			return;
		}
		setLoadingState(LoadingStateEnum.LOADING);
		const elem = waitingList.pop();
		if (elem) fetchSwitch(elem.key, elem.force).then(() => setWaitingList([...waitingList]));
	}, [waitingList]);

	const fetchFor = (key: FetchKey, force?: boolean) => setWaitingList(currentList => [...currentList, { key, force }]);

	const fetchSwitch = async(key: FetchKey, force?: boolean): Promise<void> => {
		if (key !== 'status' && !apiData?.initial) {
			fetchFor('status', false);
		}

		if (typeof key !== 'string' && 'additionalColumn' in key) {
			if (!apiData?.additionalColumns || force) {
				return getMapAdditionalColumns()
					.then(res => {
						setApiData(apiData => {
							const { newList, points } = mergeAndToPoint(res, apiData?.list);
							const newMapData: MapData = { ...apiData, additionalColumns: true, list: newList };
							setPoints(points);
							setFilters(filters => ({ ...filters, changed: true }));
							initMinMaxAdditionalFields(newList);
							return newMapData;
						});
					})
					.catch(console.error);
			}
			return;
		}

		if (typeof key !== 'string' && 'calculatedFieldColumn' in key) {
			if (!apiData?.additionalColumns || force) {
				return getMapAdditionalColumns()
					.then(res => {
						setApiData(apiData => {
							const { newList, points } = mergeAndToPoint(res, apiData?.list);
							const newMapData: MapData = { ...apiData, additionalColumns: true, list: newList };
							setPoints(points);
							setFilters(filters => ({ ...filters, changed: true }));
							initMinMaxAdditionalFields(newList);
							return newMapData;
						});
					})
					.catch(console.error);
			}
			return;
		}


		if (typeof key !== 'string' && 'additionalFieldColumn' in key) {
			if (!apiData?.additionalFields || force) {
				return getMapAdditionalFieldColumns()
					.then(res => {
						setApiData(apiData => {
							const { newList, points } = mergeAndToPoint(res, apiData?.list);
							const newMapData: MapData = { ...apiData, additionalFields: true, list: newList };
							setPoints(points);
							setFilters(filters => ({ ...filters, changed: true }));
							return newMapData;
						});
					})
					.catch(console.error);
			}
			return;
		}
		// console.log('key', key);
		switch (key) {
			case 'frequencies': {
				if (!apiData?.frequencies?.[selectedFrequencyEventType] || force) {
					return getMapFrequency(selectedFrequencyEventType)
						.then(frequencies => {
							setApiData(apiData => {
								const newFrequencies = apiData?.frequencies ?? [];
								newFrequencies[selectedFrequencyEventType] = true;
								const newMapData: MapData = { ...apiData, frequencies: newFrequencies, list: mergeFrequencies(frequencies, selectedFrequencyEventType, apiData?.list) };
								setPoints(dataToPoints(newMapData?.list));
								return newMapData;
							});
							setFilters(filters => ({ ...filters, changed: true }));
						});
				} else {
					setFilters(filters => ({ ...filters, changed: true }));
				}
				return;
			}
			case 'tags':
				if (!apiData?.tags || force) {
					return getMapTags()
						.then(tags => {
							setApiData(apiData => {
								const { newList, points } = mergeAndToPoint(tags, apiData?.list);
								const newMapData: MapData = { ...apiData, tags: true, list: newList };
								setPoints(points);
								setFilters(filters => ({ ...filters, changed: true }));
								return newMapData;
							});
						})
						.catch(console.error);
				}
				return;
			case 'checkouts':
			case 'checkouts_sum':
				if (!apiData?.checkouts || force) {
					return getMapCheckouts(selectedGranularity)
						.then(res => {
							setApiData(apiData => {
								const { newList, points } = mergeAndToPoint(res, apiData?.list);
								const newMapData: MapData = { ...apiData, checkouts: true, list: newList };
								setPoints(points);
								setFilters(filters => ({ ...filters, changed: true }));
								setCheckoutsMinMax(newList.reduce((acc: MinMax | undefined, company) => {
									if (typeof company.checkoutEvolution?.currentSum === 'number') {
										if (acc === undefined) {
											return { min: company.checkoutEvolution.currentSum, max: company.checkoutEvolution.currentSum };
										} else {
											return {
												min: Math.min(acc.min, company.checkoutEvolution.currentSum),
												max: Math.max(acc.max, company.checkoutEvolution.currentSum)
											};
										}
									}
									return acc;
								}, undefined));
								return newMapData;
							});
						})
						.catch(console.error);
				}
				return;
			case 'status':
				if (!apiData?.initial || force) {
					return getMapInitial(filterResult?.formatted)
						.then(initial => {
							setApiData(apiData => {
								const { newList, points } = mergeAndToPoint(initial, apiData?.list.map(c => ({ ...c, lat: undefined, lon: undefined })));
								const newMapData: MapData = { ...apiData, initial: true, list: newList };
								setPoints(points);
								setFilters(filters => ({ ...filters, changed: true }));
								initPostcodeCities(initial);
								return newMapData;
							});
						})
						.catch(console.error);
				}
				return;
			case 'last':
			case 'next':
			case 'plan':
				if (!apiData?.events || force) {
					return getMapEvents()
						.then(res => {
							setApiData(apiData => {
								const { newList, points } = mergeAndToPoint(getDataByEventType(res), apiData?.list);
								const newMapData: MapData = { ...apiData, events: true, list: newList };
								setPoints(points);
								setFilters(filters => ({ ...filters, changed: true }));
								return newMapData;
							});
						})
						.catch(console.error);
				}
				return;
			case 'instore':
				if (!apiData?.distribution || force) {
					return getMapDistribution()
						.then(res => {
							setApiData(apiData => {
								const { newList, points } = mergeAndToPoint(res, apiData?.list);
								const newMapData: MapData = { ...apiData, distribution: true, list: newList };
								setPoints(points);
								setFilters(filters => ({ ...filters, changed: true }));
								return newMapData;
							});
						})
						.catch(console.error);
				}
				return;
			case 'forms':
				if (!apiData?.forms?.[selectedFormId] || force) {
					return getMapForms(selectedFormId)
						.then(forms => {
							setApiData(apiData => {
								const newForms = apiData?.forms ?? [];
								newForms[selectedFormId] = true;
								const newMapData: MapData = { ...apiData, forms: newForms, list: mergeForms(forms, selectedFormId, apiData?.list) };
								const newData: Point[] = newMapData.list.map(c => ({
									properties: {
										cluster: false,
										id: c.id!,
										color: c.colorCode!,
										detail: c
									},
									geometry: {
										coordinates: [c.lon!, c.lat!]

									}
								}));
								setPoints(newData);
								setFilters(filters => ({ ...filters, changed: true }));

								return newMapData;
							});
						});
				}
				return;
			case 'campaign': {
				if (!apiData?.campaigns || force) {
					return getMapCampaigns()
						.then(res => {
							setApiData(apiData => {
								res = res.map(o => ({ ...o, campaignId: o.campaignId }));
								const { newList, points } = mergeAndToPoint(res, apiData?.list);
								console.log('points before set', points.filter(p => p.properties.detail.campaignId));
								const newMapData: MapData = { ...apiData, campaigns: true, list: newList };
								setPoints(points);
								setFilters(filters => ({ ...filters, changed: true }));
								return newMapData;
							});
						})
						.catch(console.error);
				}
				break;
			}
			case 'orders':
			case 'orders_dates':
			case 'orders_sums':
				if (!apiData?.orders || force) {
					return getMapOrders()
						.then(res => {
							setApiData(apiData => {
								res = res.map(o => ({ ...o, orderRange: moment().diff(moment(o.lastOrderDate!.seconds * 1000), 'days') }));
								const { newList, points } = mergeAndToPoint(res, apiData?.list);
								const newMapData: MapData = { ...apiData, orders: true, list: newList };
								setPoints(points);
								setFilters(filters => ({ ...filters, changed: true }));
								return newMapData;
							});
						})
						.catch(console.error);
				}
		}
	};

	React.useEffect(() => {
		fetchFor(tabType, true);
	}, [tabType]);

	const filterList = () => {
		if (filters.changed) {
			setFilters({ ...filters, changed: false });
		}
		setPoints(dataToPoints(checkByAllParams2(apiData?.list ?? [], { ...filters, searchInput: searchName }, selectedFrequencyEventType)));
	};

	React.useEffect(() => {
		filterList();
	}, [searchName]);

	React.useEffect(() => {
		if (filters.changed && (apiData?.list.length ?? 0) > 0) {
			filterList();
		}
	}, [filters, selectedFrequencyEventType]);

	React.useEffect(() => {
		if (companiesInLasso.length === 0) {
			clearDrawnPoly();
		}
	}, [companiesInLasso]);

	const changeFilters = React.useCallback((key: string, value) => {
		if (key === 'reset') {
			setFilters(initialFilters(true));
			return;
		}
		const newFilters = filters;
		newFilters[key] = value;
		setFilters({ ...newFilters, changed: true });
	}, [filters]);

	function initMinMaxAdditionalFields(companies: MapCompany[]) {
		const minMaxArray: MinMaxAdditionalColumn[] = [];
		const minMaxArrayCalculatedFields: MinMaxAdditionalColumn[] = [];
		additionalColumns.forEach(ac => (ac.type === 'Integer' || ac.type === 'Number' || ac.type === 'ReportColumn') && minMaxArray.push({ id: ac.id.toString(), min: Number.MAX_VALUE, max: Number.MIN_VALUE, field: false }));
		additionalFieldColumns.forEach(afc => (afc.field_type === 'Integer' || afc.field_type === 'Number') && minMaxArray.push({ id: afc.field_id.toString(), min: Number.MAX_VALUE, max: Number.MIN_VALUE, field: true }));
		calculatedFields.forEach(cf => minMaxArrayCalculatedFields.push({ id: cf.id.toString(), min: Number.MAX_VALUE, max: Number.MIN_VALUE, field: false }));
		companies.forEach(company => {
			minMaxArray.forEach((acc) => {
				if (acc.field) {
					aggregateNumberField(company.additionalFieldColumnsValue?.[acc.id], acc);
				} else {
					aggregateNumberField(company.additionalColumnsValue?.[acc.id], acc);
				}
			});
			minMaxArrayCalculatedFields.forEach((acc) => {
				aggregateNumberField(company.calculatedFieldColumnsValue?.[acc.id], acc);
			});
		});
		setMinMaxAdditionalColumn(minMaxArray);
		setMinMaxCalculatedFields(minMaxArrayCalculatedFields);
	}

	function initPostcodeCities(allCompanies: ProtoMap.IInitialMapRow[]) {
		const postcodes =
		allCompanies
			.filter(l => l.postCode && l.postCode != '')
			.map(l => l.postCode)
			.sort();
		const uniq_postcodes = Array.from(new Set(postcodes))
			.map(p => ({ value: p!, label: p! }));
		const cities =
		allCompanies
			.filter(l => l.city && l.city != '')
			.map(l => l.city)
			.sort();
		const uniq_cities = Array.from(new Set(cities))
			.map(c => ({ value: c!, label: c! }));
		const contries =
			allCompanies
				.filter(l => l.country && l.country != '')
				.map(l => l.country)
				.sort();
		const uniq_countries = Array.from(new Set(contries))
			.map(c => ({ value: c!, label: c! }));
		setModalLeftOptions({
			...modalLeftOptions,
			all_postcodes: uniq_postcodes,
			all_cities: uniq_cities,
			all_countries: uniq_countries,
		});
	}

	const onMapMarkerCompanyClicked = React.useCallback((companyId: number, noCloseCluster?: true) => {
		const url = new URL(window.location.href);
		url.searchParams.set('company', companyId.toString());
		window.history.pushState({}, '', url);

		setModalOptions((modalOptions) => ({ ...modalOptions, idSelected: companyId, isOpen: true }));
		if (!noCloseCluster) {
			setClusterPopupState({ isOpen: false });
		}
	}, []);
	console.log('points', points.find(p => p.properties.detail.campaignId));
	return (
		<MapProvider>
			<div style={{ height: 'calc(100vh - 108px)', margin: '-10px', marginBottom: 0, width: 'calc(100% + 20px)' }}>
				<ReactMap
					points={points}
					tabType={tabType}
					companiesInLasso={companiesInLasso}
					statuses={statuses}
					setIsModalRightOpen={setIsModalRightOpen}
					setCompaniesInLasso={setCompaniesInLasso}
					rangeColor={range}
					onMapMarkerCompanyClicked={onMapMarkerCompanyClicked}
					setClusterPopupState={setClusterPopupState}
					setModalOptions={setModalOptions}
					modalOptions={modalOptions}
					apiData={apiData}
					minMaxAdditionalColumn={minMaxAdditionalColumn}
					minMaxCalculatedFields={minMaxCalculatedFields}
					checkoutsMinMax={checkoutsMinMax}
				/>
				<ComponentLoader loadingState={loadingState} allScreen zIndex='1' />
				<ModalLeftFilter
					all_postcodes={modalLeftOptions.all_postcodes}
					all_cities={modalLeftOptions.all_cities}
					all_countries={modalLeftOptions.all_countries}
					changeFilters={changeFilters}
					updatePieFilter={updatePieFilter}
					statuses={statuses}
					fetchFor={fetchFor}
					tabType={tabType}
					setTabType={setTabType}
					range={range}
					setRange={setRange}
					pieFilter={pieFilter}
					users={users}
				/>
				<ModalRight
					isOpen={isModalRightOpen}
					setIsOpen={setIsModalRightOpen}
					companies={companiesInLasso}
					setCompaniesInLasso={setCompaniesInLasso}
				/>
			</div>
			<Popup
				isOpen={clusterPopupState.isOpen && clusterPopupState.data !== undefined}
				onClickOut={() => setClusterPopupState({ isOpen: false })}
				popupMode={PopupMode.Details}
				disableOutClick
			>
				{clusterPopupState.data !== undefined && <ClusterPopupContainer
					rangeColor={range}
					minMaxAdditionalColumn={minMaxAdditionalColumn}
					state={{ tabType, rangeColor: range }}
					properties={clusterPopupState.data}
					points={points}
					statuses={statuses}
					onPointClicked={id => onMapMarkerCompanyClicked(id, true)}
					close={() => setClusterPopupState({ isOpen: false })}
					users={users}
				/>}
			</Popup>
			<ClientCompany
				disableOnClickOut
				isOpen={modalOptions.isOpen}
				setOpen={(b: boolean) => {
					if (!b) {
						const url = new URL(window.location.href);
						url.searchParams.delete('company');
						window.history.pushState({}, '', url);
					}
					setModalOptions({ ...modalOptions, isOpen: b, idSelected: 0 });
				}}
				clientCompanyId={modalOptions.idSelected}
				fullOpenMode={false}
			/>
			<ClientCompanyCreation
				isOpen={isCreationOpen}
				setOpen={setCreationOpen}
				onCreate={(value) => {
					if (value.latitude !== undefined && value.longitude !== undefined && apiData) {
						apiData.list.push({
							id: value.company_id,
							city: value.company_city,
							clientStatusId: value.status_id,
							colorCode: value.company_status_color,
							lat: value.latitude,
							lon: value.longitude,
							name: value.name,
							ownerId: value.owner_id,
							events: [],
							nextEvent: []
						});
						setApiData({ ...apiData });
						setPoints(dataToPoints(apiData.list));
					}
				}}
			/>
			<Popup
				isOpen={isBulkEditOpen || selectedEventType !== undefined}
				popupMode={PopupMode.Details}
				onClickOut={() => {
					setIsBulkEditOpen(false);
					setSelectedEventType(undefined);
				}}
				disableOutClick={true}
				popupStyle={{ animate: true, height: '100%', top: '0%' }}
			>
				<PopupCreation
					title={isBulkEditOpen ? <Translate id='mass_modification' /> : <><Translate id='map.modal_right.plan_your' /><Translate id={`event.${selectedEventType?.name}_s`}/></>}
					hideValidation
					onClose={() => {
						setIsBulkEditOpen(false);
						setSelectedEventType(undefined);
					}}
				>
					{(selectedEventType != null)
						? <CompanyBulkEventCreation
							type={selectedEventType}
							onClose={() => setSelectedEventType(undefined)}
							ids={companiesInLasso.map(c => c.id)}
							onCreate={async() => {
								setCompaniesInLasso([]);
								if (apiData?.events) {
									fetchFor('last', true);
								}
							}}
						/>
						: <CompanyBulkEdition
							setLoadingState={setLoadingState}
							ids={companiesInLasso.map(c => c.id)}
							users={users?.map(u => ({ label: u.name, value: u, image: u.photoUrl }))}
							statuses={statuses?.map((s, i) => ({ label: s.name, value: { id: s.id }, color: s.color_code, selected: i === 0 }))}
							tags={tags}
							refresh={(str) => {
								switch (str) {
									case 'additional-columns':
										fetchFor({ additionalColumn: 0 }, true);
										break;
									case 'frequency':
										fetchFor('frequencies', true);
										break;
									case 'campaign':
										fetchFor('campaign', true);
										break;
									case 'tags':
									case 'forms':
									case 'status':
										fetchFor(str, true);
										break;
								}
								setCompaniesInLasso([]);
							}}
							onClose={() => setIsBulkEditOpen(false)}
						/>
					}
				</PopupCreation>
			</Popup>
			<PopupDeletion
				isOpen={isBulkDeleteOpen}
				onClickOut={() => setIsBulkDeleteOpen(false)}
				records={companiesInLasso.length}
				onValidation={() => {
					deleteCompanies(companiesInLasso.map(c => c.id), false, undefined, '')
						.then(response => {
							if (response) fetchFor('status', true);
						});
					setCompaniesInLasso([]);
				}}
				translationKey='companies'
			/>
		</MapProvider>
	);
});

export default MapView;