import { ContactDetail } from 'bindings/contacts/ContactDetail';
import * as React from 'react';
import { Body, ListContainer, ListText } from '../../../client-companies/style/LinkedElementStyle';
import { Open } from '../../../../styles/global/css/Open';
import Restricted from '../../../permissions/Restricted';
import { DEFAULT_LINKED_ELEMENT_OPEN_SIZE } from '../../../client-companies/popup/LinkedElements/LinkedElements';
import { translateToString } from '../../../../styles/global/translate';
import Add from '../../../../components_v2/add/Add';
import EventCreation from '../../../../components_v2/creation/EventCreation';
import OpportunityCreation from '../../../../components_v2/creation/OpportunityCreation';
import DocumentCreation from '../../../../components_v2/creation/DocumentCreation';
import NoteCreation from '../../../../components_v2/creation/NoteCreation';
import { EmailV2 } from '../../../../containers/companies/components/linkedElements/Emails';
import Event from '../../../../containers_v2/client-companies/popup/LinkedElements/sections/Event';
import Opportunity from '../../../client-companies/popup/LinkedElements/sections/Opportunity';
import Document from '../../../client-companies/popup/LinkedElements/sections/Document';
import Note from '../../../client-companies/popup/LinkedElements/sections/Note';
import { ContactLinkedElementAmount } from '../../data/Data';
import { useFunctionState } from '../../../../utils/customHooks';

export const STATE_KEY_LIST = [
	['event', true],
	['opportunity', true],
	['document', true],
	// ['free_forms', true],
	['note', true],
	// ['emails', false],
] as const;
export type StateKey = typeof STATE_KEY_LIST[number][0];
type State<T> = { [key in StateKey]: T };

function DefaultCreation() {
	return <div style={{ width: '25px', height: '25px' }} />;
}

function LinkedElementTitle(props: {
	amount: number | undefined,
	switchOpen: (value: StateKey) => void,
	switchCreate: (value: StateKey) => void,
	openList: State<boolean>,
	stateKey: StateKey,
	first?: boolean,
	creatable: boolean
}) {
	const { switchOpen, switchCreate, openList, stateKey, creatable } = props;

	return <ListContainer onClick={() => switchOpen(stateKey)} borderTop={props.first ? 'none' : undefined}>
		<Open isOpen={openList[stateKey]} size={DEFAULT_LINKED_ELEMENT_OPEN_SIZE} />
		<ListText>{`${translateToString(`company.detail.linked_elements.${stateKey}`)} ${props.amount !== undefined ? `(${props.amount})` : ''}`}</ListText>
		{creatable
			? <Restricted to={{ objectAction: 'CreateEvent' }} fallback={<DefaultCreation />}>
				<Add onClick={(e) => {
					e.stopPropagation();
					switchCreate(stateKey);
				}}/>
			</Restricted>
			: <DefaultCreation />}
		
	</ListContainer>;
}

function genDefaultState<T>(): State<T> {
	//@ts-expect-error initialization
	return {};
}

function LinkedElement(props: {
	amount: number | undefined,
	stateKey: StateKey,
	switchOpen: (value: StateKey) => void,
	switchCreate: (value: StateKey) => void,
	openList: State<boolean>,
	contact: ContactDetail,
	creatable: boolean,
	first: boolean,
	isExtend: boolean,
	setDisableEsc: React.Dispatch<React.SetStateAction<boolean>>,
	newCreations: State<any>,
	updateLinkedElementAmounts: (id: number) => void
}) {
	const { stateKey, switchOpen, switchCreate, openList, contact, creatable, first, newCreations } = props;
	const childProps = {
		isExtend: props.isExtend,
		id: { Contact: contact.id },
		onDelete: () => props.updateLinkedElementAmounts(contact.id) ,
		newCreation: newCreations[stateKey],
	};
	return <>
		<LinkedElementTitle
			switchOpen={switchOpen}
			switchCreate={switchCreate}
			openList={openList}
			stateKey={stateKey}
			first={first}
			creatable={creatable}
			amount={props.amount}
		/>
		{stateKey === 'event' && openList[stateKey] && <Event
			onDisableClickOut={props.setDisableEsc}
			{...childProps}
		/>}
		{stateKey === 'emails' && openList[stateKey] && <EmailV2
			isExtended={props.isExtend}
			id={{ Contact: contact.id }}
			onDisableClickOut={props.setDisableEsc}
			companyId={contact.client_company_id ?? undefined}
			contactId={contact.id}

		/>}
		{stateKey === 'opportunity' && openList[stateKey] && <Opportunity {...childProps} />}
		{stateKey === 'document' && openList[stateKey] && <Document {...childProps} />}
		{stateKey === 'note' && openList[stateKey] && <Note {...childProps} />}
	</>;
}

export default function ContactPopupLinkedElements(props: {
	contact: ContactDetail,
	isExtend: boolean
	setDisableEsc: React.Dispatch<React.SetStateAction<boolean>>,
	linkedAmount: ContactLinkedElementAmount | undefined,
	updateLinkedElementAmounts: (id: number) => void
}) {
	const { contact } = props;
	const [openList, setOpenList] = React.useState<State<boolean>>(genDefaultState());
	const [creationList, setCreationList] = React.useState<State<boolean>>(genDefaultState());
	const [newCreations, setNewCreation] = useFunctionState<State<any>>(genDefaultState(), ({ newValue }) => {
		props.updateLinkedElementAmounts(props.contact.id);
		return newValue;
	});

	const switchState = React.useCallback((fn: React.Dispatch<React.SetStateAction<State<boolean>>>) => (value: StateKey) => fn(state => {
		state[value] = !state[value];
		return { ...state };
	}), []);

	function getAmount(key: StateKey, linkedAmount: ContactLinkedElementAmount | undefined) {
		switch (key) {
			case 'document': return linkedAmount?.amount_documents;
			case 'emails': return undefined;
			case 'event': return linkedAmount?.amount_events;
			case 'note': return linkedAmount?.amount_notes;
			case 'opportunity': return linkedAmount?.amount_opportunities;
		}
	}

	return <Body>
		{STATE_KEY_LIST.map(([key, creatable], i) => <LinkedElement
			updateLinkedElementAmounts={props.updateLinkedElementAmounts}
			amount={getAmount(key, props.linkedAmount)}
			key={`ContactLinkedElement[${key}]`}
			switchOpen={switchState(setOpenList)}
			switchCreate={switchState(setCreationList)}
			openList={openList}
			stateKey={key}
			first={i === 0}
			creatable={creatable}
			contact={contact} isExtend={props.isExtend} setDisableEsc={props.setDisableEsc}
			newCreations={newCreations}
		/>)}
		<EventCreation
			isOpen={creationList.event}
			setOpen={() => switchState(setCreationList)('event')}
			defaultValues={{ clientCompanyId: contact.client_company_id ?? undefined, contactId: contact.id }}
			onCreate={values => setNewCreation(state => ({ ...state, event: values }))}
		/>
		<OpportunityCreation
			isOpen={creationList.opportunity}
			setOpen={() => switchState(setCreationList)('opportunity')}
			defaultValues={{ clientCompanyId: contact.client_company_id ?? undefined, contactId: contact.id }}
			onCreate={values => setNewCreation(state => ({ ...state, opportunity: values }))}
		/>
		<DocumentCreation
			isOpen={creationList.document}
			setOpen={() => switchState(setCreationList)('document')}
			id={{ Contact: contact.id }}
			onCreate={values => setNewCreation(state => ({ ...state, document: values }))}
		/>
		<NoteCreation
			isOpen={creationList.note}
			setOpen={() => switchState(setCreationList)('note')}
			onCreate={values => setNewCreation(state => ({ ...state, note: values }))}
			id={{ Contact: contact.id }}
		/>
		{/* <FreeFormCreation
			isOpen={creationList.free_forms}
			setOpen={() => switchState(setCreationList)('free_forms')}
		/> */}
	</Body>;
}