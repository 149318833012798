import edit from 'images/icon/edit.png';
import trash_black from 'images/ui_icon/trash_black.svg';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { AProfile } from '../../atoms/global/users';
import { DbView, ViewKey } from '../../atoms/global/views';
import { FlexDiv, HideTrash } from '../../containers_v2/products/style';
import { Open } from '../../styles/global/css/Open';
import { BlueSidely, BorderColor, FilterBlue, FilterRed, GreySidely2, LightGreySidely2, SidelyBlack } from '../../styles/global/css/Utils';
import { Translate, translateToString } from '../../styles/global/translate';
import { checkClickOut } from '../dropdown/Dropdown';
import { OptionText } from '../dropdown/style/Style';
import InputSearch from '../input/InputSearch';
import Popup from '../popup/Popup';
import { isOwner, useMe } from '../../atoms/global/users';
import SidelyViews from './SidelyViews.json';

type PropsRestriction = {
	name: string
}

const GridContainer = styled.div`
	display: grid;
	grid-template-rows: 40px 1fr 20px;
	grid-row-gap: 10px;
	height: 100%;
`;

const ButtonContainer = styled(FlexDiv)<{ noBorder?: boolean }>`
	height: 100%;
	${p => p.noBorder ? '' : `border-right: 1px solid ${BorderColor};`}
	gap: 10px;
	padding: 0 10px;
	max-width: 200px;
	color: ${SidelyBlack};
	font-weight: 600;
	font-size: 14px;
`;

const OptionViewBlock = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 10px;
	padding: 5px 10px;
	border-radius: 5px;
	cursor: pointer;
    ${HideTrash}
	&:hover {
		background-color: ${LightGreySidely2};
	}
`;

const SelectedViewSpan = styled.span`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const VuesContainter = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
	height: 100%;
	overflow-y: auto;
`;

const VueContainer = styled.div<{ isLast?: boolean }>`
	height: 100%;
	padding: 5px 10px;
	border-right: ${p => !p.isLast ? '2px solid ' + BorderColor : 'none'};
	border-bottom: 2px solid ${BorderColor};
	overflow-y: auto;
	@media (max-width: 1015px) {
		border-right: none;
	}
	@media (max-width: 685px) {
		border-left: none;
		height: auto;
		overflow-y: visible;
	}
`;

const VueTitle = styled.div`
	font-size: 14px;
	font-weight: 600;
	padding: 10px;
`;

const NewViewButton = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
	cursor: pointer;
	padding: 5px 10px;
	color: ${BlueSidely};
	font-size: 13px;
	&::before {
		content: '+';
		font-size: 15px;
	}
`;

const IconImage = styled.img<{ hoverRed?: boolean }>`
	height: 15px;
	cursor: pointer;
	&:hover {
		filter: ${p => p.hoverRed ? FilterRed : FilterBlue};
	}
`;

export default function ViewPicker<T extends PropsRestriction>(props: {
	views: DbView<T>[],
	selectedView?: DbView<T>
	onViewChange?: (view: DbView<T> | undefined) => void
	onDelete?: (option: DbView<T>) => void,
	onCreate?: () => void,
	onEdit?: () => void,
	setEditedView?: (view: DbView<T>) => void,
	noBorder?: boolean,
	type?: ViewKey
}): JSX.Element {
	const { views, selectedView, onViewChange, onDelete, onCreate, onEdit } = props;
	const _aProfile = useRecoilValue(AProfile);
	const alphabetViews = React.useMemo(() => {
		const parsedViews = JSON.parse(JSON.stringify(SidelyViews).replaceAll('"$current_user_id"', _aProfile.id.toString()));
		const sidelyViews = parsedViews.views.filter(v => v.type === props.type);
		sidelyViews.forEach(v => v.data.name = translateToString(v.data.name));
		const alphabetViews = [...views].concat(sidelyViews).sort((a, b) => a.data.name.toLowerCase().localeCompare(b.data.name.toLowerCase()));
		return alphabetViews;
	}, [views]);
	const defaultViewName = props.type === 'companies' ? 'views.view_names.all_companies' : props.type === 'contacts' ? 'views.view_names.all_contacts' : 'views.view_names.all_data';
	const isReports = props.type === 'reports';
	const me = useMe();

	const categories = React.useMemo(() => {
		const categories = [
			{
				title: <Translate id='views.my_views'/>,
				views: alphabetViews.filter(v => v.id > 0 && !v.public).map(v => ({ ...v, name: v.data.name, deletable: true })),
				editable: true,
				deletable: true
			},
			{
				title: <Translate id='views.admin_views'/>,
				views: alphabetViews.filter(v => v.id > 0 && v.public).map(v => ({ ...v, name: v.data.name, deletable: (isOwner(me) || v.owner_id === me.id) })),
				editable: false,
				deletable: onDelete != undefined
			}
		];

		if (!isReports) {
			categories.unshift({
				title: <Translate id='views.sidely_views'/>,
				views: [{
					id: -1,
					name: translateToString(defaultViewName),
					deletable: false
				} as DbView<T> & { name: string, deletable: boolean} , ...alphabetViews.filter(v => v.id < 0).map(v => ({ ...v, name: v.data.name, deletable: false }))],
				editable: false,
				deletable: false
			});
		} else {
			categories[0].views.unshift({
				id: -1,
				name: translateToString(defaultViewName),
				deletable: false
			} as DbView<T> & { name: string, deletable: boolean});
		}
		return categories;

	}, [alphabetViews, props.type]);

	React.useEffect(() => {
		onViewChange?.(selectedView);
	}, [selectedView]);


	return <RawViewPicker<DbView<T> & { name: string, deletable?: boolean }>
		buttonTitle={selectedView ? selectedView.data.name : translateToString(defaultViewName)}
		categories={categories}
		onCreate={onCreate}
		onEdit={onEdit}
		noBorder={props.noBorder}
		setEditedView={props.setEditedView}
		onDelete={onDelete}
		onViewChange={(v) => v.id == -1 ? onViewChange?.(undefined) : onViewChange?.(v)}
	/>;
}

export type ViewsCategory<T> = {
	title: React.ReactNode,
	views: T[],
	editable: boolean,
	deletable: boolean
}


export function RawViewPicker<T extends { name: string, id: number | string, deletable?: boolean }>(props: {
	buttonTitle: React.ReactNode,
	onCreate: (() => void) | undefined,
	onEdit: (() => void) | undefined,
	noBorder: boolean | undefined,
	setEditedView: ((view: T) => void) | undefined,
	onDelete: ((option: T) => void) | undefined,
	categories: ViewsCategory<T>[],
	onViewChange: ((view: T) => void) | undefined
}) {
	const { onCreate, onEdit, onDelete } = props;
	const wrapperRef = React.useRef<HTMLDivElement>(null);
	const [offset, setOffset] = React.useState<DOMRect | null>(null);
	const [offsetIsSet, setOffsetIsSet] = React.useState<boolean>(false);
	const [debouncedOffset, setDebouncedOffset] = React.useState<boolean>(false);
	const [isOpen, setIsOpen] = React.useState<boolean>(false);
	const [inputValue, setInputValue] = React.useState<string>('');
	const [debouncedInputValue, setDebouncedInputValue] = React.useState<string>('');

	checkClickOut(wrapperRef, setIsOpen);
	React.useEffect(() => {
		const timeout = setTimeout(() => setDebouncedOffset(offsetIsSet), 500);
		return () => clearTimeout(timeout);
	}, [offsetIsSet]);

	React.useEffect(() => {
		if (!wrapperRef.current) return;
		const bounding = wrapperRef.current.getBoundingClientRect();
		if (bounding.left != 0) {
			setOffset(bounding);
			setOffsetIsSet(true);
		}
		else
			setOffsetIsSet(!offsetIsSet);
	}, [debouncedOffset]);

	React.useEffect(() => {
		const timeout = setTimeout(() => setDebouncedInputValue(inputValue), 300);
		return () => clearTimeout(timeout);
	}, [inputValue]);

	const offsetLeft = offset ? offset.left + 5 : undefined;

	return <div ref={wrapperRef}>
		<ButtonContainer noBorder={props.noBorder} cursor='pointer' onClick={() => setIsOpen(!isOpen)}><SelectedViewSpan>{props.buttonTitle}</SelectedViewSpan><Open isOpen={isOpen} height={7} width={12}/></ButtonContainer>
		<Popup isOpen={isOpen && offset?.left != 0} disableOutClick onClickOut={() => setIsOpen(false)}
			popupStyle={{
				zIndex: 10,
				width: `min(${props.categories.length * 20 + 10}vw, calc(100vw - ${(offsetLeft ?? 0) + 50}px))`,
				height: 'auto',
				minWidth: '200px',
				minHeight: '450px',
				overflow: 'auto',
				left: offsetLeft ? offsetLeft + 'px' : undefined,
				top: offset ? (offset.top + 30).toString() + 'px' : undefined,
				borderRadius: '5px',
				noTransition: true,
				boxShadow: '0px 0px 8px 0px rgba(0,0,0,0.09)'
			}}
			content={
				<>
					<GridContainer>
						<FlexDiv padding='10px 10px 0px 10px'>
							<InputSearch name='search-views' type='text' value={inputValue} onChange={(value) => setInputValue(value)} placeholder={translateToString('views.search_views')} inputStyle={
								{
									backgroundColor: LightGreySidely2,
									border: 'none',
									borderRadius: '7px',
									width: '240px',
									padding: '5px 10px',
									placeholderColor: GreySidely2,
								}
							}/>
						</FlexDiv>
						<VuesContainter>
							{props.categories.map((category, index) => {
								const filteredViews = category.views.filter(v => v.name.toLowerCase().includes(debouncedInputValue.toLowerCase()));
								return <VueContainer key={index} isLast={props.categories.length == index + 1}>
									<VueTitle>{category.title} ({filteredViews.length})</VueTitle>
									{filteredViews.map(v => <OptionViewBlock key={v.id} onClick={() => { props.onViewChange?.(v); setIsOpen(false); } }>
										<OptionText ellipsis>{v.name}</OptionText>
										{((category.deletable && onDelete) || (category.editable && onEdit)) && <FlexDiv gap='2px'>
											{category.editable && onEdit && <IconImage src={edit} className='trash' onClick={e => {
												e.stopPropagation();
												props.setEditedView?.(v);
												onEdit();
												setIsOpen(false);
											} } />}
											{category.deletable && (v.deletable ?? true) && onDelete  && <IconImage src={trash_black} hoverRed className='trash' onClick={e => {
												e.stopPropagation();
												onDelete(v);
											} } />}</FlexDiv>}
									</OptionViewBlock>)}
								</VueContainer>;
							})}
						</VuesContainter>
						{ onCreate && <NewViewButton onClick={() => {onCreate(); setIsOpen(false);}}><Translate id='views.create_view'/></NewViewButton>}
					</GridContainer>
				</>
			}></Popup>
	</div>;
}