import styled from 'styled-components';
import { DropdownStyle } from '../../../components_v2/dropdown/model/Model';
import { InputStyle } from '../../../components_v2/input/model/Model';
import { BodyCell } from '../../../components_v2/table/style/Style';
import { DefaultButton } from '../../../styles/global/css/GlobalButton';
import { DefaultImage } from '../../../styles/global/css/GlobalImage';
import { DefaultText } from '../../../styles/global/css/GlobalText';
import { BorderColor, RedSidely, SidelyBlack } from '../../../styles/global/css/Utils';

const Container = styled.div``;

const PartialOpen = styled(DefaultImage)<{ parent? }>`
    transition: opacity 0.4s ease;
    width: 20px;
    height: 20px;
    opacity: 0;
    cursor: pointer;

    ${p => p.parent ?? BodyCell}:hover & {
        opacity: 0.8;
    }

    &:hover {
        opacity: 0.8;
    }
`;

const TagHorizontalContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    max-width: 200px;
    min-width: 150px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
`;

interface TagProps {
  backgroundColor: string
}

const Tag = styled.p<TagProps>`
    ${DefaultText}
    background-color: white;
    border-radius: 5px;
    line-height: 18px;
    color: ${p => p.backgroundColor};
    font-size: 12px;
    padding: 3px 12px;
    display: flex;
    align-items: center;
    gap: 5px;
    white-space: nowrap;
`;

interface TagContainerProps {
  backgroundColor?: string | null
}

const TagContainer = styled.div<TagContainerProps>`
    border: 1px solid ${p => p.backgroundColor ?? SidelyBlack};
    display: flex;
    margin: 5px 2px;
    border-radius: 5px;
`;

const TagDelete = styled.div<TagContainerProps>`
    ${DefaultText}
    background-color: white;
    transition: all 0.4s ease;
    padding: 2px 5px;
    color: ${p => p.backgroundColor ?? SidelyBlack};
    border-left: 1px solid ${p => p.backgroundColor ?? SidelyBlack};
    font-size: 15px;
    font-weight: 300;
    line-height: 18px;
    border-radius: 0 4px 4px 0;

    &:before {
        content: 'x';
    }

    &:hover {
        color: white;
        background-color: ${p => p.backgroundColor ?? SidelyBlack};
    }
`;

interface SettingBoxProps {
  isOpen: boolean
}

const SettingBox = styled.div<SettingBoxProps>`
    width: 40px;
    height: 10px;
    position: fixed;
    right: 0;
    display: ${p => p.isOpen ? 'initial' : 'none'};
    z-index: 100;
`;

export const SelectAllDiv = styled.div`
    ${DefaultText}
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 12px;
    color: ${SidelyBlack};
    font-weight: 600;
    gap: 24px;
    margin-left: 8px;
`;

export const SelectionActionContainer = styled.div`
    height: 100%;
    border-left: solid 1px ${BorderColor};
    display: flex;
    align-items: center;
    padding: 0 10px;
    gap: 10px;
`;

export const SelectionAction = styled.div<{ delete?: boolean, calendar?: boolean, titleHover?: string }>`
    display: flex;
    align-items: center;
    cursor: pointer;
    color: ${p => p.delete ? RedSidely : SidelyBlack};
    font-weight: 500;
    font-size: 12px;
    white-space:nowrap;
    gap: 5px;
    position: relative;
${
	(p) => {
		if (!p.calendar && p.titleHover) {
			return `
            @media (max-width: 1400px) {
            width:100px;
            }
        @media (max-width: 1250px) {
            width:100%;
            &:hover {
                ::before {
                    content: '${p.titleHover}';
                    position: absolute;
                    transform: translate(-45%, 107%);
                    background-color: white;
                    color: ${p.delete ? RedSidely : SidelyBlack};
                    font-weight: 400;
                    font-size: 12px;
                    padding: 5px;
                    border: 1px solid ${BorderColor};
                    border-radius: 5px;
                    z-index: 100;
                }
            }
        }`;}
		return '';
	}
}
`;
export const SelectionText = styled.div`
    white-space:nowrap;
    text-overflow:ellipsis;
    overflow-x:hidden;
    @media (max-width: 1250px) {
        display:none;
    }
`;

export const SeclectionImage = styled.img`
    width: 20px;
    height: 20px;
`;

export const NoDataText = styled.p`
    ${DefaultText}
    padding-top: 15px;
    padding-left: 35px;
`;

export const creationPopupDropdownStyle: DropdownStyle = {
	width: '100%',
	containerWidth: '100%',
	height: '50px',
	labelUp: true,
	optionHeight: '200px',
	optionWidth: '100%'
};

export const creationPopupInputStyle: InputStyle = {
	width: '100%',
	containerWidth: '100%',
	height: '50px',
	labelUp: true,
	padding: '.56rem 0.75rem',
	borderRadius: '5px'
};

export const CreationPopupContainer = styled.div<{ gap?: string }>`
    display: flex;
    padding: 15px 7.5%;
    flex-flow: column;
    height: 100%;
    ${p => p.gap ? `gap: ${p.gap};` : ''}
`;

export const SidebarButton = styled(DefaultButton)`
    margin: 0;
    white-space:nowrap;
`;

export {
	Container,
	PartialOpen,
	TagHorizontalContainer,
	Tag,
	SettingBox,
	TagContainer,
	TagDelete
};
