/* eslint-disable @typescript-eslint/no-non-null-assertion */
import eye from 'images/icons/company/eye.svg';
import modal_open from 'images/icons/company/modal_open.svg';
import close from 'images/icons/orders/close.svg';
import * as moment from 'moment';
import * as React from 'react';
import { ProgressBar } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { AAssortments } from '../../atoms/assortment';
import { AFormFields, Field } from '../../atoms/forms';
import { AProducts } from '../../atoms/product';
import { PanelSelector } from '../../components_v2/Selector/PanelSelector';
import { UserBlock } from '../../components_v2/dropdown/DropdownOwners';
import Input from '../../components_v2/input/Input';
import Popup from '../../components_v2/popup/Popup';
import { PopupMode, PopupProps } from '../../components_v2/popup/model/Model';
import { HeaderTitle } from '../../components_v2/popup/style/PopupCreationStyle';
import { DefaultImage } from '../../styles/global/css/GlobalImage';
import { DefaultText, DefaultTextDiv } from '../../styles/global/css/GlobalText';
import { BlueSidely, BorderColor, SidelyBlack } from '../../styles/global/css/Utils';
import { Translate, translateToString } from '../../styles/global/translate';
import { TitleAndChild } from '../client-companies/popup/Detail';
import { ButtonAction, FullOpen, HeaderLeft } from '../client-companies/style/PopupStyle';
import { creationPopupInputStyle } from '../client-companies/style/Style';
import { ModalState } from '../products/model';
import ProductPopup from '../products/product';
import { FlexDiv } from '../products/style';
import { UserBlockWrapper } from '../shelfAudit/popup/ShelfAuditPopup';
import { InputStyleLabel, inputStyle } from '../shelfAudit/style/styles';
import { FieldDisplayer } from './FieldDisplayer';
import { Instance, InstanceField, View, getFormViews, getInstanceByUuid, getInstanceFields } from './actions';
import { ComponentType, Screen } from './jsonValidator';
import { TemplateRenderer } from './templateRenderer';
import RestrictedSuperAdmin from '../permissions/RestrictedSuperAdmin';
import { DefaultButton } from '../../styles/global/css/GlobalButton';

const Td = styled.td``;

const ThreadContainer = styled.div<{ hovered: boolean }>`
	width: 100%;
	margin-left: 7px;
	padding: 0 0 10px 15px;
	border-left: 1px solid  ${p => p.hovered ? BlueSidely : BorderColor};
`;

const StyledDiv = styled.div`
${DefaultText}
font-size: 12px;
	height: 100%;
	width: 100%;
	h4 {
		margin: 0;
	}
	table {
		border-collapse: collapse;
		font-size: 0.8rem;
		letter-spacing: 1px;
	  }

	  th, td:first-of-type {
		font-size: 12px;
		color: ${SidelyBlack};
	  }

	  th,
	  td {
		border: 1px solid  ${BorderColor};
		padding: 8px 10px;
	  }

	  td:last-of-type {
		text-align: center;
	  }

	  tfoot th {
		text-align: right;
	  }

	  tfoot td {
		font-weight: bold;
	  }
`;

const ProgressBarStyle = styled.div`
	width: 100%;
	height: 11px;
	.progress .progress-bar {
		width: 100%;
	}
	.progress {
		width: 100%;
	}
`;

type LocalTemplate = {
	name: string, screens: Screen[]
}

interface FormInstanceContextType {
	hoverHistory: string[],
	setHoverHistory: React.Dispatch<React.SetStateAction<string[]>>
	productPopupState: ModalState<number>
	setProductPopupState: React.Dispatch<React.SetStateAction<ModalState<number>>>
	template: LocalTemplate | undefined
	setTemplate: React.Dispatch<React.SetStateAction<LocalTemplate | undefined>>
}

const defaultBehaviour: FormInstanceContextType = {
	hoverHistory: [],
	setHoverHistory: () => undefined,
	productPopupState: { isOpen: false },
	setProductPopupState: () => undefined,
	template: undefined,
	setTemplate: () => undefined
};

const FormInstanceContext = React.createContext<FormInstanceContextType>(defaultBehaviour);

function FormInstanceProvider(props: {
	children: React.ReactNode
}) {
	const [hoverHistory, setHoverHistory] = React.useState<string[]>([]);
	const [productPopupState, setProductPopupState] = React.useState<ModalState<number>>({ isOpen: false });
	const [template, setTemplate] = React.useState<LocalTemplate>();

	return <FormInstanceContext.Provider value={{ hoverHistory, setHoverHistory, productPopupState, setProductPopupState, template, setTemplate }}>
		{props.children}
	</FormInstanceContext.Provider>;
}

type FormInstanceContentProps = { instanceUuid: string, onClickOut?: () => void, isFullOpen: boolean, handleResizePopup: () => void }

interface InstancePopupProps extends PopupProps {
	modalState: ModalState<string>;
}

export function InstancePopup(props: InstancePopupProps) {
	const [isFullOpen, setIsFullOpen] = React.useState(props.modalState.fullOpenMode ?? true);
	React.useEffect(() => setIsFullOpen(props.modalState?.fullOpenMode ?? isFullOpen), [props.modalState]);
	return <Popup
		{...props}
		isOpen={props.isOpen}
		popupMode={isFullOpen ? PopupMode.Default : PopupMode.Details}
		content={props.modalState.data ? <FormInstanceContent
			instanceUuid={props.modalState.data}
			isFullOpen={isFullOpen}
			onClickOut={props.onClickOut}
			handleResizePopup={() => setIsFullOpen(b => !b)}
		/> : <></>}
		onClickOut={props.onClickOut}
	/>;
}

export function FormInstanceContent(props: FormInstanceContentProps) {
	return <FormInstanceProvider>
		<_FormInstanceContent {...props} />
	</FormInstanceProvider>;
}

const DatesContainer = styled.div<{ fullOpen: boolean }>`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(${p => p.fullOpen ? 150 : 200 }px, 1fr));
	overflow-y: auto;
	gap: ${p => p.fullOpen ? 10 : 0}px;
`;

function _FormInstanceContent(props: FormInstanceContentProps) {
	const [fieldValues, setFieldValues] = React.useState<InstanceField[]>([]);
	const [instance, setInstance] = React.useState<Instance>();
	const { productPopupState, setProductPopupState, setTemplate } = React.useContext(FormInstanceContext);
	const [views, setViews] = React.useState<View[]>([]);
	const [panelIndex, setPanelIndex] = React.useState(0);

	React.useEffect(() => {
		getInstanceFields(props.instanceUuid).then(setFieldValues);
		getInstanceByUuid(props.instanceUuid).then(res => {
			setInstance(res);
			setTemplate({ name: res.name, screens: res.screens });
		});
	}, []);
	React.useEffect(() => {
		if (!instance) return;
		getFormViews(instance.form_id).then(setViews);
	}, [instance]);
	if (!instance) return <></>;
	const dateFormatted = moment.utc(instance.made_at).local().format('L LTS');

	return <>
		<FlexDiv flow='column' align='stretch' gap='15px' margin='0 0 0 15px' height='100%' overflow='auto'>
			<StyledDiv>
				<HeaderLeft style={{ position: 'sticky', top: 0, zIndex: 1, background: 'white', marginLeft: '-15px', marginBottom: '10px' }}>
					<FlexDiv
						width="100%" justify="space-evenly" gap="20px" padding="5px 10px 10px 10px"
						borderBottom={!(views.length != 0 && props.isFullOpen) ? `1px solid ${BorderColor}` : undefined}>
						<DefaultImage cursor="pointer" src={close} onClick={props.onClickOut} />
						<HeaderTitle>{`${instance.name} -- ${dateFormatted}`}</HeaderTitle>
						{panelIndex < views.length && props.isFullOpen && <DefaultButton
							buttonStyle={2}
							onClick={() => 
							{
								window.frames['printf'].focus();
								window.frames['printf'].print();
							}}><Translate id='global.download'/></DefaultButton>}
						{<FullOpen src={modal_open} isActive={props.isFullOpen} onClick={props.handleResizePopup} />}
					</FlexDiv>
				</HeaderLeft>
				{views.length != 0 && props.isFullOpen && <PanelSelector
					borderBottom
					panels={[...views.map(v => ({ label: v.name })), { label: translateToString('views.default_view') }]}
					activeIndex={panelIndex}
					onClick={(_, index) => {
						setPanelIndex(index);
					}}
				/>}
				{panelIndex < views.length && props.isFullOpen ?
					<TemplateRenderer form_instance_uuid={props.instanceUuid} instance={instance} fields={fieldValues} template={views[panelIndex].template} />
					:
					<>
						<UserBlockWrapper label={translateToString('created_by')}>
							<UserBlock userId={instance.created_by} />
						</UserBlockWrapper>
						<Input
							inputStyle={{ ...inputStyle, ...creationPopupInputStyle }}
							name="company_name"
							type="text"
							label={translateToString('shelf_audit.popup.company_name')}
							value={instance.linked_company_name}
							disableChange
						/>
						<DatesContainer fullOpen={props.isFullOpen}>
							<Input
								inputStyle={{ ...inputStyle, ...creationPopupInputStyle }}
								name="date"
								type="text"
								label={translateToString('started_on')}
								value={moment.utc(instance.started_at).local().format('L LTS')}
								disableChange
							/>
							<Input
								inputStyle={{ ...inputStyle, ...creationPopupInputStyle }}
								name="date"
								type="text"
								label={translateToString('ended_on')}
								value={dateFormatted}
								disableChange
							/>
							<RestrictedSuperAdmin>
								<Input
									inputStyle={{ ...inputStyle, ...creationPopupInputStyle }}
									name="date"
									type="text"
									label={translateToString('synched_on')}
									value={moment.utc(instance.created_at).local().format('L LTS')}
									disableChange
								/>
							</RestrictedSuperAdmin>
							<Input
								inputStyle={{ ...inputStyle, ...creationPopupInputStyle }}
								name="date"
								type="text"
								label={translateToString('updated_on')}
								value={instance.updated_at ? moment.utc(instance.updated_at).local().format('L LTS') : '-'}
								disableChange
							/>
						</DatesContainer>
						<div>
							<InputStyleLabel>{translateToString('progress')}</InputStyleLabel>
							<ProgressBarStyle>
								<ProgressBar
									now={instance.progression}
									variant={instance.progression == 100 ? 'success' : instance.progression > 45 ? 'warning' : 'danger'}
									min={0}
									max={100}
								/>
							</ProgressBarStyle>
						</div>
						{props.isFullOpen && <FormTemplateGenericDisplayerPerFields fieldValues={JSON.parse(JSON.stringify(fieldValues))} />}
					</>
				}
			</StyledDiv>
		</FlexDiv>
		<ProductPopup isOpen={productPopupState.isOpen} setIsOpen={(isOpen) => setProductPopupState({ isOpen })} productId={productPopupState.data} />
	</>;
}

function findDispatcherTypeFromMetadataKey(template: LocalTemplate | undefined, key: string): ComponentType | undefined {
	const type = template?.screens.reduce((acc, screen) => {
		if (acc) return acc;
		return screen.components.find(c => c.data?.['name'] === key);
	}, undefined)?.type;
	if (type && type !== 'table') return type;
	if (key === 'key') return undefined;
	if (key === 'catalogue') return 'catalogue_dispatcher';
	if (key === 'product') return 'product_dispatcher';
	return type ?? 'dispatcher';
}
function getComponentTypePrecedence(type: ComponentType | undefined): number {
	if (type === 'catalogue_dispatcher') return 4;
	if (type === 'dispatcher') return 3;
	if (type === 'product_dispatcher') return 2;
	return 0;
}

function buildTree(componentMap: { [name: string]: T }, fieldValues: InstanceField[], hiddenFields: Set<number>): Tree {
	const tree: Tree = {};
	fieldValues.forEach(fv => {
		if (hiddenFields.has(fv.id)) return;
		const obj: Tree = Object.entries(fv.metadata).map(([key, value]) => {
			const order = componentMap[key]?.precedence ?? -1;
			return { key, value, order };
		}).sort((a, b) => b.order - a.order).reduce((curr_obj, { key, value }) => {
			if (!value) return curr_obj;
			curr_obj[key] ??= {};
			curr_obj[key][value] ??= {};
			return curr_obj[key][value];
		}, tree);
		obj.veryLongStringSoCustomersCannotUseThisMetadataByMistake ??= [];
		obj.veryLongStringSoCustomersCannotUseThisMetadataByMistake.push(fv);
	});
	return tree;
}

type T = { name: string, type: ComponentType | undefined, precedence: number };
type Tree = { veryLongStringSoCustomersCannotUseThisMetadataByMistake?: InstanceField[] } & { [metadataName: string]: { [metadataValue: string]: Tree } };


function getRecusiveAllField(tree: Tree): { [key: number]: string } {
	const res: { [key: number]: string } = {};
	tree.veryLongStringSoCustomersCannotUseThisMetadataByMistake?.forEach(fv => {
		res[fv.id] = fv.name;
	});
	Object.entries(tree).forEach(([, value]) => {
		if (Array.isArray(value)) return;
		Object.entries(value).forEach(([, newTree]) => {
			const fields = getRecusiveAllField(newTree);
			Object.entries(fields).forEach(([id, name]) => {
				res[parseInt(id)] = name;
			});
		});
	});
	return res;
}

function getRecursiveAllFieldValues(tree: Tree, metadataName: string): { metadataValue: string, fields: InstanceField[] }[] {
	const res: { metadataValue: string, fields: InstanceField[] }[] = [];
	Object.entries(tree).forEach(([, value]) => {
		if (Array.isArray(value)) {
			res.push({ metadataValue: value[0].metadata[metadataName], fields: value });
			return;
		}
		Object.entries(value).forEach(([, newTree]) => {
			res.push(...getRecursiveAllFieldValues(newTree, metadataName));
		});
	});
	return res;
}

function TreeDisplayer(props: { tree: Tree, fieldMap: { [key: string]: Field }, componentMap: { [name: string]: T } }) {
	const { tree, fieldMap, componentMap } = props;
	const assortments = useRecoilValue(AAssortments);
	const products = useRecoilValue(AProducts);
	const { setProductPopupState } = React.useContext(FormInstanceContext);
	return <>
		{tree.veryLongStringSoCustomersCannotUseThisMetadataByMistake?.map((fv, i) => {
			return <FlexDiv key={i} gap='2px' flow='column' align='stretch' padding='8px 0 0 0'>
				<DefaultTextDiv color={SidelyBlack}>{fv.name}</DefaultTextDiv>
				<FieldDisplayer field_type={fieldMap[fv.id]?.type} value={fv.value} fieldId={fv.id} metadata={fv.metadata}/>
			</FlexDiv>;
		})}
		{Object.entries(tree).map(([metadataName, value]) => {
			if (Array.isArray(value)) return undefined;
			if (componentMap[metadataName]?.type === 'product_dispatcher') {
				const allFieldValues: { metadataValue: string, fields: InstanceField[] }[] = [];
				const allFields = Object.values(value).reduce((acc: { [key: number]: string }, tree) => {
					const fields = getRecusiveAllField(tree);
					allFieldValues.push(...getRecursiveAllFieldValues(tree, metadataName));
					Object.entries(fields).forEach(([id, name]) => {
						acc[parseInt(id)] = name;
					});
					return acc;
				}, {});
				const allFieldsArray = Object.entries(allFields).map(([id, name]) => ({ id: parseInt(id), name }));
				return <table key={`${metadataName}`}>
					<thead>
						<tr>
							<th>{translateToString('products')}</th>
							{allFieldsArray.map(({ id, name }) => <th key={`HEADER[${id}]`}>{name}</th>)}
						</tr>
					</thead>
					<tbody>
						{
							allFieldValues.map(({ metadataValue, fields }, i) => {
								const product = products.find(p => p.uuid == metadataValue);
								return <tr key={`COLUMN[${i}]`}>
									<Td>
										{product?.name}
										<ButtonAction hided style={{ float: 'right' }} parent={Td} src={eye} alt="phone" onClick={() => {
											if (product) setProductPopupState({ isOpen: true, data: product.id });
										}} />
									</Td>
									{allFieldsArray.map(({ id }, i) => {
										const field = fields.find(fv => fv.id === id);
										return <td key={`LAYOUT[${i}]`}>
											{field?.value != undefined ? <FieldDisplayer field_type={fieldMap[field.id]?.type} value={field.value.toString()} fieldId={field.id} metadata={field.metadata}/> : <>-</>}
										</td>;
									})}
								</tr>;
							})
						}
					</tbody>
				</table>;
			}
			return <div key={`${metadataName}`}>
				{
					Object.entries(value).map(([metadataValue, newTree]) => {
						switch (componentMap[metadataName]?.type) {
							case 'catalogue_dispatcher': {
								const name = assortments.find(a => a.id.toString() == metadataValue)?.name;
								return <TitleAndChild defaultOpen title={name} key={`${metadataName}=${metadataValue}`}>
									<ThreadContainer hovered={false}>
										<TreeDisplayer tree={newTree} fieldMap={fieldMap} componentMap={componentMap} />
									</ThreadContainer>
								</TitleAndChild>;
							}
							default: {
								let title = `${metadataName.charAt(0).toUpperCase()}${metadataName.slice(1)}`;
								if (metadataValue !== 'true') title += `: ${metadataValue}`;
								return <TitleAndChild defaultOpen title={title} key={`${metadataName}=${metadataValue}`}>
									<ThreadContainer hovered={false}>
										<TreeDisplayer tree={newTree} fieldMap={fieldMap} componentMap={componentMap} />
									</ThreadContainer>
								</TitleAndChild>;
							}
						}
					})
				}

			</div>;
		})}
	</>;

}

function FormTemplateGenericDisplayerPerFields(props: { fieldValues: InstanceField[] }) {
	const { fieldValues } = props;
	const { template } = React.useContext(FormInstanceContext);
	const fields = useRecoilValue(AFormFields);
	const [fieldMap, setFieldMap] = React.useState<{ [key: string]: Field }>({});
	const [tree, setTree] = React.useState<Tree>({});
	const [componentMap, setComponentMap] = React.useState<{ [name: string]: T }>({});

	React.useEffect(() => {
		const hiddenFields = template?.screens.reduce<Set<number>>((acc, screen) => {
			screen.fields.forEach(f => {
				if (f.hidden) {
					if (!f.field_id) {
						const id = fields.find(field => field.slug === f.slug)?.id;
						if (id) acc.add(id);
					}
					else 
						acc.add(f.field_id);
				}
			});
			return acc;
		}, new Set()) ?? new Set();
		const componentMap = Object.entries<ComponentType | undefined>(fieldValues.reduce((acc, fv) => {
			Object.keys(fv.metadata).forEach(key => {
				if (acc[key] !== undefined || key === 'key') return;
				acc[key] = findDispatcherTypeFromMetadataKey(template, key);
			});
			return acc;
		}, {})).map(([name, type]) => ({
			name,
			type,
			precedence: getComponentTypePrecedence(type)
		})).sort((a, b) => b.precedence - a.precedence).reduce((acc, omk) => {
			acc[omk.name] = omk;
			return acc;
		}, {});
		setComponentMap(componentMap);
		setTree(buildTree(componentMap, fieldValues, hiddenFields));
	}, [template, fieldValues]);

	React.useEffect(() => {
		setFieldMap(fields.reduce((acc, field) => {
			acc[field.id] = field;
			return acc;
		}, {}));
	}, [fields]);

	return <TreeDisplayer tree={tree} fieldMap={fieldMap} componentMap={componentMap} />;
}
