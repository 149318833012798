import * as React from 'react';
import { translateToNode, translateToString } from '../../../styles/global/translate';
import { INPUT_STYLE_SETTINGS, SystemSettingsDropdownWrapper, SystemSettingsWrapper, SystemSettingTitle } from '../subSettings/styles';
import { DefaultButton } from '../../../styles/global/css/GlobalButton';
import { InputMode } from '../../../components_v2/input/model/Model';
import Input from '../../../components_v2/input/Input';
import visibility_on from '../../../images/reports/visibility_on.svg';
import visibility_off from '../../../images/reports/visibility_off.svg';
import { BlueSidely, RedSidely, LightGreySidely2, GreenSidely } from '../../../styles/global/css/Utils';
import styled, { keyframes } from 'styled-components';
import { ToolbarState } from '../../globals/mainPage/mainPage';
import { changePasswordWithVerification } from './actions';
import { sendEmail } from '../../auth/password/action';
import { Translate } from 'react-localize-redux';
import check_circle from '../../../images/icons/green_check.svg';
import { str_has_capital, str_has_lower, str_has_special, str_has_number, str_is_long, check_password } from 'validators-web';
import { useMe } from '../../../atoms/global/users';

type PasswordType = {
	new_password1: string
	new_password2: string
	old_password: string
	hide_pwd1: boolean
	hide_pwd2: boolean
	hide_old_pwd: boolean
}

type ErrorPassword = {
	new_password1: boolean
	new_password2: boolean
	old_password: boolean
}

interface EyeBlockProps {
	hide?: boolean
	onClick?: () => void
}

const Orange = '#ffddb4';

const DescriptionText = styled.div`
    margin-top: 20px;
    font-size: 12px;
    line-height: 24px;
	min-width: 200px;
`;

const PasswordField = styled.div<{ ok: boolean }>`
    width: fit-content;
    position: relative;
    font-weight: 200;
	font-size: 12px;
    color: ${p => p.ok ? GreenSidely : 'black'};
    &:after {
        display: ${p => p.ok ? '' : 'none'};
        content: ' ';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 1px;
        background: ${GreenSidely};
        animation-name: ${p => p.ok ? 'strike' : ''};
        animation-duration: 0.25s;
        animation-timing-function: linear;
        animation-iteration-count: 1;
        animation-fill-mode: forwards; 
    }
    @keyframes strike{
        0%   { width : 0; }
        100% { width: 100%; }
    }

`;

const PasswordBlock = styled.div`
	display: flex;
	align-items: center;
	min-width: 408px
`;

const FlexBlock = styled.div`
	display: flex;
`;

const Block = styled.div`
	display: block;
`;

interface StrengthPasswordProps {
	n_bar: number
	strength: number
}

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

const UnrollPassword = styled.div `
	animation: ${fadeIn} 0.3s ease forwards;
`;

const Spacer = styled.div `
	height: 12px;
`;

const Warning = styled.div `
	color: ${RedSidely};
	font-size: 12px;
	max-width: 350px;
`;

const StrengthPassword = styled.div<StrengthPasswordProps>`
	width: 76px;
	height: 6px;
	background-color: ${p => (p.n_bar > (p.strength ?? 0) - 1) && p.n_bar != 1 ? LightGreySidely2 :
		(p.strength ?? 0) <= 2 ? RedSidely :
			(p.strength ?? 0) >= 5 ? GreenSidely : Orange};
	margin: 5px;
	border-radius: 8px;
`;

const EyeBlock = styled.div<EyeBlockProps>`
    background-size: 18px;
	transition: transform 0.1s ease;
    background-image: url('${p => p.hide ? visibility_on : visibility_off}');
    padding: 20px;
    cursor: pointer;
	filter: ${p => (p.hide ? 'brightness(3)' : 'brightness(1)')};
    background-origin: initial;
    background-repeat: no-repeat;
    background-position: center;
	position: relative;
	left: -40px;
	&:hover {
		transform: ${p => (p.hide ? 'scale(1.1)' : 'scale(1.2)')};
		filter: brightness(1);
	}
`;

const GreenCheck = styled.img`
	position: relative;
	left: -30px;
	height: 18px;
`;

const Success = styled.img`
	height: 18px;
	margin-right: 10px;
`;

const PwdResetButton = styled.button`
	background: none;
	color: ${BlueSidely};
	border: none;
	text-decoration: underline;
	text-align: left;
	cursor: pointer;
	width: 350px;
	height: 21px;
	font-size: 11px;
`;

export default function SecuritySettings(props: {
	setToolBarState: (value: ToolbarState) => void
}) {
	const [password, setPassword] = React.useState<PasswordType>({ new_password1: '', new_password2: '', old_password: '', hide_pwd1: true, hide_pwd2: true, hide_old_pwd: true });
	const [errorState, setErrorState] = React.useState<ErrorPassword>({ new_password1: false, new_password2: false, old_password: false });
	const [success, setSuccess] = React.useState<boolean>(false);
	const [emailSent, setEmailSent] = React.useState<boolean>(false);

	const hasCapital = str_has_capital(password.new_password1);
	const hasLower = str_has_lower(password.new_password1);
	const hasSpecial = str_has_special(password.new_password1);
	const hasNumber = str_has_number(password.new_password1);
	const isLong = str_is_long(password.new_password1);
	const checkPassword = check_password(password.new_password1);
	const strength = Number(hasCapital) + Number(hasLower) + Number(hasSpecial) + Number(hasNumber) + Number(isLong);
	const me = useMe();

	function onSubmit() {
		changePasswordWithVerification(password.new_password1, password.old_password)
			.then(_res => {
				setPassword({ new_password1: '', new_password2: '', old_password: '', hide_pwd1: true, hide_pwd2: true, hide_old_pwd: true });
				setSuccess(true);
			})
			.catch(e => {
				console.log(e);
				setErrorState({ ...errorState, old_password: true });
			});
	}

	function onClick(email: string) {
		setEmailSent(true);
		sendEmail(email).catch(console.error);
	}

	React.useEffect(() => {
		props.setToolBarState({
			bottomRightToolbarComponent: <></>
		});
	}, []);

	React.useEffect(() => {
		setErrorState({ ...errorState,
			new_password1: !checkPassword && (password.new_password2.length > 0),
			new_password2: (password.new_password1.length <= password.new_password2.length || !password.new_password1.startsWith(password.new_password2.substring(0, password.new_password1.length)))
				&& password.new_password2.length !== 0
				&& password.new_password1 !== password.new_password2,
			old_password: false,
		});
		if (password.old_password.length >= 1) {
			setSuccess(false);
		}
	}, [password]);

	return <SystemSettingsWrapper>

		<SystemSettingTitle>{translateToNode('login.change_password')}</SystemSettingTitle>
		<SystemSettingsDropdownWrapper>
			{translateToNode('login.old_password')}
			<PasswordBlock>
				<Input
					mode={errorState.old_password ? InputMode.Error : undefined}
					inputStyle={INPUT_STYLE_SETTINGS}
					name='old_password'
					placeholder={translateToString('login.password')}
					type={password?.hide_old_pwd ? 'password' : 'text'}
					value={password?.old_password}
					onChange={v => password && setPassword({ ...password, old_password: v })}
				/>
				<EyeBlock
					hide={password?.hide_old_pwd}
					onClick={() => setPassword((prevPwd) => ({ ...prevPwd, hide_old_pwd: !prevPwd.hide_old_pwd, }))}
				/>
			</PasswordBlock>
			{errorState.old_password && <Warning>{translateToNode('login.wrong_password_only')}</Warning>}
		</SystemSettingsDropdownWrapper>
		{!!password.old_password && <UnrollPassword>
			<FlexBlock>
				<Block>
					<SystemSettingsDropdownWrapper>
						{translateToNode('login.choose_new_password')}
						<PasswordBlock>
							<Input
								mode={errorState.new_password1 ? InputMode.Error : undefined}
								inputStyle={INPUT_STYLE_SETTINGS}
								name='new_password'
								placeholder={translateToString('login.new_password')}
								type={password?.hide_pwd1 ? 'password' : 'text'}
								value={password?.new_password1}
								onChange={v => password && setPassword({ ...password, new_password1: v })}
							/>
							<EyeBlock
								hide={password?.hide_pwd1}
								onClick={() => setPassword((prevPwd) => ({ ...prevPwd, hide_pwd1: !prevPwd.hide_pwd1, })) }
							/>
							{ checkPassword && <GreenCheck src={check_circle}></GreenCheck>}
						</PasswordBlock>
						{errorState.new_password1 && <Warning>{translateToNode('login.weak_password')}</Warning>}
					</SystemSettingsDropdownWrapper>
					<Spacer/>
					<SystemSettingsDropdownWrapper>
						{translateToNode('login.confirm_password')}
						<PasswordBlock>
							<Input
								mode={errorState.new_password2 ? InputMode.Error : undefined}
								inputStyle={INPUT_STYLE_SETTINGS}
								name='confirm_password'
								placeholder={translateToString('login.new_password')}
								type={password?.hide_pwd2 ? 'password' : 'text'}
								value={password?.new_password2}
								onChange={v => password && setPassword({ ...password, new_password2: v })}
							/>
							<EyeBlock
								hide={password?.hide_pwd2}
								onClick={() => setPassword((prevPwd) => ({ ...prevPwd, hide_pwd2: !prevPwd.hide_pwd2, }))}
							/>
							{ checkPassword && password.new_password1 == password.new_password2 && <GreenCheck src={check_circle}></GreenCheck>}
						</PasswordBlock>
						{errorState.new_password2 && <Warning>{translateToNode('login.password_not_matching')}</Warning>}
					</SystemSettingsDropdownWrapper>
					<Spacer/>
					<PasswordBlock>
						<StrengthPassword n_bar={1} strength={strength}/>
						<StrengthPassword n_bar={2} strength={strength}/>
						<StrengthPassword n_bar={3} strength={strength}/>
						<StrengthPassword n_bar={4} strength={strength}/>
					</PasswordBlock>
				</Block>
				<DescriptionText>
					<Translate id="login.choose_password_constraint" />
					<PasswordField ok={hasCapital}> • <Translate id="login.capital_constraint" /></PasswordField>
					<PasswordField ok={hasLower}> • <Translate id="login.lower_constraint" /></PasswordField>
					<PasswordField ok={hasNumber}> • <Translate id="login.number_constraint" /></PasswordField>
					<PasswordField ok={hasSpecial}> • <Translate id="login.special_constraint" /></PasswordField>
					<PasswordField ok={isLong}> • <Translate id="login.length_constraint" /></PasswordField>
				</DescriptionText>
			</FlexBlock>
			<DefaultButton 
				disabled={password.new_password1 !== password.new_password2 || !checkPassword || password.old_password.length == 0}
				onClick={onSubmit}>
				<div style={{ width:'305px' }}>{translateToString('login.modify')}</div>
			</DefaultButton>
		</UnrollPassword> }
		{success && <FlexBlock><Success src={check_circle}/>{translateToNode('login.password_changed')}</FlexBlock>}
		{!emailSent && <PwdResetButton onClick={() => onClick(me.email)}>
			<Translate id="forgotten password" /> ?
		</PwdResetButton>}
		{emailSent && <FlexBlock><Success src={check_circle}/><Translate id="login.link_for_new_password"/></FlexBlock>}
	</SystemSettingsWrapper>;
}