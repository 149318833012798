import * as React from 'react';
import { Translate } from 'react-localize-redux';
import { Route } from '../Routes';
import ProductRouteBlack from 'images/menu_icon/product_menu_black.svg';
import ProductRouteBlue from 'images/menu_icon/product_menu_blue.svg';
import ProductRouteWhite from 'images/menu_icon/product_menu_white.svg';

const ProductsRoutes = (): Route[] => [
	{
		component: () => <></>,
		path: '/products',
		name: <Translate id="sidebar.catalog" />,
		logoImg: ProductRouteBlack,
		hoverImg: ProductRouteBlue,
		activeImg: ProductRouteWhite,
		menu: true,
		subMenu: 'products',
		permission: ['ViewCatalog'],
		subroutes: [
			{ path: '/products-v2', name: <Translate id="products.beta" />, permission: ['ViewCatalog'] },
			{ path: '/assortments-v2', name: <Translate id="assortments.beta" />, permission: ['ViewCatalog'] },
			{ path: '/multi-dimension', name: <Translate id="assortments.multi_dimension" />, permission: ['ViewProductMultiDimension'], beta: true },
		]
	},
];

export default ProductsRoutes;
