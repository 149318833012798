import axios from 'axios';
import { URL_FOUNDATION } from '../../config/keys';


export type Team = {
	id: number,
	name: string
	users: number[]
}

export type PostTeam = Omit<Team, 'id'>;
export type PutTeam = Partial<PostTeam>;

export async function getTeams(): Promise<Team[]> {
	return axios.get(`${URL_FOUNDATION}/api/v2/teams`).then(res => res.data);
}

export async function postTeam(team: PostTeam): Promise<number> {
	return axios.post(`${URL_FOUNDATION}/api/v2/teams`, team).then(res => res.data);
}

export async function putTeam(id: number, team: PutTeam): Promise<void> {
	return axios.put(`${URL_FOUNDATION}/api/v2/teams/${id}`, team).then(res => res.data);
}

export async function deleteTeam(id: number): Promise<void> {
	return axios.delete(`${URL_FOUNDATION}/api/v2/teams/${id}`).then(res => res.data);
}

export async function deleteTeamUser(id: number, userId: number): Promise<void> {
	return axios.delete(`${URL_FOUNDATION}/api/v2/teams/${id}/user/${userId}`).then(res => res.data);
}

export async function postTeamUser(id: number, userId: number): Promise<void> {
	return axios.post(`${URL_FOUNDATION}/api/v2/teams/${id}/user/${userId}`).then(res => res.data);
}