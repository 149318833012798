import * as React from 'react';
import { Translate, translateToString } from '../../../styles/global/translate';
import { PopupWrapper, Title, GridContainer, GridElement, TitleAndChild, DialogPopup } from '../../client-companies/popup/ColumnOrganizer';
import InputSearch from '../../../components_v2/input/InputSearch';
import { BorderColor, DarkGreySidely2, GreySidely2, LightGreySidely2 } from '../../../styles/global/css/Utils';
import styled from 'styled-components';
import { FlexDiv } from '../../products/style';
import { DefaultButton } from '../../../styles/global/css/GlobalButton';
import { getUserCategories, AdditionalColumn as GlobalAdditionalColumn, syncUserCategories } from '../../../atoms/additionalColumns';
import { CalculatedField as GlobalCalulatedField } from '../../../atoms/calculatedFields';
import { AdditionalFieldColumn as GlobalAdditionalFieldColumn } from '../../../atoms/additionalFieldColumns';
import { DandDDots, Id } from './statusesAndTagsSettings';
import { Checkbox } from '../../../components_v2/filterList/style/Style';
import _ from 'lodash';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Group, GroupInside, GroupInsideType } from '../../../components_v2/Selector/actions';
import { useMe } from '../../../atoms/global/users';
import { BlackImg, CategoryCreationPopup, GreyDot, PopupOpenMode } from '../../../components_v2/Selector/CategorySelector';
import add from 'images/icon/add.png';

const GridElementTitle = styled.div`
    font-size: 14px;
    font-weight: 500;
    padding-left: 10px;
    padding-top: 10px;
`;

type AdditionalColumn = Pick<GlobalAdditionalColumn, 'type' | 'data' | 'ishidded'> & {
	id: Id,
	name?: string,
	deleted?: boolean,
}

type AdditionalFieldColumn = Pick<GlobalAdditionalFieldColumn, 'field_id' | 'field_name' | 'ishidded'> & {
	id: Id,
	deleted?: boolean,
}

type CalculatedField = Omit<GlobalCalulatedField, 'id'> & { 
    id: Id
};

type GenericColumn = Pick<GlobalAdditionalColumn, 'name' | 'id' | 'ishidded'> & {
    deleted?: boolean,
    type_: GroupInsideType
}

export type GenericColumnGroup = Omit<Group, 'inside'> & {
    inside: GenericColumn[]
}

interface CompanyColumns {
    additionnalColumns: AdditionalColumn[],
    calculatedFields: CalculatedField[],
    additionalFieldColumns: AdditionalFieldColumn[]
}

function sortCategoryChildren(a: GenericColumn, b: GenericColumn): number {
	if (a.name && typeof a.name === 'string' && b.name && typeof b.name === 'string')
		return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
	else
		return 0;
}

function convertToGenericColumnGroup(columns: CompanyColumns, groups: Group[]): GenericColumnGroup[] {
	const additionnalColumns = columns.additionnalColumns.map(v => ({ ...v, type_: GroupInsideType.AdditionalColumn }));
	const calculatedFields = columns.calculatedFields.map(v => ({ ...v, type_: GroupInsideType.CalculatedField }));
	const additionalFieldColumns = columns.additionalFieldColumns.map(v => ({ ...v, type_: GroupInsideType.FormField }));
	const allColumns = [...additionnalColumns, ...calculatedFields, ...additionalFieldColumns];
	const columnsByGroup: GenericColumnGroup[] = groups.map(group => ({ ...group, inside: [] }));
	allColumns.forEach(column => {
		const group = columnsByGroup.find(g => g.id === groups.find(v => v.inside.some(i => i.id === column.id))?.id);
		let columnName = '';
		if ('name' in column && column.name) {
			columnName = column.name;
		}
		else if ('field_name' in column && column.field_name) {
			columnName = column.field_name;
		}
		if (typeof column.id === 'number') {
			const isHidded = groups.find(v => v.inside.some(i => i.id === column.id))?.inside.find(i => i.id === column.id)?.ishidded;
			const columnGroup = { id: column.id, name: columnName, ishidded: isHidded ?? column.ishidded, deleted: column.deleted, type_: column.type_ };
			if (group) {
				group.inside.push(columnGroup);
			}
		}
	});
	columnsByGroup.forEach(group => {
		group.inside.sort(sortCategoryChildren);
	});
	return columnsByGroup;
}

function convertCategoriesToGroups(categories: GenericColumnGroup[]): Group[] {
	const reportGroups: Group[] = [];
	categories.forEach((c, i) => {
		const inside: GroupInside[] = c.inside.map(i => {return { id: i.id as number, type_: i.type_, ishidded: i.ishidded ?? false };});
		reportGroups.push({
			id: c.id as number,
			name: c.name as string,
			sequence: i,
			created_by: c.created_by as number,
			public: c.public as boolean,
			inside: inside,
			shared_with: c.shared_with as number[],
			is_deleted: c.is_deleted as boolean,
		});
	});
	return reportGroups;
}

const FieldsContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	height: 20px;
	min-height: 40px;
	padding: 10px 0px;
	padding-left: 10px;
	border-radius: 5px;
	border: 1px solid ${BorderColor};
	background-color: white;
	color: ${DarkGreySidely2};
`;

const EmptyFieldsContainer = styled.div`
	width: 100%;
	height: 50px;
	padding-left: 10px;
	border-radius: 5px;
	border: 1px solid ${BorderColor};
	background-color: white;
	color: ${DarkGreySidely2};
`;
	
function CategoryDropdown(props: {
	category: GenericColumnGroup, 
	categoryId: number,
	setPopupMode: React.Dispatch<React.SetStateAction<PopupOpenMode>>,
	setCategoryToEditId: React.Dispatch<React.SetStateAction<number>>
}) {
	const insideWithoutHidded = props.category.inside.filter(v => !v.ishidded);
	return <TitleAndChild isDraggable title={props.category.name === 'default_company_fields' ? translateToString(props.category.name) : props.category.name} 
		onEdit={props.category.id !== 1 ? 
			(e: React.MouseEvent<HTMLDivElement>) => {
				e.stopPropagation();
				props.setPopupMode(PopupOpenMode.EDIT);
				props.setCategoryToEditId(props.categoryId);
			} 
			: undefined}>
		<Droppable droppableId={`reportsOrigin[${props.categoryId}]`} type='Report'>
			{(provided): React.ReactElement<HTMLElement> => (
				<div {...provided.droppableProps} ref={provided.innerRef} style={{ width: '100%' }}>
					<FlexDiv flow='column' align='stretch' gap='8px' padding='10px 0 0 0px' backgroundColor='white' width='100%'>
						{insideWithoutHidded.length > 0 ? insideWithoutHidded.map((inside, index) => {
							if (inside.deleted || inside.ishidded) return null;
							return <Draggable key={`categoryChild[${props.categoryId}][${index}]`} draggableId={`categoryChild[${props.categoryId}][${index}]`} index={index} type='Report' >
								{(provided): React.ReactElement<HTMLElement> => (
									<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
										<FlexDiv gap='8px' width='100%' key={`additionnal_column_${index}`} padding='0 5px'>
											<DandDDots height={'30px'} width='0.8rem'/>
											<FieldsContainer onClick={(e) => {
												e.stopPropagation();
											}} >
												<div>{inside.name}</div>
											</FieldsContainer>
										</FlexDiv>
									</div>
								)}
							</Draggable>;
						}) : <EmptyFieldsContainer/>}
						{provided.placeholder}
					</FlexDiv>
				</div>
			)}
		</Droppable>
	</TitleAndChild>;
}

export function CompanyProfile(props: { columns: CompanyColumns, isOpen: boolean, onClose: () => void }) {
	const [inputValue, setInputValue] = React.useState<string>('');
	const me = useMe();

	const [userCategories, setUserCategories] = React.useState<Group[]>([]);
	const ref = React.useRef<HTMLDialogElement | null>(null);
	const wrapperRef = React.useRef<HTMLDivElement | null>(null);
	const [genericColumns, setGenericColumns] = React.useState<GenericColumnGroup[]>([]);
	const [columnsByType, setColumnsByType] = React.useState<Record<GroupInsideType, GenericColumn[]>>();
	const filteredAdditionnalColumns = columnsByType ? columnsByType[GroupInsideType.AdditionalColumn].filter(v => v.name?.toLowerCase().includes(inputValue.toLowerCase())) : [];
	const filteredCalculatedFields = columnsByType ? columnsByType[GroupInsideType.CalculatedField].filter(v => v.name?.toLowerCase().includes(inputValue.toLowerCase())) : [];
	const filteredFormFields = columnsByType ? columnsByType[GroupInsideType.FormField].filter(v => v.name?.toLowerCase().includes(inputValue.toLowerCase())) : [];
	const categories = genericColumns.map(v => v.inside);
	const selectAll = categories.every(category => category.every(v => !v.ishidded));
	const [popupMode, setPopupMode] = React.useState<PopupOpenMode>(PopupOpenMode.CLOSED);
	const [categoryToEditId, setCategoryToEditId] = React.useState<number>(0);

	function onDragEnd({ type, destination, source }) {
		if (type === 'Category') {
			if (!destination || !source || source.index === destination.index) {
				return;
			}
			const newCategories = [...genericColumns];
			const [removed] = newCategories.splice(source.index, 1);
			newCategories.splice(destination.index, 0, removed);
			setGenericColumns(newCategories);
		}
		else if (type === 'Report') {
			if (!destination || !source) {
				return;
			}
			const sourceDropIndex = source.droppableId.match(/\d+/)?.[0];
			const destinationDropIndex = destination.droppableId.match(/\d+/)?.[0];
			if (sourceDropIndex && destinationDropIndex) {
				const newCategories = [...genericColumns];
				const sourceCategory = newCategories[sourceDropIndex];
				const destinationCategory = newCategories[destinationDropIndex];
				if (sourceCategory && destinationCategory) {
					const toRemove = sourceCategory.inside.filter(v => !v.ishidded)[source.index];										
					const toRemoveIndex = sourceCategory.inside.findIndex(v => v.id === toRemove.id);
					const [removed] = sourceCategory.inside.splice(toRemoveIndex, 1);
					destinationCategory.inside.push(removed);
					destinationCategory.inside.sort(sortCategoryChildren);
					setGenericColumns(newCategories);
				}
			}
		}
	}

	React.useEffect(() => {
		if (props.isOpen) {
			const handleEsc = (e: KeyboardEvent) => {
				if (e.key === 'Escape') props.onClose();
			};
			const handleClickOutside = (e: MouseEvent) => {
				if (wrapperRef.current && !wrapperRef.current.contains(e.target as Node)) {
					props.onClose();
				}
			};
			document.addEventListener('mousedown', handleClickOutside);
			document.addEventListener('keydown', handleEsc);
			ref.current?.showModal();
			return () => {
				document.removeEventListener('mousedown', handleClickOutside);
				document.removeEventListener('keydown', handleEsc);
			};
		}
		else 
			ref.current?.close();
	}, [props.isOpen]);

	React.useEffect(() => {
		getUserCategories(me.id).then(data => {
			setUserCategories(data);
			setGenericColumns(convertToGenericColumnGroup(props.columns, data));
		});
	}, []);

	React.useEffect(() => {
		setGenericColumns(convertToGenericColumnGroup(props.columns, userCategories));
	}, [props.isOpen]);

	React.useEffect(() => {
		const columnsByType: Record<GroupInsideType, GenericColumn[]> = {
			[GroupInsideType.AdditionalColumn]: [],
			[GroupInsideType.CalculatedField]: [],
			[GroupInsideType.FormField]: [],
			[GroupInsideType.Report]: []
		};
		genericColumns.forEach(category => {
			category.inside.forEach(column => {
				columnsByType[column.type_].push(column);
			});
		});
		if (columnsByType) {
			setColumnsByType(columnsByType);
		}
	}, [genericColumns]);

	function moveFieldToLastCategory(column: GenericColumn) {
		if (!column.ishidded) {
			const categoryIndex = genericColumns.findIndex(category => category.inside.some(c => c.id === column.id));
			if (categoryIndex !== -1) {
				const columnIndex = genericColumns[categoryIndex].inside.findIndex(c => c.id === column.id);
				if (columnIndex !== -1) {
					const [removedColumn] = genericColumns[categoryIndex].inside.splice(columnIndex, 1);
					genericColumns[genericColumns.length - 1].inside.push(removedColumn);
					genericColumns[genericColumns.length - 1].inside.sort(sortCategoryChildren);
				}
			}
		}
	}

	return <>
		<DialogPopup innerRef={React.useCallback(node => ref.current = node, [])}>
			<div style={{ height: '100%' }} ref={React.useCallback(node => wrapperRef.current = node, [])}>
				{popupMode === PopupOpenMode.CLOSED ? 
					<PopupWrapper>
						<Title><Translate id="company.profile_editor.title"/></Title>
						<GridContainer>
							<GridElement index={0}>
								<GridElementTitle><Translate id="company.profile_editor.available_fields"/></GridElementTitle>
								<InputSearch name="search-field"
									type='text' value={inputValue}
									onChange={(value) => setInputValue(value)}
									placeholder={translateToString('company.column_organizer.search_columns')}
									inputStyle={{
										backgroundColor: LightGreySidely2,
										border: 'none',
										borderRadius: '7px',
										width: '240px',
										padding: '5px 10px',
										placeholderColor: GreySidely2,
									}}
								/>
								<FlexDiv flow='column' align='stretch' height='100%' overflow='auto' scrollGutter={true} padding='10px 0'>
									<FlexDiv gap='8px' cursor='pointer' color={DarkGreySidely2} padding='0 0 10px 0' onClick={() => {
										const val = !selectAll;
										categories.forEach(category => {
											category.forEach(v => {
												v.ishidded = !val;
											});
										});
										setGenericColumns([...genericColumns]);
									}}>
										<Checkbox isActive={selectAll}/>
										<Translate id='company.column_organizer.select_all'/>
									</FlexDiv>
									{filteredAdditionnalColumns && filteredAdditionnalColumns.length > 0 &&
							<TitleAndChild title={translateToString('additional_columns')}>
								<FlexDiv flow='column' align='stretch' gap='8px' padding='6px 0 10px 0'>
									{filteredAdditionnalColumns.map((column) => {
										return <FlexDiv gap='8px' cursor='pointer' color={DarkGreySidely2} key={`additionnal_column_${column.id}`} onClick={(e) => {
											e.stopPropagation();
											column.ishidded = !column.ishidded;
											moveFieldToLastCategory(column);
											setGenericColumns([...genericColumns]);
										}} ><Checkbox isActive={!column.ishidded}/><div>{column.name}</div></FlexDiv>;
									})}
								</FlexDiv>
							</TitleAndChild>}
									{filteredCalculatedFields && filteredCalculatedFields.length > 0 &&
							<TitleAndChild title={translateToString('calculated_fields')}>
								<FlexDiv flow='column' align='stretch' gap='8px' padding='6px 0 10px 0'>
									{filteredCalculatedFields.map((column) => {
										return <FlexDiv gap='8px' cursor='pointer' color={DarkGreySidely2} key={`calculated_field_${column.id}`} onClick={(e) => {
											e.stopPropagation();
											column.ishidded = !column.ishidded;
											moveFieldToLastCategory(column);
											setGenericColumns([...genericColumns]);
										}} ><Checkbox isActive={!column.ishidded}/><div>{column.name}</div></FlexDiv>;
									})}
								</FlexDiv>
							</TitleAndChild>}
									{filteredFormFields && filteredFormFields.length > 0 &&
							<TitleAndChild title={translateToString('form_fields')}>
								<FlexDiv flow='column' align='stretch' gap='8px' padding='6px 0 10px 0'>
									{filteredFormFields.map((column) => {
										return <FlexDiv gap='8px' cursor='pointer' color={DarkGreySidely2} key={`form_field_${column.id}`} onClick={(e) => {
											e.stopPropagation();
											column.ishidded = !column.ishidded;
											moveFieldToLastCategory(column);
											setGenericColumns([...genericColumns]);
										}} ><Checkbox isActive={!column.ishidded}/><div>{column.name}</div></FlexDiv>;
									})}
								</FlexDiv>
							</TitleAndChild>}
								</FlexDiv>
							</GridElement>
							<GridElement index={1}>
								<FlexDiv justify='space-between'>
									<div style={{ fontWeight: '500', fontSize: '14px', padding: '0 20px' }}><Translate id="company.profile_editor.visible_fields"/></div>
									<div style={{ paddingRight: '20px' }} onClick={() => {
										setPopupMode(PopupOpenMode.NEW_REPORT);
									}}><GreyDot><BlackImg src={add} size='18px'/></GreyDot></div>
								</FlexDiv>
								<DragDropContext onDragEnd={onDragEnd} onAfterDragEnd>
									<Droppable droppableId='categories' type='Category'>
										{(provided): React.ReactElement<HTMLElement> => (
											<FlexDiv {...provided.droppableProps} innerRef={provided.innerRef} flow='column' align='stretch' gap='10px' overflow='auto' scrollGutter padding='10px 8px 10px 15px'>
												{genericColumns.map((category, index) =>
													<Draggable key={`category[${index}${category.name}]`} draggableId={`category[${index}]`} index={index} type='Category'>
														{(provided): React.ReactElement<HTMLElement> => (
															<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
																{ !category.is_deleted &&
																	<CategoryDropdown 
																		category={category} 
																		categoryId={index}
																		setPopupMode={setPopupMode}
																		setCategoryToEditId={setCategoryToEditId}
																	/>
																}
															</div>
														)}
													</Draggable>
												)
												}
												{provided.placeholder}
											</FlexDiv>
										)}
									</Droppable>
								</DragDropContext>
							</GridElement>
						</GridContainer>
						<FlexDiv justify='flex-end'>
							<FlexDiv>
								<DefaultButton buttonStyle={2} height='38px' width='130px' onClick={props.onClose}><Translate id='cancel'/></DefaultButton>
								<DefaultButton height='38px' width='130px' onClick={() => {
									props.onClose();
									const uploadCategories = convertCategoriesToGroups(genericColumns);
									syncUserCategories(me.id, uploadCategories).then(() => {
										getUserCategories(me.id).then(data => {
											setUserCategories(data);
											setGenericColumns(convertToGenericColumnGroup(props.columns, data));
										});
									});
								}}><Translate id='save'/></DefaultButton>
							</FlexDiv>
						</FlexDiv>
					</PopupWrapper>
					: <CategoryCreationPopup
						popupOpenMode={popupMode}
						setPopupMode={setPopupMode}
						isPopup={false}
						categories={genericColumns}
						setCategories={setGenericColumns}
						categoryId={categoryToEditId}
						me={me}
					/>}
			</div>
		</DialogPopup>
	</>;
}