export const COMPONENT_TYPE_LIST = [
	'product_dispatcher',
	'pagination',
	'expression',
	'summary',
	'catalogue_dispatcher',
	'dispatcher',
	'linked_contact',
	'event_creator',
	'status_display',
	'links',
	'text',
	'additional_column_qualifier',
	'table',
	'image',
	'button',
	'svg',
	'card_list',
	'campaign_dispatcher',
	'scanner'
] as const;

export type ComponentType = typeof COMPONENT_TYPE_LIST[number];

export const LAYOUT_TYPE_LIST = [ 'column', 'component', 'field', 'row', 'container', 'expanded_section' ] as const;
export type LayoutType = typeof LAYOUT_TYPE_LIST[number];

const POPUP_ACTION_TYPE_LIST = ['ok', 'quit'] as const;
type PopupActionType = typeof POPUP_ACTION_TYPE_LIST[number];

const COMPONENT_FILTER_TYPE_LIST = ['checkbox', 'radio', 'search', 'scanner'] as const;
type ComponentFilterType = typeof COMPONENT_FILTER_TYPE_LIST[number];

export type Dimention = {
	height: number;
	width: number;
	fontSize: number;
};

export type ScreenLink = {
	condition: string;
	screen_index?: number;
	calculated_screen_name?: string;
	screen_name?: string;
	code?: string;
};

export type PopupAction = {
	action: PopupActionType;
	text: string;
};

export type Popup = {
	actions: PopupAction[];
	content: string;
	title: string;
};

export type PaginationValue = {
	name: string;
	screen: ScreenLink;
};

export type PaginationData = {
	name: string;
	value: PaginationValue[];
};

export type Component = {
	type: ComponentType;
	data?: object | Array<object> | string;
	screen?: ScreenLink;
	name?: string;
	grid?: Dimention;
	options?: object;
	child?: Layout;
	popup?: Popup;
	filters?: ComponentFilter[];
};

export type Layout = {
	children?: Array<Layout>;
	child?: Layout;
	type: LayoutType;
	visible_if?: string;
	index?: number;
	name?: string;
	options?: object;
	metadata?: { [key: string]: unknown };
	calculated_metadata?: string;
};

export type FieldRef = {
	field_id?: number;
	slug?: string;
	metadata: { [key: string]: unknown };
	default_value?: unknown;
	calculated_default_value?: string;
	validator?: string;
	enabled_if?: string;
	name?: string;
	alias?: string;
	label?: string;
	calculated_value?: string;
	options?: object;
	default?: unknown;
	history?: boolean;
	on_change?: string;
	hidden?: boolean;
};

export type Buttons = {
	children?: Layout[];
	type: string;
};

export type Screen = {
	components: Component[];
	fields: FieldRef[];
	layout: Layout;
	next_screens: ScreenLink[];
	screenName?: string;
	next_button?: string;
	previous_button?: string;
	buttons?: object;
	progression?: boolean;
	name?: string;
};

export type FormTemplate = {
	count: number;
	id: number;
	name: string;
	created_at: string;
	updated_at: string;
	instances: number;
	lib?: string;
	screens: Screen[];
	force_resume: number;
	updatable_draft: boolean;
};

export type ComponentFilter = {
	name: string;
	type: ComponentFilterType;
	function?: string;
	values?: { function: string; label: string, color?: string }[];
	calculated_values?: string;
};

const JSON_VALIDATOR = {
	validate: true,
	schemas: [
		{
			fileMatch: ['*'],
			schema: {
				type: 'array',
				items: {
					$ref: '#/definitions/container'
				},
				definitions: {
					container: {
						type: 'object',
						'x-abstract': true,
						additionalProperties: false,
						required: ['components', 'fields'],
						properties: {
							buttons: {
								$ref: '#/definitions/button_layout'
							},
							components: {
								type: 'array',
								items: {
									$ref: '#/definitions/components'
								}
							},
							fields: {
								type: 'array',
								items: {
									$ref: '#/definitions/field'
								}
							},
							layout: {
								$ref: '#/definitions/layout'
							},
							next_screens: {
								type: 'array',
								items: {
									$ref: '#/definitions/screen_link'
								}
							},
							name: {
								type: 'string'
							},
							next_button: {
								type: 'string'
							},
							previous_button: {
								type: 'string'
							},
							progression: {
								type: 'boolean'
							}
						}
					},
					components: {
						type: 'object',
						'x-abstract': true,
						additionalProperties: false,
						required: ['type'],
						properties: {
							data: {
								type: ['array', 'string', 'object'],
								items: {
									type: 'string'
								}
							},
							screen: {
								$ref: '#/definitions/screen_link'
							},
							type: {
								enum: COMPONENT_TYPE_LIST
							},
							name: {
								type: 'string'
							},
							child: {
								$ref: '#/definitions/layout'
							},
							options: {
								type: 'object'
							},
							popup: {
								$ref: '#/definitions/popup'
							},
							filters: {
								type: 'array',
								items: {
									$ref: '#/definitions/component_filter'
								}
							}
						}
					},
					screen_link: {
						type: 'object',
						'x-abstract': true,
						additionalProperties: false,
						required: ['condition'],
						properties: {
							condition: {
								type: 'string'
							},
							screen_index: {
								type: 'number'
							},
							screen_name: {
								type: 'string'
							}
						}
					},
					button_layout: {
						type: 'object',
						'x-abstract': true,
						additionalProperties: false,
						required: ['type'],
						properties: {
							name: {
								type: 'string'
							},
							action: {
								enum: ['next', 'continue', 'back', 'finish']
							},
							type: {
								enum: ['button', 'row', 'column']
							},
							children: {
								type: 'array',
								items: {
									$ref: '#/definitions/button_layout'
								}
							},
							visible_if: {
								type: 'string'
							},
							options: {
								type: 'object'
							},
							condition: {
								type: 'string'
							}
						}
					},
					layout: {
						type: 'object',
						'x-abstract': true,
						additionalProperties: false,
						required: ['type'],
						properties: {
							name: {
								type: 'string'
							},
							component_index: {
								type: 'number'
							},
							index: {
								type: 'number'
							},
							type: {
								enum: LAYOUT_TYPE_LIST
							},
							child: {
								$ref: '#/definitions/layout'
							},
							children: {
								type: 'array',
								items: {
									$ref: '#/definitions/layout'
								}
							},
							visible_if: {
								type: 'string'
							},
							options: {
								type: 'object'
							},
							metadata: {
								type: 'object'
							},
							calculated_metadata: {
								type: 'string'
							}
						}
					},
					popup: {
						type: 'object',
						'x-abstract': true,
						additionalProperties: false,
						required: ['actions', 'content', 'title'],
						properties: {
							actions: {
								type: 'array',
								items: {
									$ref: '#/definitions/popup_action'
								}
							},
							content: {
								type: 'string'
							},
							title: {
								type: 'string'
							}
						}
					},
					popup_action: {
						type: 'object',
						'x-abstract': true,
						additionalProperties: false,
						required: ['action', 'text'],
						properties: {
							action: {
								enum: POPUP_ACTION_TYPE_LIST
							},
							text: {
								type: 'string'
							}
						}
					},
					field: {
						type: 'object',
						'x-abstract': true,
						additionalProperties: false,
						required: ['metadata'],
						properties: {
							field_id: {
								type: 'number'
							},
							slug: {
								type: 'string'
							},
							name: {
								type: 'string'
							},
							metadata: {
								type: 'object'
							},
							default: {},
							calculated_default_value: {
								type: 'string'
							},
							validator: {
								type: 'string'
							},
							enabled_if: {
								type: 'string'
							},
							calculated_value: {
								type: 'string'
							},
							evolution: {
								type: 'boolean'
							},
							history: {
								type: 'boolean'
							},
							options: {
								type: 'object'
							},
							on_change: {
								type: 'string'
							},
							hidden: {
								type: 'boolean'
							},
							label: {
								type: 'string'
							},
						}
					},
					component_filter: {
						type: 'object',
						'x-abstract': true,
						additionalProperties: false,
						required: ['name', 'type'],
						properties: {
							name: {
								type: 'string'
							},
							type: {
								enum: COMPONENT_FILTER_TYPE_LIST
							},
							function: {
								type: 'string'
							},
							calculated_values: {
								type: 'string'
							},
							values: {
								type: 'array',
								items: {
									$ref: '#/definitions/component_filter_value'
								}
							}
						}
					},
					component_filter_value: {
						type: 'object',
						'x-abstract': true,
						additionalProperties: false,
						required: ['function', 'label'],
						properties: {
							function: {
								type: 'string'
							},
							label: {
								type: 'string'
							},
							color: {
								type: 'string'
							}
						}
					}
				}
			}
		}
	]
};

export default JSON_VALIDATOR;
