import color from 'images/color.png';
import * as React from 'react';
import { Collapse, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { getTranslate, Translate } from 'react-localize-redux';
import { UserLabel } from '../../../components_v2/dropdown/style/Style';
import { labelPin } from '../../../helpers/data/map';
import storeLang from '../../../helpers/storeLang';
import { Dot } from '../../../styles/global/css/Dot';
import { BlueSidely, DarkGreySidely2, GreenSidely, RedSidely, SidelyBlack } from '../../../styles/global/css/Utils';
import { Owner } from '../../orders/model/Model';
import { FlexDiv } from '../../products/style';
import { Open } from '../../../styles/global/css/Open';
import styled from 'styled-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AEventTypes } from '../../../atoms/global/events';
import { ColumnPicker, GranularityPicker } from '../../client-companies/data/CompanyColumns';
import { AFrequencyEventType, FREQUENCY_SCORE_STEPS, getColorByFrequencyScore, getEmojiByFrequencyScore } from '../../../atoms/global/frequency';
import { DEFAULT_MAP_PIN_COLOR } from '../cluster';
import { DefaultTextDiv } from '../../../styles/global/css/GlobalText';
import Restricted from '../../permissions/Restricted';
import { ObjectAction } from '../../../../../web-types/objectActions';
import { CompanyStatus } from '../../client-companies/model/Model';
import RestrictedSuperAdmin from '../../permissions/RestrictedSuperAdmin';
import { translateToString } from '../../../styles/global/translate';
import { AAdditionalColumns, isAdditionalColumnTypeVisibleOnMap } from '../../../atoms/additionalColumns';
import { AAdditionalFieldColumns, isFieldTypeVisibleOnMap } from '../../../atoms/additionalFieldColumns';
import { FetchKey } from '../MapView';
import { ACalculatedFields } from '../../../atoms/calculatedFields';
import { AFormTemplate } from '../../../atoms/forms';
import { ASelectedFormId } from '../../../atoms/global/selectedForm';


type CategoryRenderType = {
	value: unknown,
	label: React.ReactNode,
	active: boolean,
	admin?: boolean
};

const CategoryTitle = styled(FlexDiv)`
	height: 53px;
	font-weight: 600;
	cursor: pointer;
	width: 100%;
	justify-content: space-between;
	padding: 0 24px;
`;

const StatusLegendTitle = styled(DefaultTextDiv)`
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	font-size: 12px;
	color: ${DarkGreySidely2};
`;


function CategoryRender<T extends CategoryRenderType>(props: {
	categories: T[],
	name: React.ReactNode,
	onCategoryClicked: (t: T) => void,
	isOpen: boolean,
	setIsOpen: (b: boolean) => void
}): JSX.Element {
	const { categories, name, onCategoryClicked, isOpen, setIsOpen } = props;
	// const [isOpen, setIsOpen] = React.useState<boolean>(categories.some(c => c.active));
	return <>
		<CategoryTitle onClick={() => setIsOpen(!isOpen)}>
			{name}
			<Open isOpen={isOpen}/>
		</CategoryTitle>
		<Collapse isOpen={isOpen}>
			{categories.map((pin, i) =>
				<RestrictedSuperAdmin key={`navItemPin[${i}]`} skip={!pin.admin}>
					<NavItem>
						<NavLink
							className={pin.active ? 'active' : ''}
							onClick={() => onCategoryClicked(pin)}
						>
							{pin.active && <div className="active-block" />}
							{pin.label}
						</NavLink>
					</NavItem>
				</RestrictedSuperAdmin>
			)}
		</Collapse>

	</>;

}

export type PinId = 'campaign' | 'forms' | 'status' | 'frequencies' | 'per_users' | 'urchin' | 'last' | 'next' | 'instore' | 'orders' | 'checkouts' | { additionalColumn: number } | { additionalFieldColumn: number } | { calculatedFieldColumn: number } | 'checkouts_sum';

type Pin = {
	name: string,
	objectAction?: ObjectAction,
	list: {
		value: PinId,
		label: React.ReactNode,
		active: boolean,
		admin?: boolean
	}[]
}

export default function TabPins(props: {
  handleTabRange: (t: PinId) => void
  handleRange: (t: PinId) => void
  setRange: (ranges: { firstRange: number, lastRange: number }) => void
  range: { firstRange: number, lastRange: number }
  status: CompanyStatus[]
  owners: Array<Owner>,
  tabType: FetchKey
}) {
	const { handleTabRange, handleRange, tabType } = props;
	const [ranges, setRanges] = React.useState({});
	const translate = getTranslate(storeLang.getState().localize);
	const formTemplates = useRecoilValue(AFormTemplate);
	const formTemplatesList = formTemplates.map(c => ({ value: c.id, label: c.name }));
	const [selectedFormId, setSelectedFormId] = useRecoilState(ASelectedFormId);
	const eventTypes = useRecoilValue(AEventTypes);
	const eventTypeList = eventTypes.map(c => ({ value: c, label: translate(`event.${c.name}`).toString() }));
	const [selectedFrequencyEventType, setSelectedFrequencyEventType] = useRecoilState(AFrequencyEventType);
	const additionalColumns = useRecoilValue(AAdditionalColumns);
	const additionalFieldColumns = useRecoilValue(AAdditionalFieldColumns);
	const calculatedFields = useRecoilValue(ACalculatedFields);

	const pinsList: Pin[] = [
		{
			name: translate('Company Details').toString(),
			list: [
				{ value: 'status', label: <Translate id="status" />, active: tabType === 'status' },
				{ value: 'per_users', label: <Translate id="User" />, active: tabType === 'per_users' },
				{ value: 'urchin', label: <Translate id="urchin" />, active: tabType === 'urchin' }
			]
		},
		{
			name: 'Campagnes',
			objectAction: 'ReadCampaign',
			list: [
				{ value: 'campaign', label: <Translate id="current_campagnes" />, active: tabType === 'campaign' }
			]
		},
		{
			name: translate('events').toString(),
			objectAction: 'ReadEvent',
			list: [
				{ value: 'last', label: <Translate id="Last completed event" />, active: tabType === 'last' },
				{ value: 'next', label: <Translate id="Next planned event" />, active: tabType === 'next' },
				{ value: 'frequencies', label: <FlexDiv gap='5px'>
					<Translate id="frequency" />
					<ColumnPicker
						datalist={eventTypeList}
						columnId={''}
						selectedValue={eventTypeList.find(et => et.value.id === selectedFrequencyEventType)}
						onChange={value => setSelectedFrequencyEventType(value.value.id)}
						height='12px'
					/>
				</FlexDiv>, active: tabType === 'frequencies' }
			]
		},
		{
			name: translate('forms').toString(),
			objectAction: 'ReadForm',
			list: [
				{
					value: 'forms', label:
						<FlexDiv gap='5px'>
							<Translate id="last" />
							<ColumnPicker
								datalist={formTemplatesList}
								columnId={''}
								selectedValue={formTemplatesList.find(ft => ft.value === selectedFormId)}
								onChange={value => setSelectedFormId(value.value)}
								height='12px'
							/>
						</FlexDiv> 
					, active: tabType === 'forms'
				}
			]
		},
		{
			name: translate('orders_').toString(),
			objectAction: 'ReadOrder',
			list: [
				{ value: 'orders', label: <Translate id="last_order" />, active: tabType === 'orders' }
			]
		},
		{
			name: translateToString('checkouts'),
			list: [
				{ value: 'checkouts', label: <GranularityPicker name={<Translate id="checkouts_evolution" />}/>, active: tabType === 'checkouts' },
				{ value: 'checkouts_sum', label: <GranularityPicker name={<Translate id="income" />}/>, active: tabType === 'checkouts_sum' }
			]
		},
		{
			name: translateToString('additional_columns'),
			objectAction: 'ReadForm',
			list: additionalColumns.filter(ac => isAdditionalColumnTypeVisibleOnMap(ac.type)).map(ac => ({ value: { additionalColumn: ac.id }, label: ac.name, active: typeof tabType != 'string' && 'additionalColumn' in tabType && tabType.additionalColumn === ac.id }))
		},
		{
			name: translateToString('additional_field_columns'),
			objectAction: 'ReadForm',
			list: additionalFieldColumns.filter(afc => isFieldTypeVisibleOnMap(afc.field_type)).map(afc => ({
				value: { additionalFieldColumn: afc.field_id },
				label: afc.field_name,
				active: typeof tabType != 'string' && 'additionalFieldColumn' in tabType && tabType.additionalFieldColumn === afc.field_id
			}))
		},
		{
			name: translateToString('calculated_fields'),
			objectAction: 'ReadForm',
			list: calculatedFields.map(cf => ({ value: { calculatedFieldColumn: cf.id }, label: cf.field_name, active: typeof tabType != 'string' && 'calculatedFieldColumn' in tabType && tabType.calculatedFieldColumn === cf.id }))
		}
	];

	const [pinOpen, setPinOpen] = React.useState<string | undefined>(pinsList.find(p => p.list.some(p => p.active))?.name);

	React.useEffect(() => {
		let key: string | undefined;
		switch (tabType) {
			case 'forms':
				key = 'forms';
				break;
			case 'last':
				key = 'last';
				break;
			case 'next':
				key = 'next';
				break;
			case 'orders':
				key = 'orders';
				break;
		}
		if (key) {
			props.setRange({
				firstRange: ranges[key]?.first ?? 0,
				lastRange: ranges[key]?.last ?? 30
			});
		}
		setPinOpen(pinsList.find(p => p.list.some(p => p.active))?.name);
	}, [tabType]);

	function getPinLegend(firstRange, lastRange, type) {
		return (
			(<React.Fragment>
				<h5 style={{ color: SidelyBlack }}>
					<Translate id="Pins legend" />
				</h5>
				<img src={color} alt="" className="w-100 my-3" />
				<div className="d-flex align-items-center justify-content-between mb-3">
					<div>
						<input
							name={firstRange}
							type="text"
							className="color-box text-center mr-1"
							defaultValue={props.range.firstRange}
							onChange={e => {
								props.setRange({
									...props.range,
									firstRange: parseInt(e.target.value, 10)
								});
								ranges[type] = { ...ranges[type], first: parseInt(e.target.value, 10) };
								setRanges({ ...ranges });
								handleRange(type);
							}}
						/>
						<small>
							<Translate id="days" />
						</small>
					</div>
					<div>
						<input
							name={lastRange}
							type="text"
							className="color-box text-centermr-1"
							defaultValue={props.range.lastRange}
							onChange={e => {
								props.setRange({
									...props.range,
									lastRange: parseInt(e.target.value, 10)
								});
								ranges[type] = { ...ranges[type], last: parseInt(e.target.value, 10) };
								setRanges({ ...ranges });
								handleRange(type);
							}}
						/>
						<small>
							<Translate id="days" />+
						</small>
					</div>
				</div>
			</React.Fragment>)
		);
	}

	function getLabel<T extends { color: string, label: string }>(labelPins: T[]) {
		return (
			<ul style={{ listStyle: 'none', maxWidth: 235 }}>
				{labelPins.map((val, i) => (
					<li className="d-flex align-items-center my-2" key={`labelPin[${i}]`}>
						<i
							className="mr-4 fas fa-circle"
							style={{ color: val.color, fontSize: '8px' }}
						/>
						<Translate id={val.label} />
					</li>
				))}
			</ul>
		);
	}

	return (
		<TabPane tabId={1} style={{ height: '100%' }}>
			<div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
				<h5 className="p-3" style={{ color: SidelyBlack }}>
					<Translate id="Pin representation" />
				</h5>
				<div style={{ overflow: 'auto', flexShrink: 1, height: '100%' }}>
					<Nav tabs vertical className="nav-pin">
						{pinsList.map((pl, i) =>
							<Restricted to={pl.objectAction ? { objectAction: pl.objectAction } : []} key={`PinCategory[${i}]`}>
								<CategoryRender
									categories={pl.list}
									name={pl.name}
									onCategoryClicked={pin => handleTabRange(pin.value)}
									isOpen={pinOpen === pl.name}
									setIsOpen={b => setPinOpen(b ? pl.name : undefined)}
								/>
							</Restricted>
						)}
					</Nav>
				</div>
				<TabContent activeTab={tabType} style={{ marginBottom: 10, maxHeight: '45%', overflow: 'auto', flexShrink: 0, marginTop: 10 }}>
					<TabPane tabId={'status'}>
						<h5 className="px-3" style={{ color: SidelyBlack }}>
							<Translate id="Pins legend" />
						</h5>
						<div className="px-3">
							{props.status.map((val, i) => <FlexDiv gap='20px' key={`DotPinStatus[${i}]`}>
								<Dot color={val.color_code} size='8px' />
								<StatusLegendTitle>{val.name}</StatusLegendTitle>
							</FlexDiv>
							)}
						</div>
					</TabPane>
					<TabPane tabId={'forms'} className="px-3">
						<React.Fragment>
							{getPinLegend('firsRangeOrder', 'LastRangeOrder', 'forms')}
							{getLabel([{ label: 'company_no_forms', color: '#83CEEE' }])}
						</React.Fragment>
					</TabPane>
					<TabPane tabId={'last'} className="px-3">
						<React.Fragment>
							{getPinLegend('firsRangeLast', 'LastRangeLast', 'last')}
							{getLabel(labelPin)}
						</React.Fragment>
					</TabPane>
					<TabPane tabId={'next'} className="px-3">
						<React.Fragment>
							{getPinLegend('firsRangeNext', 'LastRangeNext', 'next')}
							<ul style={{ listStyle: 'none', maxWidth: 235 }}>
								<li className="d-flex align-items-center my-2">
									<i
										className="mr-4 fas fa-circle"
										style={{ color: '#83CEEE', fontSize: '8px' }}
									/>
									<Translate id="Companies with no planned events" />
								</li>
							</ul>
						</React.Fragment>
					</TabPane>
					<TabPane tabId={'instore'}>
						<h5 className="px-3" style={{ color: SidelyBlack }}>
							<Translate id="Pins legend" />
						</h5>
						<ul className="ul-pin">
							<li style={{ color: GreenSidely }}>
								<span className="text-dark"><Translate id="instore_presence" /></span>
							</li>
							<li style={{ color: '#C8ED34' }}>
								<span className="text-dark"><Translate id="order_in_progress" /></span>
							</li>
							<li style={{ color: '#FFEA2C' }}>
								<span className="text-dark"><Translate id="out_of_stock" /></span>
							</li>
							<li style={{ color: '#FF7669' }}>
								<span className="text-dark"><Translate id="de_listed" /></span>
							</li>
							<li style={{ color: '#FFA340' }}>
								<span className="text-dark"><Translate id="out_of_assortment" /></span>
							</li>
							<li style={{ color: BlueSidely }}>
								<span className="text-dark"><Translate id="other" /></span>
							</li>
							<li style={{ color: '#EAEAEA' }}>
								<span className="text-dark"><Translate id="uninformed" /></span>
							</li>
						</ul>
					</TabPane>
					<TabPane tabId={'per_users'}>
						<h5 className="px-3" style={{ color: SidelyBlack }}>
							<Translate id="Pins legend" />
						</h5>
						<div className="px-3">
							{props.owners.map((owner, i) => <FlexDiv gap='20px' key={`DotPinOwner[${i}]`}>
								<Dot color={owner.color} size='8px' />
								<UserLabel user={owner} me={translate('me').toString()}>{owner.name}</UserLabel>
							</FlexDiv>
							)}
						</div>
					</TabPane>
					<TabPane tabId={'urchin'}>
						<h5 className="px-3" style={{ color: SidelyBlack }}>
							<Translate id="Pins legend" />
						</h5>
						<div className="px-3">
							{props.owners.map((owner, i) => <FlexDiv gap='20px' key={`DotPinOwner[${i}]`}>
								<Dot color={owner.color} size='8px' />
								<UserLabel user={owner} me={translate('me').toString()}>{owner.name}</UserLabel>
							</FlexDiv>
							)}
						</div>
					</TabPane>
					<TabPane tabId={'orders'} className="px-3">
						<React.Fragment>
							{getPinLegend('firsRangeOrder', 'LastRangeOrder', 'orders')}
							{getLabel([{ label: 'company_no_orders', color: '#83CEEE' }])}
						</React.Fragment>
					</TabPane>
					<TabPane tabId={'frequencies'}>
						<h5 className="px-3" style={{ color: SidelyBlack }}>
							<Translate id="Pins legend" />
						</h5>
						<div className="px-3">
							<FlexDiv gap='20px' fontSize='12px'>
								<Dot color={DEFAULT_MAP_PIN_COLOR} size='8px' />
								<Translate id='no_frequency' />
							</FlexDiv>
							{FREQUENCY_SCORE_STEPS
								.reduce((acc: {score: number, lb: number, hb: number}[], score: number, i: number) => i == 0 ?
									acc :
									[...acc, { score: (score + FREQUENCY_SCORE_STEPS[i - 1]) / 2, lb: FREQUENCY_SCORE_STEPS[i - 1], hb: score }]
								, [])
								.map((score, i) => <FlexDiv gap='20px' key={`DotPinFrequencies[${i}]`} fontSize='12px'>
									<Dot color={getColorByFrequencyScore(score.score)} size='8px' />
									{getEmojiByFrequencyScore(score.score)}
									<div>
										{translate('from_{{NAME1}}_to_{{NAME2}}').toString().replace('{{NAME1}}', score.lb.toString()).replace('{{NAME2}}', score.hb == 7 ? '+∞' : score.hb.toString())}
									</div>
								</FlexDiv>
								)
							}
						</div>
					</TabPane>
					<TabPane tabId='checkouts'>
						<h5 className="px-3" style={{ color: SidelyBlack }}>
							<Translate id="Pins legend" />
						</h5>
						<div className="px-3">
							<FlexDiv gap='20px' fontSize='12px'>
								<Dot color={GreenSidely} size='8px' />
								<Translate id='positive_evolution' />
							</FlexDiv>
							<FlexDiv gap='20px' fontSize='12px'>
								<Dot color={RedSidely} size='8px' />
								<Translate id='negative_evolution' />
							</FlexDiv>
							<FlexDiv gap='20px' fontSize='12px'>
								<Dot color={DEFAULT_MAP_PIN_COLOR} size='8px' />
								<Translate id='no_checkouts' />
							</FlexDiv>
						</div>
					</TabPane>
				</TabContent>
			</div>
		</TabPane>
	);
}
