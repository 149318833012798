import add_blue from 'images/icon/add_blue.png';
import trash from 'images/formbuilder/trash.svg';
import modal_open from 'images/icons/company/modal_open.svg';
import open_new_tab from 'images/icons/company/open_new_tab.svg';
import assortments from 'images/setting_icons/assortments.svg';
import noImage from 'images/no-image.jpg';
import close from 'images/icons/orders/close.svg';
import * as React from 'react';
import { PopupMode } from '../../components_v2/popup/model/Model';
import Popup from '../../components_v2/popup/Popup';
import { DefaultImage } from '../../styles/global/css/GlobalImage';
import {
	Container,
	FullOpen,
	Header as CCHeader,
	HeaderBlock,
	HeaderLeft,
	HeaderRight,
	LeftContainer
} from '../client-companies/style/PopupStyle';
import { HeaderTitle } from '../orders/templateOrders/subPage/style/PopupOrderDetailStyle';
import 'react-image-lightbox/style.css';
import Avatar from '../../components_v2/avatar/Avatar';
import { getAllProducts, getAssortmentById, updateAssortment, updateAssortmentProduct } from './action';
import { formatCurrency } from '../reports/utils';
import ProductPopup, { TitleWithOpen } from './product';
import {
	BodyMainTextEditable,
	Content,
	InnerContainer,
	InnerContainerTitle,
	Link,
	RightContainer,
	TABLE_IMAGE_PADDING,
	TABLE_IMAGE_WIDTH,
	TableAnswer,
	TableTitle,
	UserPhoto,
	UserPhotoContainer
} from './style';
import { Assortment, AssortmentProduct, ModalState } from './model';
import { ToolbarImage } from '../globals/defaultToolbar/style/Style';
import AssortmentProductCreation from './assortmentProductCreation';
import { Translate } from 'react-localize-redux';
import { Collapse } from 'reactstrap';
import { TableRow } from '../orders/templateOrders/style/Style';
import { Table, TableSortType } from '../../components_v2/table/Table';
import Pagination from '../../components_v2/pagination/Pagination';
import styled from 'styled-components';
import { DarkGreySidely, LightBlueSidely } from '../../styles/global/css/Utils';
import { parseNumber } from '../../components_v2/utils';
import Restricted from '../permissions/Restricted';
import { Unhautorized } from '../noData/NoData';
import { translateToString } from '../../styles/global/translate';
import { HeaderTitleInput, blurOnEnterPressed } from '../../components_v2/input/Input';

const DEFAULT_LIMIT = 20;

const Header = styled(CCHeader)`
	min-height: 90px;
`;

const AssortmentLogoContainer = styled.div<{ src: string, isNoImage?: boolean }>`
	width: 50px;
	height: 50px;
	/*border: 1px solid #EAEAEA;*/
	padding: 5px;
	border-radius: 50%;
	background-image: url('${p => p.src}');
	background-position: center;
	background-repeat: no-repeat;
	flex-shrink: 0;
	border: 1px solid #EAEAEA;
	background-size: ${p => p.isNoImage ? '100%' : '60%'};
`;

export default function AssortmentDetail(props: {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  assortment?: Assortment
  assortmentId?: number
  fullOpenMode?: boolean
  isChild?: boolean
  refresh?: () => void
}): JSX.Element {
	const { isOpen, setIsOpen, fullOpenMode, refresh, isChild } = props;
	const [popupMode, setPopupMode] = React.useState<PopupMode>(fullOpenMode ? PopupMode.Default : PopupMode.Details);
	const [assortment, setAssortment] = React.useState<Assortment | undefined>(undefined);
	const [modalState, setModalState] = React.useState<[ModalState, ModalState]>([{ isOpen: false }, { isOpen: false }]);

	React.useEffect(() => {
		setAssortment(props.assortment);
	}, [props.assortment, isOpen]);

	const fetchAssortment = async(id) => await getAssortmentById(id)
		.then(res => setAssortment(res.data))
		.catch(console.log);

	React.useEffect(() => {
		if (props.assortmentId && props.assortmentId !== assortment?.id && isOpen) {
			fetchAssortment(props.assortmentId);
		}
	}, [props.assortmentId, isOpen]);

	React.useEffect(() => {
		setPopupMode(fullOpenMode ? PopupMode.Default : PopupMode.Details);
	}, [fullOpenMode, isOpen]);

	if ((assortment == null) || !isOpen) { return <></>; }

	const isFullOpen = popupMode == PopupMode.Default;

	function switchMode() {
		setPopupMode(popupMode == PopupMode.Details ? PopupMode.Default : PopupMode.Details);
	}

	return (<>
		<Popup
			isOpen={isOpen}
			onClickOut={() => setIsOpen(false)}
			popupStyle={{ animate: true, height: '100vh', top: '0' }}
			popupMode={popupMode}
			disableOutClick={isFullOpen}
			disableEsc={modalState[0].isOpen || modalState[1].isOpen}
		>
			<Restricted to={{ objectAction: 'ReadCatalog' }} fallback={<Unhautorized />}>
				<Container>
					<LeftContainer isFullOpen={isFullOpen}>
						<Header>
							<HeaderLeft width='calc(100% - 25px)'>
								<DefaultImage cursor="pointer" src={close} onClick={() => props.setIsOpen(false)} />
								<HeaderBlock flexDirection={'row'} marginLeft="20px" width='calc(100% - 50px)'>
									<AssortmentLogoContainer src={assortment.url ?? noImage} isNoImage={assortment.url ? false : true}/>
									<HeaderBlock flexDirection={'column'} marginLeft="10px" width='calc(100% - 50px)'>
										<HeaderBlock flexDirection="row">
											<Restricted to={{ objectAction: 'UpdateCatalog' }} fallback={<HeaderTitle>{assortment.name}</HeaderTitle>}>
												<HeaderTitleInput
													type="text"
													defaultValue={assortment.name}
													placeholder={translateToString('product.enter_name')}
													onKeyDownCapture={blurOnEnterPressed}
													onBlur={(e) => {
														const inputValue = e.target.value.trim();
														if (inputValue === assortment.name) return;
														updateAssortment(assortment.id, { name: inputValue })
															.then(() => {
																fetchAssortment(assortment.id);
																refresh?.();
															})
															.catch(console.log);
													}}
												/>
											</Restricted>
										</HeaderBlock>
										<HeaderBlock flexDirection="row" />
									</HeaderBlock>
								</HeaderBlock>
							</HeaderLeft>
							<HeaderRight>
								{isChild
									? <FullOpen isActive src={open_new_tab} onClick={() => window.open(`/assortments-v2?id=${assortment?.id}`, '_blank')?.focus()} />
									: <FullOpen src={modal_open} isActive={isFullOpen} onClick={() => switchMode()} />
								}
							</HeaderRight>
						</Header>
						<div style={{ overflow: 'auto', height: 'calc(100vh - 86px)' }}>
							<Overview assortment={assortment} />
						</div>
					</LeftContainer>
					<RightContainer isOpen={isFullOpen}>
						<Products
							assortmentId={assortment.id}
							openProductDetails={(id) => setModalState([{ isOpen: !modalState[0].isOpen, data: id }, modalState[1]])}
							openProductCreation={() => setModalState([modalState[0], { isOpen: !modalState[1].isOpen }])}
							refresh={() => {
								fetchAssortment(assortment.id);
								refresh?.();
							}}
						/>
					</RightContainer>
				</Container>
			</Restricted>
		</Popup >
		<ProductPopup
			isOpen={modalState[0].isOpen}
			setIsOpen={isOpen => setModalState([{ ...modalState, isOpen }, modalState[1]])}
			productId={modalState[0].data}
			isChild
		/>
	</>);
}

function Overview(props: { assortment: Assortment }): JSX.Element {
	const { assortment } = props;
	return <InnerContainer>
		<InnerContainerTitle><Translate id='overview' /></InnerContainerTitle>
		<Content>
			<table>
				<tbody>
					<tr>
						<TableTitle><Translate id='createby' /></TableTitle>
						<TableAnswer flex>{assortment.user_photo ? <UserPhotoContainer><UserPhoto src={assortment.user_photo} /></UserPhotoContainer> : <UserPhotoContainer><Avatar name={assortment.user_name} width={'30px'} /></UserPhotoContainer>}{assortment.user_name ?? '-'}</TableAnswer>
					</tr>
					<tr>
						<TableTitle><Translate id='number of products' /></TableTitle>
						<TableAnswer>{assortment.product_count ?? '-'}</TableAnswer>
					</tr>
					<tr>
						<TableTitle verticalAlign='top'><Translate id='Products' /></TableTitle>
						<TableAnswer>{assortment.products_names?.sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase())).map((p, i) => <div key={`productName[${i}]`}>{p}</div>) ?? '-'}</TableAnswer>
					</tr>
				</tbody>
			</table>
		</Content>
	</InnerContainer>;
}

function Products(props: {
  assortmentId: number
  openProductDetails: (id: number) => void
  openProductCreation: () => void
  refresh: () => void
}): JSX.Element {
	const { openProductDetails, assortmentId } = props;
	const [focus, setFocus] = React.useState<[string, number] | undefined>(undefined);
	const [isOpen, setIsOpen] = React.useState<boolean>(true);
	const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
	const [products, setProducts] = React.useState<AssortmentProduct[]>([]);
	const [pagination, setPagination] = React.useState({ step: DEFAULT_LIMIT, offset: 0 });
	const [sort, setSort] = React.useState<TableSortType>();

	const refresh = () => {
		props.refresh();
		setPagination({ ...pagination });
	};

	React.useEffect(() => {
		if (assortmentId) {
			getAllProducts(assortmentId, pagination.step, pagination.offset, sort)
				.then(res => setProducts(res.data))
				.catch(console.log);
		}
	}, [assortmentId, sort, pagination]);

	const columns = React.useMemo(() => ([
		{
			id: 'photo',
			Header: ' ',
			accessor: (row: AssortmentProduct) => <Link centered onClick={() => openProductDetails(row.id) } background={row.url}/>,
			disableSortBy: true,
			width: TABLE_IMAGE_WIDTH,
			padding:TABLE_IMAGE_PADDING.DEFAULT,
			unresizeable: true
		},
		{
			id: 'lower(name)',
			Header: <Translate id='name' />,
			accessor: (row: AssortmentProduct) => <TableRow fontWeight="500" color={DarkGreySidely} onClick={() => openProductDetails(row.id)} cursor="pointer">
				{row.name ?? '-'}
			</TableRow>,
			width: 200
		},
		{
			id: 'lower(reference)',
			Header: <Translate id='reference' />,
			accessor: (row: AssortmentProduct) => <TableRow>{row.reference ?? '-'}</TableRow>,
			width: 200
		},
		{
			id: 'lower(category_name)',
			Header: <Translate id='category' />,
			accessor: (row: AssortmentProduct) => <TableRow>{row.category_name ?? '-'}</TableRow>,
			width: 200,
		},
		{
			id: 'product_price',
			Header: <Translate id='price' />,
			accessor: (row: AssortmentProduct) => <TableRow>{row.product_price ? formatCurrency(row.product_price) : '-'}</TableRow>,
			width: 200,
		},
		{
			id: 'product_recommended_price',
			Header: <Translate id='recommended price' />,
			accessor: (row: AssortmentProduct) => <TableRow>{row.product_recommended_price ? formatCurrency(row.product_recommended_price) : '-'}</TableRow>,
			width: 200,
		},
		{
			id: 'assortment_price',
			Header: <Translate id='product.catalogue_product_price' />,
			accessor: (row: AssortmentProduct) => <TableRow>
				<UpdatablePrice
					onFocus={() => setFocus(['price', row.id])}
					onBlur={(e) => {
						setFocus(undefined);
						if (row.assortment_price == parseNumber(e.currentTarget.innerText)) return;
						updatePrice(row.id, parseNumber(e.currentTarget.innerText), 'price');
					}}
					isFormated={focus?.[0] == 'price' && focus?.[1] == row.id}
					value={row.assortment_price}
				/>
			</TableRow>,
			width: 200,
		},
		{
			id: 'assortment_recommended_price',
			Header: <Translate id='product.catalogue_product_recommanded_price' />,
			accessor: (row: AssortmentProduct) => <TableRow>
				<UpdatablePrice
					onFocus={() => setFocus(['recommended_price', row.id])}
					onBlur={(e) => {
						setFocus(undefined);
						if (row.assortment_recommended_price == parseNumber(e.currentTarget.innerText)) return;
						updatePrice(row.id, parseNumber(e.currentTarget.innerText), 'recommended_price');
					}}
					isFormated={focus?.[0] == 'recommended_price' && focus?.[1] == row.id}
					value={row.assortment_recommended_price}
				/>
			</TableRow>,
			width: 200,
		},
		{
			id: 'options',
			Header: ' ',
			accessor: (row: AssortmentProduct) => <Restricted to={{ objectAction: 'UpdateCatalog' }}>
				<TableRow onClick={() => deleteProduct(row.id)} cursor="pointer">
					<img src={trash} width={15} />
				</TableRow>
			</Restricted>,
			disableSortBy: true,
			width: 35,
			minWidth: 35,
			unresizeable: true
		}
	]), [focus]);

	function deleteProduct(productId: number) {
		updateAssortmentProduct(assortmentId, productId, { deleted: true })
			.then(refresh)
			.catch(console.log);
	}

	function updatePrice(productId: number, price: number, key: string) {
		if (!isNaN(price) && price >= 0) {
			const body = {};
			body[key] = price;
			updateAssortmentProduct(assortmentId, productId, body)
				.then(refresh)
				.catch(console.log);
		}
	}

	return (
		<InnerContainer>
			<InnerContainerTitle>
				<TitleWithOpen isOpen={isOpen} setIsOpen={setIsOpen}>
					<Translate id='products' />
				</TitleWithOpen>
				<Restricted to={{ objectAction: 'UpdateCatalog' }}>
					<ToolbarImage
						width='25px'
						height='25px'
						hasPointer
						src={add_blue}
						backgroundColor={LightBlueSidely}
						round
						onClick={() => setIsModalOpen(true)}
					/>
				</Restricted>
			</InnerContainerTitle>
			<Collapse isOpen={isOpen}>
				<Table
					height={'calc(100vh - 80px)'}
					columns={columns}
					data={products}
					onSort={sort => setSort(sort[0])}
					initialSortBy={sort}
				/>
				{products[0]?.count > DEFAULT_LIMIT &&
					<Pagination label={translateToString('products')} amount={products[0]?.count} steps={[DEFAULT_LIMIT]} onChange={(value) => {
						setPagination({ ...pagination, ...value });
					}} />
				}
			</Collapse>
			<AssortmentProductCreation
				assortmentId={assortmentId}
				isOpen={isModalOpen}
				setIsOpen={isOpen => setIsModalOpen(isOpen)}
				refresh={() => setPagination({ ...pagination })}
			/>
		</InnerContainer>
	);
}

function UpdatablePrice(props: { onFocus: () => void, onBlur: (e) => void, isFormated: boolean, value?: number }) {
	const { onFocus, onBlur, isFormated, value } = props;
	return <BodyMainTextEditable
		onFocus={onFocus}
		suppressContentEditableWarning
		contentEditable
		onKeyDownCapture={blurOnEnterPressed}
		onBlur={onBlur}
	>
		{isFormated || !value ? value : formatCurrency(value)}
	</BodyMainTextEditable>;
}
