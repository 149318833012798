import loginImage2 from 'images/login/login-image-2.png';
import loginImage1 from 'images/login/login-image-1.png';
import sidelyWhiteNew from 'images/login/sidely-white-new.png';
import * as React from 'react';
import { Button } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import { changePassword } from './action';
import LanguagePicker from '../../../components_v2/languagePicker/LanguagePicker';
import { translateToString } from '../../../styles/global/translate';
import { str_has_capital, str_has_lower, str_has_special, str_has_number, str_is_long, check_password } from 'validators-web';

type loadingState = 'loading' | 'loaded' | 'error'

const Description = styled.div`
    text-align: left;
    color: white;
    margin-top: 30px;
    width: 270px;
`;

const DescriptionTitle = styled.div`
    font-size: 24px;
    line-height: 32px;
`;
const DescriptionText = styled.div`
    margin-top: 20px;
    font-size: 14px;
    line-height: 24px;
`;

const LoginForm = styled.form`
    margin-top: 30px;
    width: 270px
`;

const PasswordField = styled.div<{ ok: boolean }>`
    width: fit-content;
    position: relative;
    font-weight: 200;
    &:after {
        display: ${p => p.ok ? '' : 'none'};
        content: ' ';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 1px;
        background: white;
        animation-name: ${p => p.ok ? 'strike' : ''};
        animation-duration: 0.25s;
        animation-timing-function: linear;
        animation-iteration-count: 1;
        animation-fill-mode: forwards; 
    }
    @keyframes strike{
        0%   { width : 0; }
        100% { width: 100%; }
    }

`;

const LanguagePickerContainer = styled.div`
    position: absolute;
    right: 23px;
    top: 10px;
`;

export default function Reset() {
	const [password, setPassword] = React.useState<string>('');
	const [loadingState, setLoadingState] = React.useState<loadingState>('loaded');
	const [showPassword, setShowPassword] = React.useState<boolean>(false);


	const has_capital = (): boolean => str_has_capital(password);
	const has_lower = (): boolean => str_has_lower(password);
	const has_special = (): boolean => str_has_special(password);
	const has_number = (): boolean => str_has_number(password);
	const is_long = (): boolean => str_is_long(password);
	const checkPassword = (): boolean => check_password(password);

	const urlSearchParams = new URLSearchParams(window.location.search);
	const params = Object.fromEntries(urlSearchParams.entries());
	if (!params.token) {
		window.location.href = '/login';
	}

	function onSubmit() {
		setLoadingState('loading');
		changePassword(params.token, password)
			.then(_res => {
				setLoadingState('loaded');
				window.location.href = '/login';
			})
			.catch(e => {
				setLoadingState('error');
				console.log(e);
				toast.error('erreur lors de la création du mot de passe, vous avez mis trop de temps ou le serveur ne réponds pas', {
					position: 'top-right',
					autoClose: 10000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined
				});
			});
	}

	function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
		if (event.key === 'Enter' && password && checkPassword()) {
			onSubmit();
		}
	}

	return (
		(<div>
			<div className="container-fluid">
				<div className="row page-login">
					<div className="col form text-center">
						<div className="d-flex justify-content-center align-items-center flex-column" style={{
							top: '50%',
							position: 'relative',
							transform: 'translateY(-50%)'
						}}>
							<div className="d-md-flex">
								<img
									className="logo-sidely"
									src={sidelyWhiteNew}
									alt=""
									height="50"
								/>
							</div>
							<br />
							<Description>
								<DescriptionTitle>
									<Translate id="login.change_password" />
								</DescriptionTitle>
								<DescriptionText>
									<Translate id="login.choose_password_constraint" />
									<PasswordField ok={has_capital()}><Translate id="login.capital_constraint" /></PasswordField>
									<PasswordField ok={has_lower()}><Translate id="login.lower_constraint" /></PasswordField>
									<PasswordField ok={has_number()}><Translate id="login.number_constraint" /></PasswordField>
									<PasswordField ok={has_special()}><Translate id="login.special_constraint" /></PasswordField>
									<PasswordField ok={is_long()}><Translate id="login.length_constraint" /></PasswordField>
								</DescriptionText>
							</Description>
							<LoginForm onSubmit={onSubmit}>
								<FormInput
									name="password"
									type={showPassword ? 'text' : 'password'}
									required
									placeholder={translateToString('login.password')}
									onKeyPress={handleKeyPress}
									showPassword={
										<i
											onClick={() => setShowPassword(!showPassword)}
											className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'
											} pointer`}
											role="button"
											tabIndex={0}
										/>
									}
									value={password}
									onChange={value => setPassword(value.target.value)}
								/>
								<div className="form-group row form-login-action">
									<div className="col-md-12 text-right">
										<Button
											className="btn btn-primary btn-login"
											onClick={onSubmit}
											loading={loadingState == 'loading'}
											spinColor="#003d79"
											disabled={!checkPassword() || loadingState == 'loading'}
										>
											{loadingState != 'loading' && (
												<span className="text-white"><Translate id="global.fire.confirm_message" /></span>
											)}
										</Button>
									</div>
								</div>
							</LoginForm>
						</div>
					</div>
					<div className="col image-login text-center">
						<div className="image d-flex justify-content-center align-items-center flex-column">
							<div className="image-login-bot d-md-flex">
								<img
									className="image-login-bot"
									src={loginImage1}
									alt=""
								/>
								<img
									className="image-login-top"
									style={{ top: '25%' }}
									src={loginImage2}
									alt=""
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ToastContainer
				position="top-right"
				autoClose={10000}
				hideProgressBar={false}
				newestOnTop
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			<LanguagePickerContainer><LanguagePicker /></LanguagePickerContainer>
		</div>)
	);
}

function FormInput(props: {
  placeholder: string
  required: boolean
  type: React.HTMLInputTypeAttribute
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  name: string
  showPassword?: React.ReactNode
  value: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
}) {
	const { placeholder, required, type, onKeyPress, name, showPassword, value, onChange } = props;
	return (
		<div className="form-group">
			<div className="input-group">
				<input
					placeholder={placeholder}
					required={required}
					type={type}
					className="form-control"
					onKeyDown={onKeyPress}
					value={value}
					onChange={onChange}
				/>
				{name === 'password' && (
					<div className="input-group-append show-password">
						<span className="input-group-text">{showPassword}</span>
					</div>
				)}
			</div>
		</div>
	);
}
